import strings from 'localization/strings';
import { convertToNumber, isNumber, replaceAll } from 'helpers/StringHelper';
import { parseIsoDate } from 'helpers/DateHelper';
const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const monthAndDayPattern = /^[0-9]{1,2}\-[0-9]{1,2}$/;

export const requiredIf = condition => value => !condition || value || value === 0
    ? undefined
    : strings.validation.required;
export const required = requiredIf(true);

export const mustBeNumberIf = condition => value => !condition || isNumber(value)
    ? undefined
    : strings.validation.mustBeNumber;
export const mustBeNumber = mustBeNumberIf(true);
export const mustBeNullOrNumber = value => value
    ? mustBeNumber(value)
    : undefined;

export const mustBeGreaterThanIf = (condition, limit) => value => !condition || (isNumber(value) && convertToNumber(value) > limit)
    ? undefined
    : strings.formatString(strings.validation.mustBeGreaterThan, limit);
export const mustBeGreaterThan = limit => mustBeGreaterThanIf(true, limit);

export const mustBeNullOrGreaterThanIf = (condition, limit) => value => !condition || (!value && value !== 0) || (isNumber(value) && convertToNumber(value)) > limit
    ? undefined
    : strings.formatString(strings.validation.mustBeNullOrGreaterThan, limit);
export const mustBeNullOrGreaterThan = limit => mustBeNullOrGreaterThanIf(true, limit);

export const mustBeNullOrGreaterThanOrEqualToIf = (condition, limit) => value => !condition || (!value && value !== 0) || (isNumber(value) && convertToNumber(value)) >= limit
    ? undefined
    : strings.formatString(strings.validation.mustBeNullOrGreaterThanOrEqualTo, limit);
export const mustBeNullOrGreaterThanOrEqualTo = limit => mustBeNullOrGreaterThanOrEqualToIf(true, limit);

export const mustBeGreaterThanOrEqualToIf = (condition, limit) => value => !condition || (isNumber(value) && convertToNumber(value) >= limit)
    ? undefined
    : strings.formatString(strings.validation.mustBeGreaterThanOrEqualTo, limit);
export const mustBeGreaterThanOrEqualTo = limit => mustBeGreaterThanOrEqualToIf(true, limit);

export const email = value => value && value.match(emailPattern)
    ? undefined
    : strings.wrongFormat;

export const emailOptional = value => !value || value.match(emailPattern)
    ? undefined
    : strings.wrongFormat;

export const multipleEmailsOptional = value =>
    !value
    ||
    replaceAll(value, ';', ',').split(',').every(o => o.trim().match(emailPattern))
        ? undefined
        : strings.wrongFormat;

export const isPassword = value =>
    (value ?? '').length < 10
        ? strings.passwordLongerThan10Characters
        : undefined;

export const isPasswordOptional = value => value && !isPassword(value)
    ? strings.passwordLongerThan10Characters
    : undefined;

export const isSamePassword = otherPassword => value =>
    value !== otherPassword
        ? strings.passwordMustMatch
        : undefined;

export const monthAndDay = value => value && value.match(monthAndDayPattern) && parseIsoDate(`2021-${value}`)
    ? undefined
    : strings.wrongFormat;

export const timeOfDayIf = condition => value => {
    if(!condition) {
        return undefined;
    }
    let sanitizedValue = replaceAll(value, '.', ':');
    if(!sanitizedValue) {
        return strings.wrongFormat;
    }
    const atoms = sanitizedValue.split(':');
    if(atoms[0].length === 1) {
        // we need a leading 0 for validation
        atoms[0] = `0${atoms[0]}`;
    }
    sanitizedValue = atoms.join(':');
    return parseIsoDate(`2021-01-01 ${sanitizedValue}`)
        ? undefined
        : strings.wrongFormat;
};

export const timeOfDay = timeOfDayIf(true);

export const timeOfDayOptional = value => value
    ? timeOfDay(value)
    : undefined;

export const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);
