import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { drawerWidth } from './AdminApp';
import routes from 'routes';

import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(({ theme }) => ({
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    topLogo: {
        '& a img': {
            width: '100%',
            padding: theme.spacing(2)
        }
    }
}));

const Menu = ({ mobileOpen, onDrawerToggle }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    const menuItems = [
        { text: 'Actors', url: routes.admin.actors },
        { text: 'Users', url: routes.admin.users },
        { text: 'Bookings', url: routes.admin.bookings },
        { text: 'Storage sites', url: routes.admin.storageSites },
        { text: 'Purchases', url: routes.admin.purchases },
        { text: 'Payouts', url: routes.admin.payouts },
        { text: 'Conversations', url: routes.admin.conversations },
        { text: 'Takeovers', url: routes.admin.takeovers },
        { text: 'Capacity utilization', url: routes.admin.capacityUtilization },
        { text: 'Reports', url: routes.admin.reports },
        { text: 'Search statistics', url: routes.admin.searchStatistics },
        { text: 'Message templates', url: routes.admin.messageTemplates },
        { text: 'Version history', url: routes.admin.versionHistory }
    ];

    const drawer = (
        <Box>
            <Box className={classes.topLogo}>
                <Link to="/">
                    <img src={appContext.images.topLogo}/>
                </Link>
            </Box>
            <List>
                {
                    menuItems.map(item => (
                        <ListItem button key={item.text}>
                            <Link to={item.url} activeClassName="selected">
                                <ListItemText>
                                    {item.text}
                                </ListItemText>
                            </Link>
                        </ListItem>
                    ))
                }
            </List>
        </Box>
    );

    return (
        <nav className={classes.drawer} aria-label="menu">
            <Hidden smUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={mobileOpen}
                    onClose={onDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </Drawer>
            </Hidden>
        </nav>
    );
};

Menu.propTypes = {
    mobileOpen: PropTypes.bool.isRequired,
    onDrawerToggle: PropTypes.func
};

export default Menu;
