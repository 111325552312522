import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, useField } from 'react-final-form';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import paymentRecurrences from 'enums/paymentRecurrences';
import organizationTypes from 'enums/organizationTypes';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { convertToNumber } from 'helpers/StringHelper';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AdditionalServiceEditor from './AdditionalServiceEditor';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Amount from 'common/Amount';

const useStyles = makeStyles(({ theme, colors }) => ({
    hidden: {
        display: 'none'
    },
    dialogContent: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    dialogActions: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    amount: {
        textAlign: 'right'
    },
    buttons: {
        textAlign: 'right',
        '& > *': {
            marginLeft: theme.spacing(2)
        }
    },
    arrow: {
        textAlign: 'right'
    },
    arrowButton: {
        color: colors.icon,
        margin: 0,
        padding: 0
    },
    actionPanel: {
        color: colors.icon,
        borderColor: colors.icon
    }
}));

const AdditionalService = ({ fieldNamePrefix, doubleRows, showExclusiveVatSuffix = false, editable = false, actor, onRemove }) => {
    const classes = useStyles();
    const form = useForm();

    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;
    const getValue = suffix => useField(getName(suffix)).input.value;

    const [dialogOpen, setDialogOpen] = useState(false);

    // the current booking item to show in the list
    const additionalService = { ...getValue() };

    // the storage group being edited in the editor
    const editorValues = { ...getValue('editor') };

    // validation is performed on the editor field
    const editorField = useField(getName('editor'));
    // the .meta.valid property contains whether the editor is valid or not, given that validation rules are set up correctly
    const editorIsValid = editorField && editorField.meta.valid;

    const showRemoveButtonInDialog = !useMediaQuery(theme => theme.breakpoints.up('md')) && !additionalService.isDraft;
    const showEligibleForVat = actor.organizationType === organizationTypes.business.key;

    const getFormattedAmount = () => {
        const price = convertToNumber(additionalService.price);
        return (
            <Amount
                value={price}
                currency={additionalService.currency}
                formatString={paymentRecurrences[additionalService.paymentRecurrence].priceSuffixTemplate + (showExclusiveVatSuffix ? ' ' + strings.excludingVatSuffix : '')}
            />
        );
    };

    const getFormattedEligibleForVat = () => {
        return additionalService.eligibleForVat
            ? strings.eligibleForVat
            : '';
    };

    const handleDialogOpen = () => {
        // we're opening the editor; copy the current storage group to the editor and operate on the copy
        form.mutators.changeValue({ name: getName('editor'), value: additionalService });
        setDialogOpen(true);
    };

    const handleDialogOk = () => {
        // the user clicked OK in the editor; copy the values of the edited storage group back to the current storage group
        form.mutators.changeValue({ name: fieldNamePrefix, value: { ...editorValues, isDraft: false, editor: undefined } });
        setDialogOpen(false);
    };

    const handleDialogRemove = () => {
        onRemove();
        setDialogOpen(false);
    };

    const handleDialogCancel = () => {
        // the user canceled the editor; go back without updating the current storage group
        if (additionalService.isDraft) {
            // canceled on create new storage group; remove that storage group
            onRemove();
        }
        setDialogOpen(false);
    };

    if(additionalService.isDraft && !dialogOpen) {
        // if creating a new storage group, show the editor immediately
        setDialogOpen(true);
    }

    return (
        <>
            <Box className={(additionalService.isDraft ? classes.hidden : '')}>
                {
                    doubleRows &&
                    (
                        <>
                            <Box>
                                <Box>
                                    {additionalService.description}
                                </Box>

                                <Box>
                                    <Box>
                                        {getFormattedAmount()}
                                    </Box>
                                    {
                                        showEligibleForVat &&
                                        (
                                            <Box>
                                                {getFormattedEligibleForVat()}
                                            </Box>
                                        )
                                    }
                                </Box>
                            </Box>

                            {
                                editable &&
                                (
                                    <Box>
                                        <Box className={classes.arrow}>
                                            <IconButton
                                                onClick={handleDialogOpen}
                                            >
                                                <KeyboardArrowRightIcon className={classes.arrowButton} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                )
                            }
                        </>
                    )
                }
                {
                    !doubleRows &&
                    (
                        <>
                            <Box>
                                {additionalService.description}
                            </Box>
                            <Box className={classes.amount}>
                                {getFormattedAmount()}
                            </Box>
                            {
                                showEligibleForVat &&
                                (
                                    <Box className={classes.eligibleForVat}>
                                        {getFormattedEligibleForVat()}
                                    </Box>
                                )
                            }
                            {
                                editable &&
                                (
                                    <Box className={classes.buttons}>
                                        <Button onClick={handleDialogOpen} className={classes.actionPanel} variant="outlined" size="small">
                                            {strings.change}
                                        </Button>
                                        <Button onClick={onRemove} className={classes.actionPanel} variant="outlined" size="small">
                                            {strings.remove}
                                        </Button>
                                    </Box>
                                )
                            }
                        </>
                    )
                }
            </Box>
            {
                editable &&
                (
                    <Dialog fullWidth maxWidth="md" open={dialogOpen} onClose={handleDialogCancel} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title" disableTypography>
                            <Typography variant="h5">
                                {strings.addAdditionalService}
                            </Typography>
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <AdditionalServiceEditor
                                fieldNamePrefix={getName('editor')}
                                actor={actor}
                            />
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleDialogCancel}
                            >
                                {strings.cancel}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!editorIsValid}
                                onClick={handleDialogOk}
                            >
                                {additionalService.isDraft ? strings.add : strings.ok}
                            </Button>
                            {
                                showRemoveButtonInDialog &&
                                (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleDialogRemove}
                                    >
                                        {strings.remove}
                                    </Button>
                                )
                            }
                        </DialogActions>
                    </Dialog>
                )
            }
        </>
    );
};

AdditionalService.propTypes = {
    fieldNamePrefix: PropTypes.string.isRequired,
    doubleRows: PropTypes.bool.isRequired,
    actor: PropTypes.object.isRequired,
    showExclusiveVatSuffix: PropTypes.bool,
    editable: PropTypes.bool,
    onRemove: PropTypes.func.isRequired
};

export default AdditionalService;
