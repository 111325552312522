import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { calculatePriceForPeriod, calculateDaysInPeriod } from 'helpers/PriceCalculator';
import { createTenantBooking } from 'actions/tenantBookings';
import { fetchActorHasCreditReports } from 'actions/actors';
import { calculateArea } from 'helpers/StorageAreaCalculator';
import { googleTagManagerConstants, createGoogleTagManagerUserAction } from 'integration/google-tag-manager/googleTagManagerHelper';
import { handleResponse } from 'actions/actionHelpers';
import { required } from 'form/validation';
import { sanitizeRegistrationNumber } from 'helpers/StringHelper';
import { getPriceInfo, calculateMinBookingLengthForSubscriptionBooking } from 'helpers/PriceCalculator';
import { getOwnerExpectedReplyTimeItems } from 'helpers/ActorHelper';
import paymentRecurrences from 'enums/paymentRecurrences';
import organizationTypes from 'enums/organizationTypes';
import bookingItemTypes from 'enums/bookingItemTypes';
import goodsTypes from 'enums/goodsTypes';
import { formatIsoDate } from 'helpers/DateHelper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getBookableStorages, askForTenantOrganizationType } from 'logic/bookingLogic';
import storageGroupCategories from 'enums/storageGroupCategories';
import additionalServiceStatuses from 'enums/additionalServiceStatuses';
import { getPriceAdjustmentExplanation, getStorageSiteCurrentDiscountPercentage } from 'helpers/StorageSiteHelper';
import { roundAmount, calculateVat } from 'helpers/MonetaryHelper';
import { calculateSum } from 'helpers/ArrayHelper';
import { fetchOpeningTimeSpansForStorageSite } from 'actions/viewStorageSites';
import { add, max, startOfDay } from 'date-fns';
import { parseOpeningTimeSpan, formatDateAndTimeSpan, stripDateCandidates } from 'helpers/OpeningTimeSpanHelper';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LoadingWrapper from 'form/LoadingWrapper';
import Form from 'form/Form';
import { TextField, Checkboxes, Radios, showErrorOnBlur } from 'mui-rff';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import MenuItem from '@material-ui/core/MenuItem';
import DateCandidateSelector from 'components/DateCandidateSelector';
import ActorBlocker from 'account/ActorBlocker';
import Amount from 'common/Amount';
import ShortDate from 'common/ShortDate';
import Length from 'common/Length';
import Area from 'common/Area';
import Percentage from 'common/Percentage';
import MarginBox from 'common/MarginBox';

const emptyArray = [];

const useStyles = makeStyles(({ theme, colors }) => ({
    section: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '&:first-child': {
            marginTop: 0
        },
        '&:last-child': {
            marginBottom: 0
        }
    },
    serviceDescription: {
        fontStyle: 'italic'
    },
    detailsList: {
        backgroundColor: colors.mediumGrey,
        padding: theme.spacing(2, 0),
        '& .MuiListItemAvatar-root': {
            textAlign: 'center',
            minWidth: '32px'
        },
        '& .icon': {
            zoom: 1.25
        },
        '& > *': {
            lineHeight: '1.5rem'
        }
    },
    listIcon: {
        marginTop: '5px',
        alignSelf: 'flex-start'
    }
}));

const BookingRequestDialog = ({ open, onClose, onBookingSuccess, storageSite, bookingRequestData }) => {
    const classes = useStyles();
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const { appContext } = useAppContext();
    const dispatch = useDispatch();
    const authenticationContext = useSelector(state => state.authentication.context);
    const { user, selectedActor } = authenticationContext;
    const { requestedStartDate, requestedEndDate, length, width, storageGroup } = bookingRequestData;
    const areaCalculation = calculateArea(length, width, storageGroupCategories[storageGroup.category]);
    const [tenantActorHasCreditReports, setTenantActorHasCreditReports] = useState(undefined);

    useEffect(() => {
        if(open) {
            // determine the dates that are available for check-in and optionally check-out
            const startDateFrom = max([startOfDay(new Date()), add(requestedStartDate, { months: -3 })]);
            const startDateTo = max([startOfDay(new Date()), add(requestedStartDate, { months: 3 })]);
            let count = 1;
            dispatch(fetchOpeningTimeSpansForStorageSite(storageSite.id, formatIsoDate(startDateFrom), formatIsoDate(startDateTo)))
                .then(handleResponse(
                    response => {
                        setStartDateCandidates(stripDateCandidates(response.payload.map(parseOpeningTimeSpan), requestedStartDate));
                        setLoadingCount(loadingCount - 1);
                    },
                    () => {
                        setLoadingCount(loadingCount - 1);
                    }
                ));
            if(requestedEndDate) {
                const endDateFrom = add(requestedEndDate, { months: -3 });
                const endDateTo = add(requestedEndDate, { months: 3 });
                dispatch(fetchOpeningTimeSpansForStorageSite(storageSite.id, formatIsoDate(endDateFrom), formatIsoDate(endDateTo)))
                    .then(handleResponse(
                        response => {
                            setEndDateCandidates(stripDateCandidates(response.payload.map(parseOpeningTimeSpan), requestedEndDate));
                            setLoadingCount(loadingCount - 1);
                        },
                        () => {
                            setLoadingCount(loadingCount - 1);
                        }
                    ));
                count++;
            }
            if(appContext.creditReport.enabled) {
                dispatch(fetchActorHasCreditReports(selectedActor.id))
                    .then(handleResponse(
                        response => {
                            setTenantActorHasCreditReports(response.payload.hasCreditReports);
                            setLoadingCount(loadingCount - 1);
                        },
                        () => {
                            setLoadingCount(loadingCount - 1);
                        }
                    ));
                count++;
            }

            setLoadingCount(count);
        }
    }, [open]);

    const subscriptionBooking = !storageSite.endDate && !requestedEndDate;

    const goodsTypeSelectListItems = [{ title: '' }].concat(Object.values(goodsTypes));

    const bookableStorages = storageGroup.enumerateStorages && storageGroup.quantity > 1
        ? [{ id: -1, title: strings.assignedByOwner }].concat(getBookableStorages(storageGroup, undefined, requestedStartDate, requestedEndDate))
        : undefined;

    const showTenantOrganizationTypeRadios = askForTenantOrganizationType(storageSite, storageGroup);

    const currency = storageGroup.currency;

    const initialValues = {
        description: undefined,
        goodsType: undefined,
        registrationNumber: undefined,
        noRegistrationNumber: false,
        additionalServiceIds: emptyArray,
        requestedService: undefined,
        selectedStartDate: undefined,
        selecedEndDate: undefined,
        storageId: storageGroup.enumerateStorages && storageGroup.quantity === 1
            ? storageGroup.storages[0].id // if there is only one storage, don't show dropdown for selection but auto-assign instead
            : -1, // to properly select "assigned by owner",
        isImmediateBooking: false,
        tenantOrganizationType: undefined
    };
    if(!showTenantOrganizationTypeRadios) {
        initialValues.tenantOrganizationType = selectedActor?.organizationType ?? organizationTypes.private.key;
    }

    const [startDateCandidates, setStartDateCandidates] = useState(undefined);
    const [endDateCandidates, setEndDateCandidates] = useState(undefined);
    const [loadingCount, setLoadingCount] = useState(0);
    const [isSaving, setIsSaving] = useState(false);

    const canUseRequestedStartDate = startDateCandidates && !!startDateCandidates.find(o => formatIsoDate(o.date) === formatIsoDate(requestedStartDate));
    const canUseRequestedEndDate = endDateCandidates && !!endDateCandidates.find(o => formatIsoDate(o.date) === formatIsoDate(requestedEndDate));
    const showStartDateSelector = startDateCandidates && !canUseRequestedStartDate;
    const showEndDateSelector = endDateCandidates && requestedEndDate && !canUseRequestedEndDate;

    const handleFormSubmit = formValues => {
        setIsSaving(true);
        const booking = getBooking(formValues);
        const createBookingRequest = {
            tenantActorId: selectedActor.id,
            tenantUserId: user.id,
            description: formValues.description,
            goodsType: formValues.goodsType,
            registrationNumber: !formValues.noRegistrationNumber
                ? sanitizeRegistrationNumber(formValues.registrationNumber)
                : undefined,
            startDate: formatIsoDate(booking.startDate),
            endDate: formatIsoDate(booking.endDate),
            bookingItems: getBookingItemRequests(formValues),
            area: areaCalculation.adjustedArea,
            width: areaCalculation.adjustedWidth,
            length: areaCalculation.adjustedLength,
            storageGroupId: storageGroup.id,
            storageId: formValues.storageId === -1
                ? undefined
                : formValues.storageId,
            isImmediateBooking: formValues.isImmediateBooking,
            tenantOrganizationType: formValues.tenantOrganizationType
        };

        const googleTagManagerMetadata = `${storageGroup.category}-${(requestedEndDate ? 'period' : 'subscription')}`;
        dispatch(createTenantBooking(createBookingRequest))
            .then(handleResponse(
                response => {
                    setIsSaving(false);
                    if(!createBookingRequest.isImmediateBooking) {
                        dispatch(createGoogleTagManagerUserAction(googleTagManagerConstants.userActions.sendBookingRequest, googleTagManagerMetadata));
                    }
                    onBookingSuccess(response.payload);
                },
                () => {
                    setIsSaving(false);
                    return { dialog: { title: strings.bookingRequestFailed } };
                }
            ));
    };

    const getFormattedArea = () => {
        const area = areaCalculation.adjustedArea
            ? areaCalculation.adjustedLength * areaCalculation.adjustedWidth
            : storageGroup.storageArea;
        return <Area value={area} maxNumberOfDecimals={1} />;
    };

    const getFormattedLength = () => {
        return <Length value={areaCalculation.adjustedArea ? areaCalculation.adjustedLength : storageGroup.storageLength} maxNumberOfDecimals={1}/>;
    };

    const getFormattedWidth = () => {
        return <Length value={areaCalculation.adjustedArea ? areaCalculation.adjustedWidth : storageGroup.storageWidth} maxNumberOfDecimals={1}/>;
    };

    const getFormattedCeilingHeight = () => <Length value={storageGroup.ceilingHeight} maxNumberOfDecimals={1}/>;

    const getOwnerExpectedReplyTime = () => {
        const value = getOwnerExpectedReplyTimeItems().find(v => v.value === storageSite.ownerExpectedReplyTime);

        if (value) {
            return <Typography variant="body1" gutterBottom>{strings.ownerExpectedReplyTime} {value.text.toLowerCase()}.</Typography>;
        }
        return undefined;
    };

    const getBookingRequestDataWithSelectedDates = values => ({ ...bookingRequestData, startDate: getStartDate(values), endDate: getEndDate(values) });

    const getBooking = values => {
        // TODO: refactor
        // creating a booking object, including booking items, based on the user input
        // note that this booking doesn't exist in the database
        const brd = getBookingRequestDataWithSelectedDates(values);
        const pi = calculatePriceForPeriod(storageSite, storageGroup, { ...brd, tenantOrganizationType: values.tenantOrganizationType }, appContext);
        const invoiceInfo = {
            amounts: {
                storageAmount: pi.priceExcludingVat,
                storageVat: pi.vat
            }
        };
        const priceInfo = {
            perMonth: subscriptionBooking
                ? invoiceInfo
                : undefined,
            wholePeriod: subscriptionBooking
                ? undefined
                : invoiceInfo
        };
        priceInfo.currency = currency;

        const bookingItems = getBookingItems(values);

        return {
            subscriptionBooking: !requestedEndDate,
            storageGroup: { ...storageGroup, storageSite },
            startDate: brd.startDate,
            endDate: brd.endDate,
            priceInfo,
            bookingItems
        };
    };

    const getStartDate = values => showStartDateSelector
        ? values.selectedStartDate
        : requestedStartDate;

    const getEndDate = values => showEndDateSelector
        ? values.selectedEndDate
        : requestedEndDate;

    const getCheckInOpeningTimeSpan = values => {
        const startDate = getStartDate(values);
        if(!startDateCandidates) {
            return undefined;
        }
        return startDateCandidates.find(o => formatIsoDate(o.date) === formatIsoDate(startDate));
    };

    const getCheckOutOpeningTimeSpan = values => {
        const endDate = getEndDate(values);
        if(!endDate || !endDateCandidates) {
            return undefined;
        }
        return endDateCandidates.find(o => formatIsoDate(o.date) === formatIsoDate(endDate));
    };

    const getBookingItemRequests = values => {
        const result = values.additionalServiceIds.map(additionalServiceId => ({ referenceId: additionalServiceId, type: bookingItemTypes.service.key }));
        if(values.requestedService) {
            result.push({
                description: values.requestedService,
                paymentRecurrence: paymentRecurrences.oneTime.key,
                type: bookingItemTypes.requestedService.key
            });
        }
        return result;
    };

    const getBookingItems = values => {
        const brd = getBookingRequestDataWithSelectedDates(values);
        const days = calculateDaysInPeriod(brd);
        return getBookingItemRequests(values).map(bir => {
            if(bir.type === bookingItemTypes.service.key) {
                const additionalService = storageSite.additionalServices.find(o => o.id === bir.referenceId);
                if(additionalService) {
                    const factor = subscriptionBooking || additionalService.paymentRecurrence !== paymentRecurrences.perMonth.key
                        ? 1
                        : days / 30;
                    const amount = roundAmount(factor * additionalService.price, appContext);
                    return {
                        amount: amount,
                        vat: calculateVat(amount, additionalService.vatRate, appContext),
                        vatRate: additionalService.vatRate,
                        description: additionalService.description,
                        paymentRecurrence: additionalService.paymentRecurrence,
                        referenceId: additionalService.id,
                        type: bookingItemTypes.service.key
                    };
                }
            }
            return { ...bir };
        });
    };

    const additionalServiceItems = (storageSite.additionalServices || [])
        .filter(additionalService => additionalService.status !== additionalServiceStatuses.deleted.key)
        .map(additionalService => {
            const paymentRecurrence = paymentRecurrences[additionalService.paymentRecurrence];
            const additionalServicePriceInfo = getPriceInfo(additionalService.price, appContext, additionalService);
            return {
                label: <span><span className="bold">{additionalService.description}:</span> <Amount value={additionalServicePriceInfo.displayPrice} currency={additionalServicePriceInfo.currency}/> {paymentRecurrence.key === paymentRecurrences.perMonth.key ? paymentRecurrence.title.toLowerCase() : ''}</span>,
                value: additionalService.id
            };
        });
    const tenantOrganizationTypeItems = Object.values(organizationTypes).map(o => ({
        label: o.bookingAs,
        value: o.key
    }));
    const additionalServicesAvailable = additionalServiceItems.length > 0;
    const isVehicle = storageGroup.category === storageGroupCategories.vehicle.key;
    const showDescription = !isVehicle;
    const showGoodsType = isVehicle;
    const showRegistrationNumber = isVehicle;
    const describeItemString = isVehicle
        ? strings.vehicleDescribeItem
        : strings.describeItem;
    const describeItemPlaceholderString = isVehicle
        ? strings.vehicleDescribeItemPlaceholder
        : undefined;

    const dialogTitle = storageSite.allowImmediateBookings
        ? strings.sendBooking
        : strings.sendBookingRequest;

    const validateFormValues = formValues => {
        const errors = {};
        if(showDescription && !formValues.description) {
            errors.description = strings.validation.required;
        }
        if(showGoodsType && !formValues.goodsType) {
            errors.goodsType = strings.validation.required;
        }
        if(showRegistrationNumber && !sanitizeRegistrationNumber(formValues.registrationNumber) && !formValues.noRegistrationNumber) {
            errors.registrationNumber = strings.validation.required;
        }
        return errors;
    };

    const allowImmediateBookings = values => storageSite.allowImmediateBookings && !values.requestedService;

    const renderSubscriptionBookingCost = booking => {
        const bookingItemsPerMonth = booking.bookingItems.filter(as => as.paymentRecurrence === paymentRecurrences.perMonth.key);
        const bookingItemsOneTime = booking.bookingItems.filter(as => as.paymentRecurrence === paymentRecurrences.oneTime.key);
        const pricePerMonth = booking.priceInfo.perMonth.amounts.storageAmount + calculateSum(bookingItemsPerMonth, as => as.amount);
        const vatPerMonth = booking.priceInfo.perMonth.amounts.storageVat + calculateSum(bookingItemsPerMonth, as => as.vat);
        const priceOneTime = calculateSum(bookingItemsOneTime, as => as.amount);
        const vatOneTime = calculateSum(bookingItemsOneTime, as => as.vat);
        const hasVat = vatPerMonth + vatOneTime > 0;

        return (
            <>
                {
                    priceOneTime > 0 &&
                    (
                        <>
                            {
                                hasVat &&
                                (
                                    <>
                                        <MarginBox bottom={2}>
                                            {strings.costPerMonthExcludingVat}: <Amount value={pricePerMonth} currency={currency}/>
                                            <br/>
                                            {strings.addedVat}: <Amount value={vatPerMonth} currency={currency}/>
                                            <br/>
                                            {strings.costPerMonthIncludingVat}: <Amount value={pricePerMonth + vatPerMonth} currency={currency}/>
                                        </MarginBox>
                                        <MarginBox bottom={2}>
                                            {strings.costOneTimeExcludingVat}: <Amount value={priceOneTime} currency={currency}/>
                                            <br/>
                                            {strings.addedVat}: <Amount value={vatOneTime} currency={currency}/>
                                            <br/>
                                            {strings.costOneTimeIncludingVat}: <Amount value={priceOneTime + vatOneTime} currency={currency}/>
                                        </MarginBox>
                                    </>
                                )
                            }
                            {
                                !hasVat &&
                                (
                                    <Box>
                                        {strings.costPerMonth}: <Amount value={pricePerMonth} currency={currency}/>
                                        <br/>
                                        {strings.costOneTime}: <Amount value={priceOneTime} currency={currency}/>
                                    </Box>
                                )
                            }
                        </>
                    )
                }
                {
                    !(priceOneTime > 0) &&
                    (
                        <>
                            {
                                hasVat &&
                                (
                                    <Box>
                                        {strings.costPerMonthExcludingVat}: <Amount value={pricePerMonth} currency={currency}/>
                                        <br/>
                                        {strings.addedVat}: <Amount value={vatPerMonth} currency={currency}/>
                                        <br/>
                                        {strings.costPerMonthIncludingVat}: <Amount value={pricePerMonth + vatPerMonth} currency={currency}/>
                                    </Box>
                                )
                            }
                            {
                                !hasVat &&
                                (
                                    <Box>
                                        {strings.costPerMonth}: <Amount value={pricePerMonth} currency={currency}/>
                                    </Box>
                                )
                            }
                        </>
                    )
                }

                {
                    storageSite.annualPriceAdjustmentDate &&
                    (
                        <Box>
                        {
                            getPriceAdjustmentExplanation(
                                    storageSite.annualPriceAdjustmentPercentage < 0 ? strings.priceDecreaseDescriptionTemplate : strings.priceIncreaseDescriptionTemplate,
                                    Math.abs(storageSite.annualPriceAdjustmentPercentage),
                                    storageSite.annualPriceAdjustmentDate,
                                    appContext
                                )
                            }
                        </Box>
                    )
                }
            </>
        );
    };

    const renderPeriodBookingCost = booking => {
        const price = booking.priceInfo.wholePeriod.amounts.storageAmount + calculateSum(booking.bookingItems, as => as.amount);
        const vat = booking.priceInfo.wholePeriod.amounts.storageVat + calculateSum(booking.bookingItems, as => as.vat);
        return (
            <>
                {
                    !!vat &&
                    (
                        <>
                            {strings.costExcludingVat}: <Amount value={price} currency={currency}/>
                            <br/>
                            {strings.addedVat}: <Amount value={vat} currency={currency}/>
                            <br/>
                            {strings.costIncludingVat}: <Amount value={price + vat} currency={currency}/>
                        </>
                    )
                }
                {
                    !vat &&
                    (
                        <>
                            {strings.cost}: <Amount value={price} currency={currency}/>
                        </>
                    )
                }
            </>
        );
    };

    const renderDiscountInformation = () => {
        const discountPercentage = getStorageSiteCurrentDiscountPercentage(storageSite);
        if(discountPercentage === undefined) {
            return undefined;
        }
        return (
            <Box>
                {strings.formatString(strings.xDiscountHasBeenDeducted, <Percentage value={discountPercentage} />)}
            </Box>
        );
    };

    const renderDateSelector = booking => {
        if(loadingCount > 0) {
            return undefined;
        }

        if(canUseRequestedStartDate && (canUseRequestedEndDate || !requestedEndDate)) {
            return undefined;
        }

        const availableStartDateCandidates = startDateCandidates
            ?.filter(candidate => !booking.endDate || candidate.date < booking.endDate);

        const availableEndDateCandidates = endDateCandidates
            ?.filter(candidate => !booking.startDate || candidate.date > booking.startDate);

        return (
            <Box className={classes.section}>
                {
                    showStartDateSelector &&
                    (
                        <>
                            <Typography variant="h6" gutterBottom>
                                {strings.selectCheckInDateTitle}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {strings.formatString(strings.selectCheckInDateDescription, <ShortDate value={requestedStartDate}/>)}
                            </Typography>
                            <DateCandidateSelector
                                availableDateCandidates={availableStartDateCandidates}
                                name="selectedStartDate"
                                label={strings.startDate}
                            />
                        </>
                    )
                }
                {
                    showEndDateSelector &&
                    (
                        <>
                            <Typography variant="h6" gutterBottom>
                                {strings.selectCheckOutDateTitle}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {strings.formatString(strings.selectCheckOutDateDescription, <ShortDate value={requestedEndDate}/>)}
                            </Typography>
                            <DateCandidateSelector
                                availableDateCandidates={availableEndDateCandidates}
                                name="selectedEndDate"
                                label={strings.endDate}
                            />
                        </>
                    )
                }
            </Box>
        );
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <LoadingWrapper
                isSaving={isSaving}
            >
                <Form
                    initialValues={initialValues}
                    onSubmit={handleFormSubmit}
                    validate={validateFormValues}
                >
                    {({ handleSubmit, form, values, invalid: invalidFromForm }) => {
                        const booking = getBooking(values);
                        const invalid = invalidFromForm || !booking.startDate || (!subscriptionBooking && !booking.endDate);

                        const checkInOpeningTimeSpan = getCheckInOpeningTimeSpan(values);
                        const checkOutOpeningTimeSpan = getCheckOutOpeningTimeSpan(values);

                        return (
                            <form onSubmit={handleSubmit}>
                                <DialogTitle disableTypography>
                                    <Typography variant="h5">
                                        {dialogTitle}
                                    </Typography>
                                </DialogTitle>
                                <DialogContent>
                                    <ActorBlocker block={selectedActor?.isBlocked}>
                                        <Box className={classes.section}>
                                            <Typography variant="h6" gutterBottom>
                                                {strings.storage}: {storageSite.title}
                                            </Typography>
                                            {
                                                showDescription &&
                                                (
                                                    <TextField
                                                        name="description"
                                                        label={describeItemString}
                                                        placeholder={describeItemPlaceholderString}
                                                        variant="outlined"
                                                        showError={showErrorOnBlur}
                                                        required
                                                        fieldProps={{ validate: required }}
                                                    />
                                                )
                                            }
                                            {
                                                showGoodsType &&
                                                (
                                                    <TextField
                                                        select
                                                        name="goodsType"
                                                        label={strings.vehicleType}
                                                        variant="outlined"
                                                        required
                                                        fieldProps={{ validate: required }}
                                                    >
                                                        {
                                                            goodsTypeSelectListItems.map((goodsType, i) => (
                                                                <MenuItem key={(goodsType.key ?? i)} value={goodsType.key}>
                                                                    {goodsType.title}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </TextField>
                                                )
                                            }
                                            {
                                                showRegistrationNumber &&
                                                (
                                                    <>
                                                        <TextField
                                                            name="registrationNumber"
                                                            label={strings.registrationNumber}
                                                            placeholder={strings.registrationNumberPlaceholder}
                                                            maxLength={16}
                                                            variant="outlined"
                                                        />
                                                        <Checkboxes
                                                            name="noRegistrationNumber"
                                                            data={{ label: strings.noRegistrationNumber, value: true }}
                                                        />
                                                    </>
                                                )
                                            }

                                            {
                                                showTenantOrganizationTypeRadios &&
                                                (
                                                    <Radios
                                                        name="tenantOrganizationType"
                                                        data={tenantOrganizationTypeItems}
                                                        showError={showErrorOnBlur}
                                                        required
                                                        fieldProps={{ validate: required }}
                                                    />
                                                )
                                            }
                                        </Box>

                                        {
                                            additionalServicesAvailable &&
                                            (
                                                <Box className={classes.section}>
                                                    <Typography variant="h6" gutterBottom>
                                                        {strings.additionalServices}
                                                    </Typography>
                                                    <Checkboxes
                                                        name="additionalServiceIds"
                                                        data={additionalServiceItems}
                                                    />
                                                </Box>
                                            )
                                        }

                                        {
                                            storageSite.serviceDescription &&
                                            (
                                                <Box className={classes.section}>
                                                    <Typography variant="h6" gutterBottom>
                                                        {additionalServicesAvailable ? strings.requestedService : strings.additionalServices}
                                                    </Typography>
                                                    <Typography variant="body1" gutterBottom>
                                                        {additionalServicesAvailable ? strings.theOwnerOffersrequestedService : strings.theOwnerOffersAdditionalServices}
                                                    </Typography>
                                                    <Typography className={classes.serviceDescription} variant="body1" gutterBottom>
                                                        {storageSite.serviceDescription}
                                                    </Typography>
                                                    <TextField
                                                        name="requestedService"
                                                        label={strings.additionalServicesToOrder}
                                                        helperText={strings.additionalServicesToOrderHelperText}
                                                        variant="outlined"
                                                        multiline
                                                        rows={2}
                                                    />
                                                </Box>
                                            )
                                        }

                                        {
                                            bookableStorages && bookableStorages.length > 1 &&
                                            (
                                                <Box className={classes.section}>
                                                    <TextField
                                                        select
                                                        name="storageId"
                                                        label={strings.selectStorageLabel}
                                                        helperText={strings.selectStorageHelperText}
                                                        variant="outlined"
                                                    >
                                                        {
                                                            bookableStorages.map((storage, i) => (
                                                                <MenuItem key={(storage.id ?? i)} value={storage.id}>
                                                                    {storage.title}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </TextField>
                                                </Box>
                                            )
                                        }

                                        {renderDateSelector(booking)}

                                        <List className={classes.detailsList + ' ' + classes.section}>
                                            <ListItem>
                                                <ListItemAvatar className={classes.listIcon}>
                                                    <span className="icon icon-location"/>
                                                </ListItemAvatar>
                                                <ListItemText>
                                                    {storageSite.city}
                                                </ListItemText>
                                            </ListItem>

                                            <ListItem>
                                                <ListItemAvatar className={classes.listIcon}>
                                                    <span className="icon icon-kvm"/>
                                                </ListItemAvatar>
                                                <ListItemText>
                                                    {strings.area}: {getFormattedArea()}
                                                    {
                                                        (areaCalculation.adjustedArea || (storageGroup.storageLength && storageGroup.storageWidth)) &&
                                                        (
                                                            <>
                                                                <br/>
                                                                {strings.length}: {getFormattedLength()}
                                                                <br/>
                                                                {strings.width}: {getFormattedWidth()}
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        storageGroup.ceilingHeight &&
                                                        (
                                                            <>
                                                                <br/>
                                                                {strings.ceilingHeight}: {getFormattedCeilingHeight()}
                                                            </>
                                                        )
                                                    }
                                                </ListItemText>
                                            </ListItem>

                                            <ListItem>
                                                <ListItemAvatar className={classes.listIcon}>
                                                    <span className="icon icon-calendar"/>
                                                </ListItemAvatar>
                                                <ListItemText>
                                                    {
                                                        booking.startDate &&
                                                        (
                                                            <>{strings.checkIn}: {formatDateAndTimeSpan(booking.startDate, checkInOpeningTimeSpan, appContext)}</>
                                                        )
                                                    }
                                                    {
                                                        !booking.startDate &&
                                                        (
                                                            <>{strings.checkIn}: {strings.dateNotSelected}</>
                                                        )
                                                    }
                                                    <br/>
                                                    {
                                                        booking.endDate &&
                                                        (
                                                            <>{strings.checkOut}: {formatDateAndTimeSpan(booking.endDate, checkOutOpeningTimeSpan, appContext)}</>
                                                        )
                                                    }
                                                    {
                                                        !subscriptionBooking && !booking.endDate &&
                                                        (
                                                            <>{strings.checkOut}: {strings.dateNotSelected}</>
                                                        )
                                                    }
                                                    {
                                                        subscriptionBooking && strings.bookingIsUntilFurther
                                                    }
                                                </ListItemText>
                                            </ListItem>

                                            {
                                                subscriptionBooking &&
                                                (
                                                    <ListItem>
                                                        <ListItemAvatar className={classes.listIcon}>
                                                            <span className="icon icon-time"/>
                                                        </ListItemAvatar>
                                                        <ListItemText>
                                                            {strings.minBookingLength}: {calculateMinBookingLengthForSubscriptionBooking(storageSite)} {strings.daysLower}
                                                        </ListItemText>
                                                    </ListItem>
                                                )
                                            }

                                            <ListItem>
                                                <ListItemAvatar className={classes.listIcon}>
                                                    <span className="icon icon-cost"/>
                                                </ListItemAvatar>
                                                <ListItemText>
                                                    { !!subscriptionBooking && renderSubscriptionBookingCost(booking) }
                                                    { !subscriptionBooking && renderPeriodBookingCost(booking) }
                                                    { renderDiscountInformation() }
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                        {
                                            appContext.creditReport.enabled &&
                                            (
                                                <Box className={classes.section}>
                                                    <Typography variant="h6" gutterBottom>
                                                        {strings.creditCheck}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        {tenantActorHasCreditReports ? strings.creditCheckAlreadyPerformedTenantInformation : strings.creditCheckTenantInformation}
                                                    </Typography>
                                                </Box>
                                            )
                                        }

                                        {getOwnerExpectedReplyTime()}
                                    </ActorBlocker>
                                </DialogContent>
                                <DialogActions>
                                    {
                                        allowImmediateBookings(values) &&
                                        (
                                            <Button
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                disabled={invalid || values.requestedService || selectedActor?.isBlocked}
                                                fullWidth={isMobile}
                                                onClick={() => form.change('isImmediateBooking', true)}
                                            >
                                                {strings.payImmediately}
                                            </Button>
                                        )
                                    }
                                    {
                                        !allowImmediateBookings(values) &&
                                        (
                                            <Button
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                disabled={invalid || selectedActor?.isBlocked}
                                                fullWidth={isMobile}
                                            >
                                                {strings.send}
                                            </Button>
                                        )
                                    }
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={onClose}
                                        fullWidth={isMobile}
                                    >
                                        {strings.cancel}
                                    </Button>
                                </DialogActions>
                            </form>
                        );
                    }}
                </Form>
            </LoadingWrapper>
        </Dialog>
    );
};

BookingRequestDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onBookingSuccess: PropTypes.func,
    storageSite: PropTypes.object,
    bookingRequestData: PropTypes.object,
};

export default BookingRequestDialog;
