import React from 'react';
import strings from 'localization/strings';
import storageTypes from 'enums/storageTypes';
import rentalTypes from 'enums/rentalTypes';

import VehicleInfoLink from 'pages/storageSiteDetails/VehicleInfoLink';
import VehicleInfoText from 'pages/storageSiteDetails/VehicleInfoText';

const storageGroupCategories = {
    storeHouse: {
        allowCustomArea: true,
        allowStorageQuantity: true,
        allowStorageEnumeration: true,
        defaultStorageType: storageTypes.storeHouse,
        searchRadius: 80,
        defaultRentalType: rentalTypes.fixedArea
    },
    vehicle: {
        infoLinkRenderer: props => <VehicleInfoLink {...props} />,
        infoTextRenderer: props => <VehicleInfoText {...props} />,
        allowCustomArea: true,
        allowStorageQuantity: true,
        allowStorageEnumeration: true,
        addPaddingWhenCalculatingArea: true,
        useAccessAllowances: true,
        useGoodsTypes: true,
        defaultStorageType: storageTypes.parking,
        searchRadius: 150,
        defaultRentalType: rentalTypes.fixedArea
    },
    buildingsAndLand: {
        allowCustomArea: true,
        allowStorageQuantity: true,
        allowStorageEnumeration: true,
        defaultStorageType: storageTypes.commercialStorage,
        searchRadius: 150,
        defaultRentalType: rentalTypes.fixedArea
    },
    office: {
        allowCustomArea: false,
        allowStorageQuantity: true,
        allowStorageEnumeration: true,
        defaultStorageType: storageTypes.office,
        searchRadius: 80,
        defaultRentalType: rentalTypes.fixedArea
    }
};

Object.keys(storageGroupCategories).forEach(key => {
    storageGroupCategories[key].key = key;
});

strings.addLoadCallback(() => {
    Object.keys(storageGroupCategories).forEach(key => {
        Object.keys(strings.storageGroupCategories[key]).forEach(stringKey => {
            storageGroupCategories[key][stringKey] = strings.storageGroupCategories[key][stringKey];
        });
        storageGroupCategories[key].singleTitle = storageGroupCategories[key].singleTitle ?? storageGroupCategories[key].title;
        storageGroupCategories[key].multipleTitle = storageGroupCategories[key].multipleTitle ?? storageGroupCategories[key].title;
    });
});

export const getStorageGroupCategoryOrDefault = (key, appContext) =>
    appContext.storageGroupCategories.find(o => o.key === key)
    ??
    appContext.storageGroupCategories[0];

export default storageGroupCategories;
