import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { useAppContext } from 'context/AppContext';
import { relativeDateFormat } from 'helpers/DateHelper';
import strings from 'localization/strings';

import Box from '@material-ui/core/Box';
import Text from 'common/Text';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(({ theme, colors }) => ({
    message: {
        flex: '1 1 100%'
    },
    messageContent: {
        width: '48%',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            maxWidth: '350px'
        }
    },
    textContent: {
        padding: theme.spacing(2.5, 1.875),
        borderRadius: '24px',
        position: 'relative',
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            height: '24px',
            width: '24px',
            transform: 'translateY(100%)',
        },
        '&::after': {
            backgroundColor: colors.white
        }
    },
    sender: {
        '& $messageContent': {
            marginLeft: 'auto'
        },
        '& $textContent': {
            backgroundColor: colors.intermediateBackgroundColor,
            borderBottomRightRadius: '0',
            '&::before': {
                right: 0,
                backgroundColor: colors.intermediateBackgroundColor
            },
            '&::after': {
                right: 0,
                borderTopRightRadius: '24px'
            }
        }
    },
    receiver: {
        '& $messageContent': {
            marginRight: 'auto'
        },
        '& $textContent': {
            backgroundColor: colors.lightBackgroundColor,
            borderBottomLeftRadius: '0',
            '&::before': {
                left: 0,
                backgroundColor: colors.lightBackgroundColor
            },
            '&::after': {
                left: 0,
                borderTopLeftRadius: '24px'
            }
        }
    },
    time: {
        fontSize: '12px',
        textTransform: 'uppercase',
        textAlign: 'center'
    }
}));

const ConversationMessage = ({ message, actor }) => {
    const { appContext } = useAppContext();

    const classes = useStyles();

    const isOwnMessage = message.writerActor.id === actor.id;
    const writerName = message.adminUser ? strings.companyName : message.writerActor.name;

    return (
        <Box className={`${classes.message} ${isOwnMessage ? classes.sender : classes.receiver}`}>
            <Box className={classes.messageContent}>
                <Box className={classes.messageHeader}>
                    <Typography className={classes.time} variant="body1">{relativeDateFormat(message.createdTime, appContext)}</Typography>
                </Box>
                <Box className={classes.textContent}>
                    <Typography variant="body1">{writerName}</Typography>
                    <Text html={message.text} />
                </Box>
            </Box>

        </Box>
    );
};

ConversationMessage.propTypes = {
    message: PropTypes.object.isRequired,
    actor: PropTypes.object.isRequired
};

export default ConversationMessage;
