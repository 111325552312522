import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import HtmlLink from '@material-ui/core/Link';
import { Link as ReactRouterLink } from 'react-router-dom';

// renders a link with a button appearence: <a href="...">...</a>

const useStyles = makeStyles(({ colors, fonts, styles }) => ({
    buttonLink: {
        display: 'inline-block',
        fontFamily: fonts.bold,
        '& span': {
            fontFamily: fonts.bold
        },
        fontSize: '1rem',
        textDecoration: 'none',
        lineHeight: 1,
        letterSpacing: 'normal',
        padding: '22px 30px',
        borderRadius: styles.buttonBorderRadius,
        backgroundColor: colors.primaryColor,
        color: `${colors.white} !important`,
        boxShadow: 'none',
        // boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: colors.primaryHoverColor
        },
        '&:active': {
            textDecoration: 'none',
            backgroundColor: colors.primaryHoverColor
        }
    },
    white: {
        fontFamily: fonts.light,
        color: `${colors.textColor} !important`,
        backgroundColor: colors.white,
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: colors.lightButtonColor
        },
        '&:active': {
            backgroundColor: colors.lightButtonColor
        }
    },
    light: {
        fontFamily: fonts.light,
        color: `${colors.textColor} !important`,
        backgroundColor: colors.lightButtonColor,
        '&:hover': {
            color: `${colors.white} !important`,
            backgroundColor: colors.lightButtonHoverColor
        },
        '&:active': {
            color: `${colors.white} !important`,
            backgroundColor: colors.lightButtonHoverColor
        }
    },
    dark: {
        color: `${colors.white} !important`,
        backgroundColor: colors.darkButtonColor,
        '&:hover': {
            backgroundColor: colors.darkButtonHoverColor,
        },
        '&:active': {
            backgroundColor: colors.darkButtonHoverColor,
        }
    }
}),
({ colors }) => ({
    storage: {
        light: {
            '&:hover': {
                color: `${colors.textColor} !important`,
            },
            '&:active': {
                color: `${colors.textColor} !important`,
            }
        }
    }
}));

const ButtonLink = ({ variant, className, ...rest }) => {
    const classes = useStyles();
    const classNames = [classes.buttonLink];

    if(className) {
        classNames.push(className);
    }

    if(classes[variant]) {
        classNames.push(classes[variant]);
    }

    if(rest.to) {
        return (
            <ReactRouterLink
                {...rest}
                className={classNames.join(' ')}
            />
        );
    }
    return (
        <HtmlLink
            {...rest}
            className={classNames.join(' ')}
        />
    );
};

ButtonLink.propTypes = {
    variant: PropTypes.string,
    className: PropTypes.string
};

export default ButtonLink;
