import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Form from 'form/Form';
import { TextField } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import ButtonContainer from 'common/ButtonContainer';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import IconPrefix from 'common/IconPrefix';

const useStyles = makeStyles(() => ({
    filterAccordion: {
        '&.Mui-expanded': {
            margin: 0
        }
    },
    filterForm: {
        width: '100%',
        maxWidth: '100%'
    }
}));

const BookingFilter = ({ storageSites, onFilterChange, filterParams, expanded, onExpandedChange }) => {
    const classes = useStyles();
    const handleFormSubmit = formValues => {
        onFilterChange({
            storageSiteId: formValues.storageSiteId === 'all'
                ? undefined
                : formValues.storageSiteId,
            q: formValues.q ?? '',
            page: 1
        });
    };

    const initialValues = {
        storageSiteId: filterParams.storageSiteId ?? 'all',
        q: filterParams.q ?? ''
    };

    return (
        <Accordion className={classes.filterAccordion} expanded={expanded} onChange={onExpandedChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <IconPrefix icon={<SearchIcon/>}>
                    {strings.search}
                </IconPrefix>
            </AccordionSummary>
            <AccordionDetails>
                <Form
                    initialValues={initialValues}
                    onSubmit={handleFormSubmit}
                >
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className={classes.filterForm}>
                            {
                                storageSites && storageSites.length > 1 &&
                                (
                                    <TextField
                                        select
                                        name="storageSiteId"
                                        label={strings.storageSite}
                                        variant="outlined"
                                    >
                                        <MenuItem value="all">{strings.allStorageSites}</MenuItem>
                                        {
                                            storageSites.map(storageSite => (
                                                <MenuItem key={storageSite.id} value={storageSite.id}>{storageSite.title}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                )
                            }
                            <TextField
                                name="q"
                                label={strings.searchText}
                                placeholder={strings.searchBookingsPlaceholder}
                                variant="outlined"
                            />

                            <Typography variant="body2" gutterBottom>
                                {strings.contactSupportToSearchOldBookings}
                            </Typography>

                            <ButtonContainer>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    {strings.search}
                                </Button>
                            </ButtonContainer>
                        </form>
                    )}
                </Form>
            </AccordionDetails>
        </Accordion>
    );
};

BookingFilter.propTypes = {
    storageSites: PropTypes.array,
    onFilterChange: PropTypes.func.isRequired,
    onExpandedChange: PropTypes.func.isRequired,
    filterParams: PropTypes.object.isRequired,
    expanded: PropTypes.bool.isRequired
};

export default BookingFilter;
