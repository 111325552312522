import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';
import queryString from 'query-string';

export const ACCOUNT_CONVERSATIONS_SECTION_LIST_CONVERSATIONS = 'ACCOUNT_CONVERSATIONS_SECTION_LIST_CONVERSATIONS';

export const ACCOUNT_CONVERSATIONS_SECTION_FETCH_CONVERSATION = 'ACCOUNT_CONVERSATIONS_SECTION_FETCH_CONVERSATION';

export const listConversations = (actorId, page, pageSize ) => ({
    [RSAA]: {
        types: createRsaaTypes(ACCOUNT_CONVERSATIONS_SECTION_LIST_CONVERSATIONS),
        endpoint: `/api/actors/${actorId}/conversations?${queryString.stringify({ page, pageSize })}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const fetchConversation = conversationId => ({
    [RSAA]: {
        types: createRsaaTypes(ACCOUNT_CONVERSATIONS_SECTION_FETCH_CONVERSATION),
        endpoint: `/api/conversations/${conversationId}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});
