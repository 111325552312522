import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import bookingStatuses from 'enums/bookingStatuses';

import Box from '@material-ui/core/Box';
import BookingDetailsSectionCaption from './BookingDetailsSectionCaption';

const Agreement = ({ booking }) => {
    const title = booking.bookingStatus === bookingStatuses.purchased.key
        ? strings.downloadAgreement
        : strings.downloadDraftAgreement;

    return (
        <Box>
            <BookingDetailsSectionCaption>
                {strings.agreement}
            </BookingDetailsSectionCaption>
            <a
                href={booking.agreementUrl}
                target="_blank"
            >
                {title} ({strings.pdf})
            </a>
        </Box>
    );
};

Agreement.propTypes = {
    booking: PropTypes.object.isRequired
};

export default Agreement;
