import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import queryString from 'query-string';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AccountNavigation from 'account/AccountNavigation';
import PageTitle from 'common/PageTitle';

const useStyles = makeStyles(({ theme }) => ({
    buttonContainer: {
        padding: theme.spacing(5, 0),
    },
    smSmall: {
        lineHeight: 1,
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.caption.fontSize,
            lineHeight: theme.typography.caption.lineHeight,
            letterSpacing: theme.typography.caption.letterSpacing,
        },
    },
    smallH1: {
        fontSize: '3.25rem',
        lineHeight: '3.4125rem',
        letterSpacing: '-2.08px',
        marginBottom: theme.spacing(2.5),
        [theme.breakpoints.down('xs')]: {
            fontSize: '2.25rem',
            lineHeight: '2.3625rem',
            letterSpacing: '-1.08px',
        },
    }
}));

const OverviewSection = ({ accountRoutes }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const { showWelcomeMessage } = queryString.parse(location.search);

    const formatAccountIntroduction = sitename => strings.formatString(strings.accountNavigation.introduction, sitename);

    return (
        <>
            <PageTitle>{strings.accountTabs.overview}</PageTitle>

            {
                showWelcomeMessage &&
                (
                    <>
                        <Typography variant="h1" className={classes.smallH1}>
                            {strings.accountNavigation.welcome}
                        </Typography>
                        <Typography>
                            {strings.initialLoginWelcomeMessage}
                        </Typography>
                    </>
                )
            }

            {
                !showWelcomeMessage &&
                (
                    <>
                        <Typography variant="h1" className={classes.smallH1}>
                            {strings.accountNavigation.hello}
                        </Typography>
                        <Typography>
                            {formatAccountIntroduction(appContext.company.name)}
                        </Typography>
                    </>
                )
            }

            <Box className={classes.buttonContainer}>
                <AccountNavigation routes={accountRoutes}/>
            </Box>
        </>
    );
};

OverviewSection.propTypes = {
    accountRoutes: PropTypes.array.isRequired
};

export default OverviewSection;
