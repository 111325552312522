import React, { useEffect, useState } from 'react';
import { makeStyles } from 'styles/util';
import { useAppContext } from 'context/AppContext';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import { fetchTakeoverTenant } from 'actions/takeoverTenants';
import { getStorageTitle } from 'helpers/StorageSiteHelper';
import strings from 'localization/strings';
import routes from 'routes';
import { addQuerystringParametersToUrl } from 'helpers/BrowserHelper';
import { performConnectTakeoverBooking } from 'logic/authenticationLogic';
import queryString from 'query-string';

import PageTitle from 'common/PageTitle';
import MuiContainer from '@material-ui/core/Container';
import Loader from 'common/Loader';
import Form from 'form/Form';
import { Radios } from 'mui-rff';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgressButton from 'common/CircularProgressButton';
import ContentBox from 'common/ContentBox';
import Text from 'common/Text';
import ActorSelectorDialog from 'components/actorSelector/ActorSelectorDialog';

const useStyles = makeStyles(({ theme, fonts }) => ({
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3.75),
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(3.75),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2.5)
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3.75)
    },
    caption: {
        fontFamily: fonts.bold
    }
}));

// if booking already connected to actor:
// - redirect to booking details page

// if booking not connected to actor:
// - if logged in:
//   - ask which actor to connect the booking to, or to log in with another actor
// - if not logged in:
//   - show create actor form
//   - after actor creation, connect the booking to the actor and redirect to booking details page

const TakeoverTenantPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { takeoverTenantId, token } = useParams();
    const { isAuthenticated, user, selectedActor, actors } = useSelector(state => state.authentication.context);
    const { isLoadingAuthenticationContext } = useSelector(state => state.authentication.loading);
    const { appContext } = useAppContext();
    const { connect } = queryString.parse(location.search);

    const [takeoverTenant, setTakeoverTenant] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [accountModeSelected, setAccountModeSelected] = useState(false);
    const [actorSelectorDialogOpen, setActorSelectorDialogOpen] = useState(false);

    const accountModes = {
        authenticated: { enabled: isAuthenticated },
        another: { enabled: isAuthenticated},
        existing: { enabled: !isAuthenticated },
        new: { enabled: true }
    };

    const propertiesToCopy = ['radioLabel', 'submitLabel'];
    Object.keys(accountModes).forEach(key => {
        accountModes[key].key = key;
        propertiesToCopy.forEach(p => {
            accountModes[key][p] = strings.accountModes[key][p];
        });
    });

    if(isAuthenticated) {
        accountModes.authenticated.radioLabel = strings.formatString(accountModes.authenticated.radioLabel, selectedActor.name);
        accountModes.authenticated.submitLabel = strings.formatString(accountModes.authenticated.submitLabel, selectedActor.name);
    }

    const accountModeOptions = Object.values(accountModes)
        .filter(o => o.enabled)
        .map(o => ({
            value: o.key,
            label: o.radioLabel
        }));

    useEffect(() => {
        dispatch(fetchTakeoverTenant(takeoverTenantId, token, true /* setLinkClickedStatus */))
            .then(handleResponse(
                response => {
                    if(response.payload.isConnectedToUser) {
                        // this booking has already an actor
                        // redirect to booking details page
                        history.push(routes.account.tenantBookingDetails.replace(':bookingId', response.payload.booking.id));
                    } else {
                        setTakeoverTenant(response.payload);
                    }
                },
                () => setIsLoading(false)
            ));
    }, [takeoverTenantId, token]);

    useEffect(() => {
        if(takeoverTenant) {
            if(connect) {
                connectToAuthenticatedActor();
            } else {
                setIsLoading(false);
            }
        }
    }, [connect, takeoverTenant]);

    const handleFormSubmit = values => {
        const parameters = {
            takeoverTenantId,
            takeoverTenantToken: token
        };
        switch(values.accountMode) {
            case accountModes.authenticated.key:
                connectToAuthenticatedActor();
                break;
            case accountModes.another.key:
            case accountModes.existing.key:
                history.push(addQuerystringParametersToUrl(routes.eidLogIn, parameters));
                break;
            case accountModes.new.key:
                history.push(addQuerystringParametersToUrl(routes.createAccount, parameters));
                break;
            default:
                break;
        }
    };

    const handleActorSelectorDialogOk = actorId => {
        setActorSelectorDialogOpen(false);
        performConnectTakeoverBooking({
            takeoverTenant: { id: takeoverTenantId, token },
            userId: user.id,
            actorId,
            setIsLoading,
            dispatch,
            history
        });
    };

    const connectToAuthenticatedActor = () => {
        setAccountModeSelected(true);
        if(actors.length > 1) {
            // if multiple actors, select actor
            setActorSelectorDialogOpen(true);
        } else {
            // otherwise connect immediately
            performConnectTakeoverBooking({
                takeoverTenant: { id: takeoverTenantId, token },
                userId: user.id,
                actorId: actors[0].id,
                setIsLoading,
                dispatch,
                history
            });
        }
    };

    const getMessage = () => strings.formatString(
        strings.takeover.helloX,
        isAuthenticated ? selectedActor.name : takeoverTenant.name ?? takeoverTenant.firstName
    );

    const title = isLoading
        ? strings.loadingText
        : strings.formatString(strings.takeover.landingPageTitle, takeoverTenant.storageSite.title, getStorageTitle(takeoverTenant.storage, takeoverTenant.storage.storageGroup, appContext));

    const initialValues = {
        accountMode: null
    };

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <MuiContainer maxWidth={false}>
                <ContentBox className={classes.pageWrapper} themeMaxWidth="xs">
                    <Typography variant="h2">
                        {title}
                    </Typography>

                    {
                        (isLoading || isLoadingAuthenticationContext) &&
                        (
                            <Loader />
                        )
                    }

                    {
                        !(isLoading || isLoadingAuthenticationContext) &&
                        (
                            <Form
                                initialValues={initialValues}
                                onSubmit={handleFormSubmit}
                            >
                                {({ values, handleSubmit }) => (
                                    <form onSubmit={handleSubmit} className={classes.form}>
                                        <Text html={getMessage()} />

                                        <Box>
                                            <Typography variant="body1" className={classes.caption} gutterBottom>
                                                {strings.selectAccountMode}
                                            </Typography>

                                            <Radios
                                                name="accountMode"
                                                data={accountModeOptions}
                                                disabled={accountModeSelected}
                                            />
                                        </Box>

                                        {
                                            values.accountMode &&
                                            (
                                                <CircularProgressButton
                                                    type="submit"
                                                    className={classes.button}
                                                    isLoading={accountModeSelected}
                                                >
                                                    {accountModes[values.accountMode]?.submitLabel}
                                                </CircularProgressButton>
                                            )
                                        }
                                    </form>
                                )}
                            </Form>
                        )
                    }
                </ContentBox>
            </MuiContainer>
            <ActorSelectorDialog
                title={strings.takeover.actorSelectorDialogTitle}
                description={strings.takeover.actorSelectorDialogBody}
                open={actorSelectorDialogOpen}
                onOk={handleActorSelectorDialogOk}
            />
        </>
    );
};

export default TakeoverTenantPage;
