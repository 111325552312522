import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { handleResponse } from 'actions/actionHelpers';
import { getTakeover, createBookingsForTakeoverTenants } from 'actions/admin/takeovers';
import routes from 'routes';
import takeoverTenantSelections from 'enums/takeoverTenantSelections';
import takeoverTenantStatuses from 'enums/takeoverTenantStatuses';

import Loader from 'common/Loader';
import AdminPage from '../../presentational/AdminPage';
import AdminFormWrapper from '../../presentational/AdminFormWrapper';
import AdminFormFields from '../../presentational/AdminFormFields';
import AdminFormButtons from '../../presentational/AdminFormButtons';
import Form from 'form/Form';
import Button from '@material-ui/core/Button';
import EmailFormFields from './EmailFormFields';
import { Link } from 'react-router-dom';

const CreateBookingsForTenants = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [takeover, setTakeover] = useState(undefined);

    const initialValues = {
        subject: '',
        body: '',
        filter: {
            selection: takeoverTenantSelections.bookingNotYetCreated.key,
            customSelectedTakeoverTenantIds: []
        }
    };

    const backUrl = routes.admin.takeover.replace(':takeoverId', params.takeoverId);

    useEffect(() => {
        dispatch(getTakeover(params.takeoverId))
            .then(handleResponse(
                response => {
                    setTakeover(response.payload);
                    setIsLoading(false);
                },
                () => setIsLoading(false)
            ));
    }, []);

    const handleFormSubmit = formValues => {
        setIsSubmitting(true);
        const request = {
            subject: formValues.subject,
            body: formValues.body,
            filter: formValues.filter
        };

        dispatch(createBookingsForTakeoverTenants(params.takeoverId, request))
            .then(handleResponse(
                response => {
                    history.push(routes.admin.takeover.replace(':takeoverId', params.takeoverId));
                    return { snackbar: { message: `${response.payload.count} invoices were successfully sent.` } };
                },
                () => setIsSubmitting(false)
            ));
    };

    const title = 'Create bookings for tenants: ' + (takeover ? takeover.storageSite.title : 'Loading...');

    const takeoverTenants = takeover?.tenants.filter(o => o.status === takeoverTenantStatuses.draft.key);

    const selections = [
        takeoverTenantSelections.bookingNotYetCreated,
        takeoverTenantSelections.custom
    ];

    return (
        <AdminPage title={title}>
            {
                isLoading && <Loader />
            }
            {
                takeover &&
                (
                    <>
                        <AdminFormWrapper isSubmitting={isSubmitting}>
                            <Form
                                initialValues={initialValues}
                                onSubmit={handleFormSubmit}
                            >
                                {({ handleSubmit, values }) => (
                                    <form onSubmit={handleSubmit}>
                                        <AdminFormFields>
                                            <EmailFormFields
                                                takeover={takeover}
                                                takeoverTenants={takeoverTenants}
                                                selections={selections}
                                                formValues={values}
                                            />
                                        </AdminFormFields>

                                        <AdminFormButtons>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Create bookings and send invoices
                                            </Button>
                                            <Button
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                component={Link}
                                                to={backUrl}
                                            >
                                                Cancel
                                            </Button>
                                        </AdminFormButtons>
                                    </form>
                                )}
                            </Form>
                        </AdminFormWrapper>
                    </>
                )
            }
        </AdminPage>
    );
};

export default CreateBookingsForTenants;
