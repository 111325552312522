import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';

import Box from '@material-ui/core/Box';
import BookingDetailsSectionCaption from './BookingDetailsSectionCaption';
import ButtonLink from 'common/ButtonLink';
import IconPrefix from 'common/IconPrefix';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const BookingLockCode = ({ booking }) => {
    return (
        <Box>
            <BookingDetailsSectionCaption>
                {strings.bookingLockCode}
            </BookingDetailsSectionCaption>
            <ButtonLink
                target="_blank"
                variant="contained"
                href={booking.lockCodeUrl}
            >
                <IconPrefix icon={<VpnKeyIcon/>}>
                    {strings.bookingLockCodeLink}
                </IconPrefix>
            </ButtonLink>
        </Box>
    );
};

BookingLockCode.propTypes = {
    booking: PropTypes.object.isRequired
};

export default BookingLockCode;
