import React, { useState } from 'react';
import { useAppContext } from 'context/AppContext';
import { initializeGoogleMaps } from 'helpers/MapHelper';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';
import GoogleTagManagerProvider from './GoogleTagManagerProvider';
import ResourceProvider from './ResourceProvider';
import CacheBuster from './CacheBuster';
import Routes from './Routes';
import DateFnsUtils from '@date-io/date-fns';
import Loader from 'common/Loader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Head from './Head';
import { BrowserRouter } from 'react-router-dom';
import { userAgentIsBot } from 'helpers/ConfigurationHelper';
import { querystringParameterSet } from 'helpers/BrowserHelper';
import TelemetryProvider from './telemetryProvider/TelemetryProvider';
import SitePasswordProtector from './SitePasswordProtector';

// container component that hosts other components that are dependent on app context
// nothing (except for a script tag for Google Tag Manager) is rendered to the DOM until SitePasswordProtector is reached
const AppContextRoot = () => {
    const { appContext, stripePromise } = useAppContext();
    const minimal = querystringParameterSet('minimal');

    // start with some initialization of components that are dependent on appContext

    // the 'stripe' prop of StripeElements shouldn't be modified
    // therefore, we need to resolve stripePromise before rendering
    // this mechanism ensures correct handling
    const [ stripeObjectResolved, setStripeObjectResolved ] = useState(minimal);

    if(!minimal) {
        stripePromise.then(
            () => {
                // now, we are ready to render
                setStripeObjectResolved(true);
            }
        );
        initializeGoogleMaps(appContext);
    }

    const applicationInsightsConnectionString = userAgentIsBot() || minimal
        ? undefined
        : appContext.applicationInsightsConnectionString;

    return (
        <GoogleTagManagerProvider enabled={!minimal}>
            <ThemeProvider theme={appContext.theme}>
                <CssBaseline />
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={appContext.dateFnsLocale}>
                    <ResourceProvider>
                        <Head />
                        {
                            !stripeObjectResolved && <Loader />
                        }
                        {
                            stripeObjectResolved &&
                            (
                                <CacheBuster enabled={!minimal}>
                                    {({ isLoading, isLatestVersion, refreshCacheAndReload }) => {
                                        if (isLoading) {
                                            return <Loader />;
                                        }
                                        if (!isLoading && !isLatestVersion) {
                                            refreshCacheAndReload();
                                        }
                                        return (
                                            <BrowserRouter>
                                                {/* TelemetryProvider must be placed inside BrowserRouter since it uses ReactRouter */}
                                                <TelemetryProvider applicationInsightsConnectionString={applicationInsightsConnectionString}>
                                                    <SitePasswordProtector>
                                                        <Routes/>
                                                    </SitePasswordProtector>
                                                </TelemetryProvider>
                                            </BrowserRouter>
                                        );
                                    }}
                                </CacheBuster>
                            )
                        }
                    </ResourceProvider>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </GoogleTagManagerProvider>
    );
};

export default AppContextRoot;
