import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Form from 'form/Form';
import { TextField } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import AdminFormWrapper from 'admin/presentational/AdminFormWrapper';
import AdminFormButtons from 'admin/presentational/AdminFormButtons';
import AdminFormFields from 'admin/presentational/AdminFormFields';
import purchaseStatuses from 'enums/purchaseStatuses';
import { getShortDateFormat } from 'helpers/DateHelper';
import NullableDatePicker from 'form/NullableDatePicker';

const SearchPurchasesForm = ({ searchParameters, onSubmit, isSubmitting = false }) => {
    const statuses = [{
        key: 'all',
        title: '[all]'
    }].concat(Object.values(purchaseStatuses).map(o => ({ key: o.key, title: o.key })));

    return (
        <AdminFormWrapper isSubmitting={isSubmitting}>
            <Form
                initialValues={searchParameters}
                onSubmit={onSubmit}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <AdminFormFields>
                            <TextField
                                select
                                name="status"
                                label="Status"
                                variant="outlined"
                            >
                                {
                                    statuses.map(item =>
                                        <MenuItem key={item.key} value={item.key}>{item.title}</MenuItem>
                                    )
                                }
                            </TextField>

                            <TextField
                                name="ownerName"
                                label="Owner name"
                                variant="outlined"
                            />

                            <TextField
                                name="storageSiteTitle"
                                label="Storage site"
                                variant="outlined"
                            />

                            <NullableDatePicker
                                name="updatedDateFrom"
                                label="Updated from"
                                formatFunc={getShortDateFormat}
                                inputVariant="outlined"
                                nullString={''}
                                nullButtonLabel="Clear"
                            />
                            <NullableDatePicker
                                name="updatedDateTo"
                                label="Updated to"
                                formatFunc={getShortDateFormat}
                                inputVariant="outlined"
                                nullString={''}
                                nullButtonLabel="Clear"
                            />
                        </AdminFormFields>
                        <AdminFormButtons>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Search
                            </Button>
                        </AdminFormButtons>
                    </form>
                )}
            </Form>
        </AdminFormWrapper>
    );
};

SearchPurchasesForm.propTypes = {
    searchParameters: PropTypes.object,
    onSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool
};

export default SearchPurchasesForm;
