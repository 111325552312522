import React  from 'react';
import { makeStyles } from 'styles/util';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { useScrollToTop } from 'helpers/BrowserHelper';

import Container from 'common/Container';
import PageTitle from 'common/PageTitle';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Text from 'common/Text';
import StoreHouseImage from 'assets/images/landing-pages/store-house.jpg';
import TeamMember from './TeamMember';
import BoardPositionMembers from './BoardPositionMembers';

const useStyles = makeStyles(({ theme, colors }) => ({
    aboutMainContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(8),
        marginBottom: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            gap: theme.spacing(5),
            flexDirection: 'column'
        }
    },
    aboutContainer: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        gap: theme.spacing(2)
    },
    image: {
        flex: '0 0 auto',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    teamBoardContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.white,
        margin: theme.spacing(4, 0),
        padding: theme.spacing(8),
        gap: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(6)
        }
    },
    teamBoardColor: {
        backgroundColor: colors.secondaryColor,
        '& .MuiTypography-root': {
            color: colors.white
        }
    },
    teamMembersContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: theme.spacing(5),
        flexWrap: 'wrap'
    },
}));

const AboutPage = () => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    useScrollToTop();

    const aboutStrings = strings.pages.about;
    const teamStrings = aboutStrings.team;
    const board = aboutStrings.board;

    return (
        <Container withPageMargins>
            <PageTitle>{strings.aboutUs}</PageTitle>
            <Box className={classes.aboutMainContainer}>
                <Box className={classes.aboutContainer}>
                    <Typography variant="h1" gutterBottom>{strings.aboutUs}</Typography>
                    <Text text={aboutStrings.description} />
                </Box>
                <img className={classes.image} src={StoreHouseImage} />
            </Box>

            <Typography variant="h2" gutterBottom>{aboutStrings.contact}</Typography>
            <Typography variant="body1" gutterBottom>{strings.companyFormalName}</Typography>
            <Typography variant="body1" gutterBottom>{aboutStrings.address}</Typography>
            <Typography variant="body1" gutterBottom><a href={`mailto:${appContext.company.email}`}>{appContext.company.email}</a></Typography>
            <Typography variant="body1" gutterBottom>{appContext.company.telephone}</Typography>
            <Typography variant="body1" gutterBottom>{strings.phoneOpeningHours}</Typography>

            <Box className={classes.teamBoardContainer + ' ' + classes.teamBoardColor}>
                <Typography variant="h2" gutterBottom>{teamStrings.title}</Typography>
                <Box className={classes.teamMembersContainer}>
                    {Object.entries(teamStrings.members).map(([name, member]) => (
                        <TeamMember key={name} member={member} imageName={name} />
                    ))}
                </Box>
            </Box>
            <Box className={classes.teamBoardContainer}>
                <Typography variant="h2" display="block" gutterBottom>{board.title}</Typography>
                <Box className={classes.teamMembersContainer}>
                    <BoardPositionMembers position={board.chairman.title} members={board.chairman.content.split(', ')} />
                    <BoardPositionMembers position={board.members.title} members={board.members.content.split(', ')} />
                    <BoardPositionMembers position={board.substituteMembers.title} members={board.substituteMembers.content.split(', ')} />
                    <BoardPositionMembers position={board.advisoryBoard.title} members={board.advisoryBoard.content.split(', ')} />
                    <BoardPositionMembers position={board.investors.title} members={board.investors.content.split(', ')} />
                </Box>
            </Box>
        </Container>
    );
};

export default AboutPage;
