import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOwnerBookings } from 'actions/account/ownerBookingsSection';
import { fetchStorageSites } from 'actions/account/storageSitesSection';
import strings from 'localization/strings';
import queryString from 'query-string';
import { getTabs, isStale, parseFilterParams } from './BookingsSectionHelper';
import routes from 'routes';
import { handleResponse } from 'actions/actionHelpers';

import BookingsSection from './BookingsSection';
import OwnerBookingListItem from './OwnerBookingListItem';
import BookingFilter from './BookingFilter';
import CopyBookingEmailsToClipboardDialog from './CopyBookingEmailsToClipboardDialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';

const OwnerBookingsSection = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const filterParams = parseFilterParams(location);
    const filterQueryString = queryString.stringify(filterParams);
    const initialBookingFilterIsExpanded = !!(filterParams.storageSiteId || filterParams.q);

    const [bookingsResult, setBookingsResult] = useState({});
    const [bookingsFetchedTime, setBookingsFetchedTime] = useState(undefined);
    const [isLoadingBookings, setIsLoadingBookings] = useState(undefined);
    const [bookingsLoadingError, setBookingsLoadingError] = useState(undefined);
    const [menuAnchorElement, setMenuAnchorElement] = useState(undefined);
    const [copyBookingEmailsToClipboardDialogOpen, setCopyBookingEmailsToClipboardDialogOpen] = useState(false);
    const [bookingFilterExpanded, setBookingFilterExpanded] = useState(initialBookingFilterIsExpanded);

    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;
    const storageSites = useSelector(state => state.account.storageSitesSection.storageSites);
    const isLoadingStorageSites = useSelector(state => state.account.storageSitesSection.isLoading);
    const isLoading = isLoadingBookings || isLoadingStorageSites;
    const storageSitesLoadingError = useSelector(state => state.account.storageSitesSection.loadingError);
    const loadingError = !!(bookingsLoadingError || storageSitesLoadingError);

    useEffect(() => {
        history.push(`${routes.account.ownerBookings}?${filterQueryString}`);
        fetchBookings(true);
    }, [filterQueryString]);

    useEffect(() => {
        if(isStale(bookingsFetchedTime)) {
            fetchBookings(false);
        }
    }, [isStale(bookingsFetchedTime)]);

    // reload every other minute, but without showing the loading indicator
    useEffect(() => {
        const timer = window.setInterval(() => fetchBookings(false), 120000);
        return () => window.clearInterval(timer);
    }, []);

    useEffect(() => {
        if(!storageSites) {
            dispatch(fetchStorageSites(selectedActor.id));
        }
    }, [storageSites]);

    const fetchBookings = isLoadingValue => {
        setIsLoadingBookings(isLoadingValue);
        setBookingsLoadingError(undefined);
        const request = {
            ownerActorId: selectedActor.id,
            adminBookingStatus: filterParams.adminBookingStatus,
            storageSiteId: filterParams.storageSiteId,
            q: filterParams.q,
            page: filterParams.page - 1,
            pageSize: filterParams.pageSize
        };
        dispatch(fetchOwnerBookings(request))
            .then(handleResponse(
                response => {
                    setBookingsResult(response.payload);
                    const now = new Date().getTime();
                    setBookingsFetchedTime(now);
                    setIsLoadingBookings(false);
                },
                response => {
                    setBookingsLoadingError(response);
                    setIsLoadingBookings(false);
                }
            ));
    };

    const handleFilterChange = changedFilterParams => {
        const newFilterParams = { ...filterParams, ...changedFilterParams };
        const newFilterQueryString = queryString.stringify(newFilterParams);
        history.push(`${routes.account.ownerBookings}?${newFilterQueryString}`);
    };

    const handleOpenMenu = event => {
        setMenuAnchorElement(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchorElement(undefined);
    };

    const handleOpenCopyBookingEmailsToClipboardDialog = () => {
        handleCloseMenu();
        setCopyBookingEmailsToClipboardDialogOpen(true);
    };

    const handleCloseCopyBookingEmailsToClipboardDialog = () => {
        setCopyBookingEmailsToClipboardDialogOpen(false);
    };

    const handleFilterExpandedChange = () => {
        setBookingFilterExpanded(!bookingFilterExpanded);
    };

    const title = selectedActor.tenantBookingCount > 0 && selectedActor.isOwner
        ? strings.accountTabs.ownerBookings
        : strings.accountTabs.bookings;

    const titleActionPanel = (
        <Box>
            <Button aria-controls="context-menu" aria-haspopup="true" onClick={handleOpenMenu}>
                <EmailOutlinedIcon/>
            </Button>
            <Menu
                id="context-menu"
                anchorEl={menuAnchorElement}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                open={Boolean(menuAnchorElement)}
                onClose={handleCloseMenu}
                getContentAnchorEl={undefined}
            >
                <MenuItem onClick={handleOpenCopyBookingEmailsToClipboardDialog}>{strings.copyBookingEmailsToClipboard}</MenuItem>
            </Menu>
        </Box>
    );

    const bookingFilter = (
        <BookingFilter
            storageSites={storageSites}
            filterParams={filterParams}
            onFilterChange={handleFilterChange}
            onExpandedChange={handleFilterExpandedChange}
            expanded={bookingFilterExpanded}
        />
    );

    const tabs = getTabs(true /* excludeCanceledBookings */);

    return (
        <>
            <BookingsSection
                storageSites={storageSites}
                bookingsResult={bookingsResult}
                isLoading={isLoading}
                loadingError={loadingError}
                tabs={tabs}
                filterParams={filterParams}
                onFilterChange={handleFilterChange}
                title={title}
                titleActionPanel={titleActionPanel}
                renderBooking={booking => <OwnerBookingListItem booking={booking}/>}
                bookingFilter={bookingFilter}
            />
            <CopyBookingEmailsToClipboardDialog
                filterParams={filterParams}
                open={copyBookingEmailsToClipboardDialogOpen}
                onClose={handleCloseCopyBookingEmailsToClipboardDialog}
            />
        </>
    );
};

export default OwnerBookingsSection;
