import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';
import queryString from 'query-string';

export const ACCOUNT_OWNER_BOOKINGS_SECTION_LIST_BOOKINGS = 'ACCOUNT_OWNER_BOOKINGS_SECTION_LIST_BOOKINGS';

export const fetchOwnerBookings = ({ ownerActorId, ...rest }) => ({
    [RSAA]: {
        types: createRsaaTypes(ACCOUNT_OWNER_BOOKINGS_SECTION_LIST_BOOKINGS),
        endpoint: `/api/actors/${ownerActorId}/ownerBookings?${queryString.stringify(rest)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});
