import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import Typography from '@material-ui/core/Typography';
import PriceSpecificationTableNew from 'common/PriceSpecification/PriceSpecificationTableNew';

const useStyles = makeStyles(({ theme }) => ({
    caption: {
        marginTop: theme.spacing(2)
    }
}));

const AcceptPriceSpecification = ({ booking, bookingPriceInfo }) => {
    const classes = useStyles();

    const showVat = [
        ...(bookingPriceInfo.oneTime?.purchaseItems ?? []),
        ...(bookingPriceInfo.perMonth?.purchaseItems ?? []),
        ...(bookingPriceInfo.wholePeriod?.purchaseItems ?? [])
    ].filter(o => o.vat).length > 0;

    const hasOneTimePurchaseItems = bookingPriceInfo.oneTime.purchaseItems.length > 0;

    const getAmount = amounts => amounts.totalAmount + amounts.totalVat + amounts.roundingCorrection;

    const getOneTimePaymentSpecificationLabel = amounts => getAmount(amounts) < 0
        ? strings.bookingOneTimeDiscountSpecification
        : strings.bookingOneTimePaymentSpecification;

    return (
        <>
            {
                booking.subscriptionBooking &&
                (
                    <>
                        {hasOneTimePurchaseItems && <Typography variant="h6" className={classes.caption} gutterBottom>{strings.bookingMonthlyPaymentSpecification}</Typography>}
                        <PriceSpecificationTableNew booking={booking} purchaseItems={bookingPriceInfo.perMonth.purchaseItems} showVat={showVat} />
                        {
                            hasOneTimePurchaseItems &&
                            (
                                <>
                                    <Typography variant="h6" className={classes.caption} gutterBottom>{getOneTimePaymentSpecificationLabel(bookingPriceInfo.oneTime.amounts)}</Typography>
                                    <PriceSpecificationTableNew booking={booking} purchaseItems={bookingPriceInfo.oneTime.purchaseItems} showVat={showVat} />
                                </>
                            )
                        }
                    </>
                )
            }

            {
                !booking.subscriptionBooking &&
                (
                    <PriceSpecificationTableNew booking={booking} purchaseItems={bookingPriceInfo.wholePeriod.purchaseItems} showVat={showVat} />
                )
            }
        </>
    );
};

AcceptPriceSpecification.propTypes = {
    booking: PropTypes.object.isRequired,
    bookingPriceInfo: PropTypes.object.isRequired
};

export default AcceptPriceSpecification;
