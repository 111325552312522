import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';
import queryString from 'query-string';

export const ACCOUNT_TENANT_BOOKINGS_SECTION_LIST_BOOKINGS = 'ACCOUNT_TENANT_BOOKINGS_SECTION_LIST_BOOKINGS';

export const fetchTenantBookings = ({ tenantActorId, ...rest }) => ({
    [RSAA]: {
        types: createRsaaTypes(ACCOUNT_TENANT_BOOKINGS_SECTION_LIST_BOOKINGS),
        endpoint: `/api/actors/${tenantActorId}/tenantBookings?${queryString.stringify(rest)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});
