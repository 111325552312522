const subscriptionEndReasons = {
    requestedByTenant: {},
    requestedByOwner: {},
    missingPayment: {},
    refund: {}
};

Object.keys(subscriptionEndReasons).forEach(key => {
    subscriptionEndReasons[key].key = key;
});

export default subscriptionEndReasons;
