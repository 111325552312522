import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ theme, colors }) => ({
    container: {
        margin: 'auto 0 0'
    },
    chip: {
        padding: theme.spacing(1, 1.5),
        borderRadius: '50px',
        display: 'inline-block',
        lineHeight: 1,
        '&.success': {
            backgroundColor: colors.lightGreen,
            color: colors.green
        },
        '&.warning': {
            backgroundColor: colors.lightYellow,
            color: colors.yellow
        },
        '&.danger': {
            backgroundColor: colors.lightRed,
            color: colors.red
        },
        '&.hidden': {
            backgroundColor: colors.lightGrey,
            color: colors.darkGrey
        }
    }
}));

const StatusChip = ({ text, heading, status = 'success' }) => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            {heading && <Typography variant="caption" component="p">{heading}</Typography>}
            {text && <Typography className={clsx(classes.chip, status)} variant="caption" component="span">{text}</Typography>}
        </Box>
    );
};

StatusChip.propTypes = {
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    status: PropTypes.oneOf(['success', 'warning', 'danger', 'hidden'])
};

export default StatusChip;
