import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { performSearch, mergeSearchParameters } from 'components/adminTable/AdminTableHelper';
import routes from 'routes';
import paymentProviders from 'enums/paymentProviders';

import { Link } from 'react-router-dom';
import Loader from 'common/Loader';
import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import AdminTable from 'components/adminTable/AdminTable';
import Amount from 'common/Amount';
import SearchPurchasesForm from './SearchPurchasesForm';
import { searchPurchases, setSearchParameters } from 'actions/admin/purchases';
import FlagIcon from '@material-ui/icons/Flag';
import purchaseStatuses from 'enums/purchaseStatuses';
import ShortDateTime from 'common/ShortDateTime';
import { formatIsoDate } from 'helpers/DateHelper';
import Box from '@material-ui/core/Box';

const Purchases = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const searchResult = useSelector(state => state.admin.purchases.searchResult);
    const searchParametersFromState = useSelector(state => state.admin.purchases.searchParameters);

    const defaultParameters = {
        status: purchaseStatuses.failed.key,
        updatedDateFrom: undefined,
        updatedDateTo: undefined,
        ownerName: undefined,
        storageSiteTitle: undefined,
        page: 0,
        pageSize: 20,
        sortByColumn: 'updatedTime',
        sortDirection: 'desc'
    };

    const columns = [
        {
            id: 'flagged',
            render: (purchase) => purchase.flagged ? <FlagIcon/> : undefined
        },
        {
            title: 'Purchase ID',
            id: 'purchaseId',
            render: (purchase) => <Link to={routes.admin.purchase.replace(':purchaseId', purchase.id)}>{purchase.id}</Link>,
            align: 'right'
        },
        {
            title: 'Owner',
            id: 'ownerName',
            render: (purchase) => <Link to={routes.admin.actor.replace(':actorId', purchase.ownerActorId)}>{purchase.ownerName}</Link>
        },
        {
            title: 'Storage site',
            id: 'storageSiteTitle',
            render: (purchase) => <Link to={routes.storageSiteDetails.replace(':storageSiteId', purchase.storageSiteId)} target="_blank">{purchase.storageSiteTitle}</Link>
        },
        {
            title: 'Tenant',
            id: 'tenantName',
            render: (purchase) => <Link to={routes.admin.actor.replace(':actorId', purchase.tenantActorId)}>{purchase.tenantName}</Link>
        },
        {
            title: 'Amount',
            id: 'amount',
            render: (purchase) => <Amount value={purchase.amount} currency={purchase.currency}/>,
            align: 'right',
            whiteSpace: 'nowrap'
        },
        {
            title: 'Payment provider',
            id: 'paymentProvider',
            render: (purchase) => paymentProviders[purchase.paymentProvider]?.providerName
        },
        {
            title: 'Updated time',
            id: 'updatedTime',
            render: (purchase) => <ShortDateTime value={purchase.updatedTime}/>,
            whiteSpace: 'nowrap'
        },
        {
            title: 'Purchase status',
            id: 'purchaseStatus'
        },
        {
            title: 'Failed payments',
            id: 'failedPaymentCount'
        },
        {
            title: 'Subscription status',
            id: 'subscriptionStatus'
        }
    ];

    const search = (parameters) => {
        const sanitizedParameters = { ...parameters };
        if(parameters.updatedDateFrom) {
            sanitizedParameters.updatedDateFrom = formatIsoDate(parameters.updatedDateFrom);
        }
        if(parameters.updatedDateTo) {
            sanitizedParameters.updatedDateTo = formatIsoDate(parameters.updatedDateTo);
        }
        const searchParameters = mergeSearchParameters(sanitizedParameters, location, searchParametersFromState, defaultParameters);
        performSearch(performSearchObject, searchParameters);
    };

    const getParametersForApiCall = parameters => ({
        ...parameters,
        status: parameters.status === 'all'
            ? undefined
            : parameters.status
    });

    const performSearchObject = {
        searchParametersFromState,
        setSearchParametersToState: parameters => dispatch(setSearchParameters(parameters)),
        searchResult,
        search,
        apiCall: parameters => dispatch(searchPurchases(getParametersForApiCall(parameters))),
        location,
        history,
        isInitializedRef: useRef()
    };

    useEffect(() => {
        const searchParameters = mergeSearchParameters({}, location, searchParametersFromState, defaultParameters);
        performSearch(performSearchObject, searchParameters);
    }, []);

    const handleSort = (sortingParameters) => {
        search(sortingParameters);
    };

    const handlePageChange = (newPage) => {
        search({ page: newPage });
    };

    const handlePageChangeSize = (newPageSize) => {
        search({ page: 0, pageSize: newPageSize });
    };

    const handleSearchFormSubmit = (formValues) => {
        search({ ...defaultParameters, ...formValues, page: 0 });
    };

    return (
        <AdminPage title="Purchases">

            <AdminContentWrapper type="listing">

                <SearchPurchasesForm
                    searchParameters={searchParametersFromState}
                    onSubmit={handleSearchFormSubmit}
                />

                <Box>
                    {
                        searchResult.isLoading && <Loader />
                    }
                    {
                        searchResult.items && (
                            <AdminTable
                                isPaged
                                items={searchResult.items}
                                count={searchResult.count}
                                page={searchResult.page}
                                pageSize={searchResult.pageSize}
                                columns={columns}
                                sortByColumn={columns.find(c => c.id === searchParametersFromState.sortByColumn)}
                                sortDirection={searchParametersFromState.sortDirection}
                                onSort={handleSort}
                                onPageChange={handlePageChange}
                                onPageChangeSize={handlePageChangeSize}
                            />
                        )
                    }
                </Box>

            </AdminContentWrapper>
        </AdminPage>
    );
};

export default Purchases;
