import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from 'styles/util';
import { handleResponse } from 'actions/actionHelpers';
import { updateOwner } from 'actions/actors';
import { refreshAuthenticationContext } from 'actions/authentication';
import strings from 'localization/strings';
import { emailOptional, multipleEmailsOptional } from 'form/validation';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import organizationTypes from 'enums/organizationTypes';
import { getContact, getContacts, parseMultipleContacts, formatMultipleContacts, getOwnerExpectedReplyTimeItems } from 'helpers/ActorHelper';
import { uploadMedia } from 'actions/media';
import { getResizedImageUrl } from 'helpers/ImageHelper';
import queryString from 'query-string';
import contactTypes from 'enums/contactTypes';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MarginBox from 'common/MarginBox';
import LoadingWrapper from 'form/LoadingWrapper';
import Form from 'form/Form';
import { TextField, Checkboxes, Radios, showErrorOnBlur } from 'mui-rff';
import ButtonContainer from 'common/ButtonContainer';
import Dropzone from 'react-dropzone';
import MediaUploadErrorDialog from 'common/MediaUploadErrorDialog';
import TooltipIcon from 'common/TooltipIcon';

const useStyles = makeStyles(({ theme, colors }) => ({
    fieldCaption: {
        marginTop: theme.spacing(2)
    },
    logotypeOuterContainer: {
        margin: theme.spacing(2, 0)
    },
    logotypeInnerContainer: {
        margin: theme.spacing(2, 0),
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center'
    },
    dropzone: {
        border: `2px solid ${colors.primaryColor}`,
        borderRadius: '4px',
        padding: theme.spacing(4),
        cursor: 'pointer',
        marginTop: theme.spacing(2)
    },
    mediaHint: {
        fontSize: '80%'
    },
    removeIconButton: {
        zIndex: 1000,
        position: 'absolute',
        top: '2px',
        right: '2px',
        background: 'rgba(255, 255, 255, 0.5)',
        padding: '2px',
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.75)',
        }
    }
}));

const handledContactTypes = [contactTypes.paymentDocumentEmail.key, contactTypes.checkInEmail.key, contactTypes.checkInMobileTelephone.key, contactTypes.monthlyTransactionReportEmail.key];

const OwnerSettings = () => {
    const classes = useStyles();
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const dispatch = useDispatch();
    const location = useLocation();

    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;
    const isOwnerFromQuerystring = !!queryString.parse(location.search).isOwner;

    const [ isSaving, setIsSaving ] = useState(false);
    const [ uploadErrors, setUploadErrors ] = useState([]);
    const [ uploadErrorDialogOpen, setUploadErrorDialogOpen ] = useState(false);

    const initialValues = {
        isOwner: selectedActor.isOwner || isOwnerFromQuerystring,
        vatIdentificationNumber: selectedActor.vatIdentificationNumber,
        expectedReplyTime: selectedActor.expectedReplyTime ? selectedActor.expectedReplyTime + '' : undefined,
        description: selectedActor.description,
        logotypeUrl: selectedActor.logotypeUrl,
        paymentDocumentEmails: formatMultipleContacts(getContacts(selectedActor, contactTypes.paymentDocumentEmail)),
        checkInEmail: getContact(selectedActor, contactTypes.checkInEmail),
        checkInMobileTelephone: getContact(selectedActor, contactTypes.checkInMobileTelephone),
        sendMonthlyTransactionReports: selectedActor.sendMonthlyTransactionReports,
        monthlyTransactionReportEmails: formatMultipleContacts(getContacts(selectedActor, contactTypes.monthlyTransactionReportEmail))
    };

    const expectedReplyTimeItems = getOwnerExpectedReplyTimeItems()
        .map(o => ({
            label: o.text,
            value: o.value + ''
        }));

    const paymentDocumentEmailHelperText = strings.multipleEmailsCanBeProvided + ' ' +
        (
            selectedActor.organizationType === organizationTypes.business.key
                ? strings.paymentDocumentEmailBusinessHelperText
                : strings.paymentDocumentEmailPrivateHelperText
        );

    const handleLogotypeDrop = (acceptedFiles, fileRejections, form) => {
        setUploadErrors(fileRejections);
        setUploadErrorDialogOpen(fileRejections.length > 0);

        acceptedFiles.forEach(file =>
            dispatch(uploadMedia(file, 'image'))
                .then(handleResponse(
                    response => {
                        const json = response.payload;
                        const mediaUrl = json.location;
                        form.change('logotypeUrl', mediaUrl);
                    }
                ))
        );
    };

    const handleUploadErrorDialogClose = () => {
        setUploadErrorDialogOpen(false);
    };

    const handleDeleteLogotype = (form) => {
        form.change('logotypeUrl', undefined);
    };

    const handleFormSubmit = formValues => {
        setIsSaving(true);
        const updateOwnerRequest = {
            ...formValues,
            paymentDocumentEmails: undefined,
            monthlyTransactionReportEmails: undefined,
            checkInEmail: undefined,
            checkInMobileTelephone: undefined,
            sendMonthlyTransactionReports: formValues.sendMonthlyTransactionReports,
            contacts: [
                ...selectedActor.contacts.filter(o => !handledContactTypes.includes(o.type)),
                ...parseMultipleContacts(contactTypes.paymentDocumentEmail.key, formValues.paymentDocumentEmails),
                { type: contactTypes.checkInEmail.key, value: formValues.checkInEmail },
                { type: contactTypes.checkInMobileTelephone.key, value: formValues.checkInMobileTelephone },
                ...parseMultipleContacts(contactTypes.monthlyTransactionReportEmail.key, formValues.sendMonthlyTransactionReports ? formValues.monthlyTransactionReportEmails : '')
            ]
         };
        dispatch(updateOwner(selectedActor.id, updateOwnerRequest))
            .then(handleResponse(
                () => {
                    dispatch(refreshAuthenticationContext())
                        .then(handleResponse(
                            () => {
                                // context in Redux state is updated by reducer
                                setIsSaving(false);
                                return {
                                    snackbar: {
                                        message: strings.ownerSettingsUpdated
                                    }
                                };
                            },
                            () => setIsSaving(false)
                        ));
                },
                () => setIsSaving(false)
            ));
    };

    return (
        <LoadingWrapper
            isSaving={isSaving}
        >
            <Form
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
            >
                {({ form, values, handleSubmit, submitting, invalid }) => (
                    <form onSubmit={handleSubmit}>
                        {
                            !selectedActor.isOwner &&
                            (
                                <MarginBox bottom={values.isOwner ? 4 : 0}>
                                    <Checkboxes
                                        name="isOwner"
                                        data={{
                                            label: strings.isOwnerLabel,
                                            value: true
                                        }}
                                        required
                                    />
                                </MarginBox>
                            )
                        }
                        {
                            values.isOwner &&
                            (
                                <>
                                    <TextField
                                        name="description"
                                        label={strings.ownerDescription}
                                        helperText={strings.ownerDescriptionHelperText}
                                        variant="outlined"
                                        maxLength={1024}
                                        multiline
                                        rows={3}
                                    />

                                    {
                                        selectedActor.organizationType === organizationTypes.business.key &&
                                        (
                                            <TextField
                                                name="vatIdentificationNumber"
                                                label={strings.vatIdentificationNumber}
                                                helperText={strings.vatIdentificationNumberHelperText}
                                                variant="outlined"
                                            />
                                        )
                                    }

                                    <TextField
                                        name="paymentDocumentEmails"
                                        label={strings.paymentDocumentEmail}
                                        helperText={paymentDocumentEmailHelperText}
                                        variant="outlined"
                                        showError={showErrorOnBlur}
                                        fieldProps={{ validate: multipleEmailsOptional }}
                                    />

                                    {
                                        selectedActor.organizationType === organizationTypes.business.key &&
                                        (
                                            <>
                                                <TextField
                                                    name="checkInEmail"
                                                    label={strings.checkInEmail}
                                                    variant="outlined"
                                                    showError={showErrorOnBlur}
                                                    fieldProps={{ validate: emailOptional }}
                                                    helperText={strings.checkInEmailHelperText}
                                                />

                                                <TextField
                                                    name="checkInMobileTelephone"
                                                    label={strings.checkInMobileTelephone}
                                                    variant="outlined"
                                                    helperText={strings.checkInMobileTelephoneHelperText}
                                                />
                                            </>
                                        )
                                    }

                                    <Box>
                                        <Checkboxes
                                            name="sendMonthlyTransactionReports"
                                            data={{
                                                label: <>{strings.sendMonthlyTransactionReportsCheckbox}<TooltipIcon text={strings.sendMonthlyTransactionReportsTooltip}/></>, value: true
                                            }}
                                        />
                                    </Box>

                                    {
                                        values.sendMonthlyTransactionReports &&
                                        (
                                            <TextField
                                                name="monthlyTransactionReportEmails"
                                                label={strings.monthlyTransactionReportEmail}
                                                helperText={strings.multipleEmailsCanBeProvided + ' ' + strings.leaveEmptyToUseMainEmail}
                                                variant="outlined"
                                                showError={showErrorOnBlur}
                                                fieldProps={{ validate: multipleEmailsOptional }}
                                            />
                                        )
                                    }

                                    <Typography variant="h6" className={classes.fieldCaption} gutterBottom>
                                        {strings.ownerExpectedReplyTimeTitle}
                                    </Typography>

                                    <Typography variant="body1" gutterBottom>
                                        {strings.ownerExpectedReplyTimeBody}
                                    </Typography>

                                    <Radios
                                        name="expectedReplyTime"
                                        data={expectedReplyTimeItems}
                                    />

                                    {
                                        authenticationContext.isSuperAdminOrImpersonated && selectedActor.organizationType === organizationTypes.business.key &&
                                        (
                                            <Box component="div" className={classes.logotypeOuterContainer}>
                                                <Typography variant="h6" gutterBottom>
                                                    {strings.ownerLogotype}
                                                </Typography>
                                                {
                                                    values.logotypeUrl &&
                                                    (
                                                        <Box className={classes.logotypeInnerContainer}>
                                                            <img
                                                                src={getResizedImageUrl(values.logotypeUrl, { height: 64 })}
                                                            />
                                                            <IconButton
                                                                onClick={() => handleDeleteLogotype(form)}
                                                            >
                                                                <DeleteOutlineOutlinedIcon />
                                                            </IconButton>
                                                        </Box>
                                                    )
                                                }
                                                <Dropzone
                                                    accept="image/jpeg, image/png, image/webp"
                                                    maxSize={1000000}
                                                    onDrop={(acceptedFiles, fileRejections) => handleLogotypeDrop(acceptedFiles, fileRejections, form)}
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <Box {...getRootProps()} component="div" className={classes.dropzone}>
                                                            <input {...getInputProps()} />
                                                            <Typography variant="body1" gutterBottom>
                                                                {strings.uploadOwnerLogotypeTitle}
                                                            </Typography>
                                                            <Typography variant="body1" gutterBottom className={classes.mediaHint}>
                                                                {strings.uploadOwnerLogotypeDescription}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </Dropzone>
                                                <MediaUploadErrorDialog uploadErrors={uploadErrors} open={uploadErrorDialogOpen} onClose={handleUploadErrorDialogClose} />
                                            </Box>
                                        )
                                    }
                                </>
                            )
                        }

                        {
                            values.isOwner &&
                            (
                                <ButtonContainer>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        disabled={submitting || invalid || isSaving}
                                        fullWidth={isMobile}
                                    >
                                        {strings.save}
                                    </Button>
                                </ButtonContainer>
                            )
                        }
                    </form>
                )}
            </Form>
        </LoadingWrapper>
    );
};

export default OwnerSettings;
