import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { getPurchase } from 'actions/admin/purchases';
import { handleResponse } from 'actions/actionHelpers';
import routes from 'routes';
import { refundPayment, connectUnmatchedPaymentToPurchase } from 'actions/admin/payments';
import { regeneratePurchasePaymentDocuments } from 'actions/admin/purchases';
import { updatePurchase } from 'actions/admin/purchases';
import { createPurchaseNote } from 'actions/admin/purchases';
import paymentProviders from 'enums/paymentProviders';
import purchaseStatuses from 'enums/purchaseStatuses';
import subscriptionStatuses from 'enums/subscriptionStatuses';

import Loader from 'common/Loader';
import Button from '@material-ui/core/Button';
import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import AdminFormWrapper from '../../presentational/AdminFormWrapper';
import AdminFormFields from '../../presentational/AdminFormFields';
import AdminActionWrapper from '../../presentational/AdminActionWrapper';
import ReadOnlyField from '../../presentational/ReadOnlyField';
import { Link } from 'react-router-dom';
import PurchaseItemList from './PurchaseItemList';
import PayoutList from './PayoutList';
import TimeAgo from 'common/TimeAgo';
import RefundPaymentDialog from './RefundPaymentDialog';
import ConnectToUnmatchedPaymentDialog from './ConnectToUnmatchedPaymentDialog';
import Amount from 'common/Amount';
import ShortDate from 'common/ShortDate';
import EditNoteDialog from 'components/EditNoteDialog';
import NoteList from 'admin/containers/booking/NoteList';
import { Checkboxes } from 'mui-rff';
import Form from 'form/Form';
import AdminFormButtons from 'admin/presentational/AdminFormButtons';
import PaymentList from './PaymentList';

const formatAmountOptions = { displayPriceRounding: false };

const Purchase = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();

    const [purchase, setPurchase] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPayment, setSelectedPayment] = useState(undefined);
    const [refundPaymentDialogOpen, setRefundPaymentDialogOpen] = useState(false);
    const [connectToUnmatchedPaymentDialogOpen, setConnectToUnmatchedPaymentDialogOpen] = useState(false);
    const [purchaseNoteDialogOpen, setPurchaseNoteDialogOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const refundInfo = purchase?.booking.refundInfos.find(ri => ri.canBeRefunded && purchase.payments.filter(payment => payment.id === ri.paymentId).length > 0);
    const canRegenerateReceipts = [purchaseStatuses.paid.key, purchaseStatuses.refunded.key].includes(purchase?.purchaseStatus);
    const canConnectToUnmatchedPayment = purchase?.booking.subscriptionBooking && purchase?.subscriptionStatus === subscriptionStatuses.ended.key;

    useEffect(() => {
        loadPurchase();
    }, []);

    const loadPurchase = successResponse => {
        setIsLoading(true);
        dispatch(getPurchase(params.purchaseId))
            .then(handleResponse(
                response => {
                    setPurchase(response.payload);
                    setIsLoading(false);
                    return successResponse;
                },
                () => setIsLoading(false)
            ));
    };

    const handleRefundButtonClick = () => {
        purchase.payments.forEach(payment => {
            if(payment.id === refundInfo.paymentId) {
                setSelectedPayment(payment);
            }
        });
        setRefundPaymentDialogOpen(true);
    };

    const handleConnectToUnmatchedPaymentButtonClick = () => {
        setConnectToUnmatchedPaymentDialogOpen(true);
    };

    const handleRegenerateReceiptsButtonClick = () => {
        setIsLoading(true);
        dispatch(regeneratePurchasePaymentDocuments(purchase.id))
            .then(handleResponse(
                () => loadPurchase({ snackbar: { message: 'Receipts were successfully regenerated.' } }),
                () => setIsLoading(false)
            ));
    };

    const handleRefundPaymentDialogSubmit = values => {
        setIsLoading(true);
        setRefundPaymentDialogOpen(false);
        const request = {
            ownerAmountToRefund: values.ownerAmountToRefund,
            commissionFeeToRefund: values.commissionFeeToRefund,
            cancelBooking: values.cancelBooking,
            endSubscription: values.endSubscription
        };
        dispatch(refundPayment(selectedPayment.id, request))
            .then(handleResponse(
                () => {
                    history.push(routes.admin.booking.replace(':bookingId', purchase.booking.id));
                    return { snackbar: { message: 'Payment was successfully refunded.' } };
                },
                () => setIsLoading(false)
            ));
    };

    const handleRefundPaymentDialogCancel = () => {
        setRefundPaymentDialogOpen(false);
    };

    const handleConnectToUnmatchedPaymentDialogSubmit = values => {
        setIsLoading(true);
        setConnectToUnmatchedPaymentDialogOpen(false);
        dispatch(connectUnmatchedPaymentToPurchase(values.paymentId, purchase.id))
            .then(handleResponse(
                () => loadPurchase({ snackbar: { message: 'Payment was successfully connected.' } }),
                () => setIsLoading(false)
            ));
    };

    const handleConnectToUnmatchedPaymentDialogCancel = () => {
        setConnectToUnmatchedPaymentDialogOpen(false);
    };

    const title = 'Purchase: ' + (purchase?.id ?? 'Loading...');

    const formatPurchasePeriod = () => purchase.periodStartDate && purchase.periodEndDate
        ? <><ShortDate value={purchase.periodStartDate}/> &ndash; <ShortDate value={purchase.periodEndDate}/></>
        : '-';

    const handleAddPurchaseNoteClick = () => {
        setPurchaseNoteDialogOpen(true);
    };

    const handlePurchaseNoteDialogCancel = () => {
        setPurchaseNoteDialogOpen(false);
    };

    const handlePurchaseNoteDialogSubmit = (formValues, form) => {
        const request = {
            text: formValues.text
        };
        dispatch(createPurchaseNote(params.purchaseId, request))
            .then(handleResponse(
                response => {
                    const newPurchase = { ...purchase };
                    newPurchase.notes.push(response.payload);
                    setPurchase(newPurchase);
                    form.reset();
                    setPurchaseNoteDialogOpen(false);
                    return { snackbar: { message: 'Note was successfully saved.' } };
                }
            ));
    };

    const createUpdatePurchaseRequest = formValues => ({
        flagged: formValues.flagged
    });

    const handleFormSubmit = formValues => {
        setIsSubmitting(true);
        const updatePurchaseRequest = createUpdatePurchaseRequest(formValues);
        dispatch(updatePurchase(params.purchaseId, updatePurchaseRequest))
            .then(handleResponse(() => {
                setIsSubmitting(false);
                history.push(routes.admin.booking.replace(':bookingId', purchase.booking.id));
                return { snackbar: { message: 'Purchase was successfully saved.' } };
            },
                () => setIsSubmitting(false)
            ));
    };

    const handleCancel = () => {
      history.push(routes.admin.booking.replace(':bookingId', purchase.booking.id));
    };

    const initialValues = {
        flagged: purchase ? purchase.flagged : false
    };

    return (
        <AdminPage title={title}>
            {
                isLoading && <Loader />
            }
            {
                !isLoading && purchase && (
                        <AdminContentWrapper type="detail">

                        <AdminFormWrapper isSubmitting={isSubmitting}>
                            <Form
                                initialValues={initialValues}
                                onSubmit={handleFormSubmit}
                            >
                                {({ handleSubmit }) => {
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <AdminFormFields>

                                                <ReadOnlyField label="Purchase ID">
                                                    {purchase.id}
                                                </ReadOnlyField>

                                                <ReadOnlyField label="Booking ID">
                                                    <Link to={routes.admin.booking.replace(':bookingId', purchase.booking.id)}>{purchase.booking.id}</Link>
                                                </ReadOnlyField>

                                                <ReadOnlyField label="Created time">
                                                    <TimeAgo date={purchase.createdTime}/>
                                                </ReadOnlyField>

                                                <ReadOnlyField label="Updated time">
                                                    <TimeAgo date={purchase.updatedTime}/>
                                                </ReadOnlyField>

                                                {
                                                    purchase.dueTime &&
                                                    (
                                                        <ReadOnlyField label="Due date">
                                                            <ShortDate value={purchase.dueTime}/>
                                                        </ReadOnlyField>
                                                    )
                                                }

                                                <ReadOnlyField label="Status">
                                                    {purchase.purchaseStatus}
                                                </ReadOnlyField>

                                                <ReadOnlyField label="Amount">
                                                    <Amount value={purchase.amount} currency={purchase.currency} {...formatAmountOptions}/>
                                                </ReadOnlyField>

                                                <ReadOnlyField label="Owner amount">
                                                    <Amount value={purchase.ownerAmount} currency={purchase.currency} {...formatAmountOptions}/>
                                                </ReadOnlyField>

                                                <ReadOnlyField label="Commission fee">
                                                    <Amount value={purchase.commissionFee} currency={purchase.currency} {...formatAmountOptions}/>
                                                </ReadOnlyField>

                                                <ReadOnlyField label="Payment provider">
                                                    {paymentProviders[purchase.paymentProvider]?.providerName}
                                                </ReadOnlyField>

                                                {
                                                    purchase.paymentProviderFee > 0 &&
                                                    (
                                                        <ReadOnlyField label="Payment provider fee">
                                                            <Amount value={purchase.paymentProviderFee} currency={purchase.currency} {...formatAmountOptions}/>
                                                        </ReadOnlyField>
                                                    )
                                                }

                                                {
                                                    purchase.paymentProviderPortalUrl &&
                                                    (
                                                        <ReadOnlyField label="Purchase identity in payment provider portal">
                                                            <a href={purchase.paymentProviderPortalUrl} target="_blank">{purchase.paymentProviderInvoiceId}</a>
                                                        </ReadOnlyField>
                                                    )
                                                }

                                                <ReadOnlyField label="Owner">
                                                    <Link to={routes.admin.actor.replace(':actorId', purchase.booking.ownerActorId)}>{purchase.booking.ownerName}</Link>
                                                </ReadOnlyField>

                                                <ReadOnlyField label="Storage site">
                                                    <Link to={routes.storageSiteDetails.replace(':storageSiteId', purchase.storageSiteId)} target="_blank">{purchase.storageSiteTitle}</Link>
                                                </ReadOnlyField>

                                                <ReadOnlyField label="Tenant">
                                                    <Link to={routes.admin.actor.replace(':actorId', purchase.booking.tenantActorId)}>{purchase.booking.tenantName}</Link>
                                                </ReadOnlyField>

                                                <ReadOnlyField label="Owner receipt">
                                                    {
                                                        purchase.ownerPaymentDocumentUrl && <Link to={purchase.ownerPaymentDocumentUrl} target="_blank">{purchase.ownerPaymentDocumentUrl}</Link>
                                                    }
                                                </ReadOnlyField>

                                                <ReadOnlyField label="Tenant receipt">
                                                    {
                                                        purchase.tenantPaymentDocumentUrl && <Link to={purchase.tenantPaymentDocumentUrl} target="_blank">{purchase.tenantPaymentDocumentUrl}</Link>
                                                    }
                                                </ReadOnlyField>

                                                <ReadOnlyField label="Purchase period">
                                                    {formatPurchasePeriod()}
                                                </ReadOnlyField>

                                                <PurchaseItemList purchaseItems={purchase.purchaseItems}/>

                                                <PaymentList payments={purchase.payments} />

                                                <ReadOnlyField label="Storage365 payout status">
                                                    {purchase.storage365PayoutStatus}
                                                </ReadOnlyField>

                                                <ReadOnlyField label="Owner payout status">
                                                    {purchase.ownerPayoutStatus}
                                                </ReadOnlyField>

                                                <PayoutList payouts={purchase.payouts}/>

                                                <NoteList notes={purchase.notes}/>

                                                <Checkboxes
                                                    name="flagged"
                                                    data={{
                                                        label: 'Flagged',
                                                        value: true
                                                    }}
                                                />

                                            </AdminFormFields>
                                            <AdminFormButtons>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={handleCancel}
                                                >
                                                    Cancel
                                                </Button>
                                            </AdminFormButtons>
                                        </form>
                                    );
                                }}
                            </Form>
                        </AdminFormWrapper>

                        <AdminActionWrapper>
                            {
                                canConnectToUnmatchedPayment &&
                                (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleConnectToUnmatchedPaymentButtonClick}
                                    >
                                        Connect to unmatched payment
                                    </Button>
                                )
                            }
                            {
                                refundInfo &&
                                (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleRefundButtonClick}
                                    >
                                        Refund
                                    </Button>
                                )
                            }

                            {
                                canRegenerateReceipts &&
                                (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleRegenerateReceiptsButtonClick}
                                    >
                                        Regenerate receipts
                                    </Button>
                                )
                            }

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddPurchaseNoteClick}
                            >
                                Add note
                            </Button>

                            {
                                selectedPayment && refundInfo &&
                                (
                                    <RefundPaymentDialog
                                        booking={purchase.booking}
                                        payment={selectedPayment}
                                        refundInfo={refundInfo}
                                        open={refundPaymentDialogOpen}
                                        onSubmit={handleRefundPaymentDialogSubmit}
                                        onCancel={handleRefundPaymentDialogCancel}
                                    />
                                )
                            }

                            <ConnectToUnmatchedPaymentDialog
                                open={connectToUnmatchedPaymentDialogOpen}
                                onSubmit={handleConnectToUnmatchedPaymentDialogSubmit}
                                onCancel={handleConnectToUnmatchedPaymentDialogCancel}
                            />

                            <EditNoteDialog
                                open={purchaseNoteDialogOpen}
                                onSubmit={handlePurchaseNoteDialogSubmit}
                                onCancel={handlePurchaseNoteDialogCancel}
                            />

                        </AdminActionWrapper>

                    </AdminContentWrapper>
                )
            }
        </AdminPage>
    );
};

export default Purchase;
