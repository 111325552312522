import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from 'styles/util';
import { listConversations } from 'actions/account/conversationsSection';
import { handleResponse } from 'actions/actionHelpers';
import strings from 'localization/strings';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import queryString from 'query-string';
import { convertToNumber } from 'helpers/StringHelper';
import routes from 'routes';

import PageTitle from 'common/PageTitle';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Loader from 'common/Loader';
import ConversationListItem from './ConversationListItem';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles(({ theme, colors }) => ({
    conversationListItem: {
        padding: theme.spacing(2.5, 0, 0),
        borderBottom: `1px solid ${colors.lightGreyBorder ?? colors.mediumGrey}`,
    },
    paginationWrapper: {
        textAlign: 'center',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    bottomPaginationWrapper: {
        marginTop: theme.spacing(2),
        marginBottom: 0
    },
    pagination: {
        display: 'inline-block',
    }
}));

const ConversationsSection = () => {
    const classes = useStyles();
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const filterParams = queryString.parse(location.search);
    filterParams.page = convertToNumber(filterParams.page) ?? 1; // page numbers are one-based
    filterParams.pageSize = convertToNumber(filterParams.pageSize) ?? 20;
    const filterQueryString = queryString.stringify(filterParams);

    const [conversationsResult, setConversationsResult] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;

    useEffect(() => {
        setIsLoading(true);
        history.push(`${routes.account.conversations}?${filterQueryString}`);
        dispatch(listConversations(selectedActor.id, filterParams.page - 1, filterParams.pageSize))
            .then(handleResponse(
                response => {
                    setConversationsResult(response.payload);
                    setIsLoading(false);
                }));
    }, [filterQueryString]);

    const title = strings.accountTabs.conversations;

    const getPaginationElement = (isBottom) => {
        const numberOfPages = Math.floor((conversationsResult.count - 1) / filterParams.pageSize) + 1;

        if(numberOfPages < 2) {
            return undefined;
        }

        return (
            <Box className={classes.paginationWrapper + ' ' + (isBottom ? classes.bottomPaginationWrapper : '')}>
                <Pagination
                    className={classes.pagination}
                    color="primary"
                    page={filterParams.page}
                    count={numberOfPages}
                    onChange={handlePageChange}
                    siblingCount={isMobile ? 0 : 2}
                    boundaryCount={isMobile ? 1 : 2}
                />
            </Box>
        );
    };

    const handlePageChange = (event, newPage) => {
        const newFilterQueryString = queryString.stringify({ page: newPage, pageSize: filterParams.pageSize });
        history.push(`${routes.account.conversations}?${newFilterQueryString}`);
    };

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <Typography variant="h2">{title}</Typography>
            {
                isLoading && <Loader />
            }
            {
                !isLoading && conversationsResult.items &&
                (
                    <Box>
                        {
                            conversationsResult.items.length === 0 &&
                            (
                                <Typography variant="body1">{strings.youHaveNoMessages}</Typography>
                            )
                        }
                        {getPaginationElement(false)}
                        {
                            <Box>
                                {
                                    conversationsResult.items.map(conversation =>
                                        (
                                            <Box key={conversation.id} className={classes.conversationListItem}>
                                                <ConversationListItem conversation={conversation} actor={selectedActor} />
                                            </Box>
                                        )
                                    )
                                }
                            </Box>
                        }
                        {getPaginationElement(true)}
                    </Box>
                )
            }
        </>
    );
};

export default ConversationsSection;
