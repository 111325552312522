import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import PriceSpecificationHeader from 'common/PriceSpecification/PriceSpecificationHeader';
import PriceSpecificationFooter from 'common/PriceSpecification/PriceSpecificationFooter';
import PriceSpecificationContent from 'common/PriceSpecification/PriceSpecificationContent';

const PriceSpecificationTableNew = ({ booking, purchaseItems, showVat }) => {
    const useStyles = makeStyles(({ theme, colors, styles }) => ({
        table: {
            border: `1px solid ${colors.lightGreyBorder ?? colors.mediumGrey}`,
            borderRadius: theme.spacing(0.5)
        },
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            gap: theme.spacing(2),
            '& > :last-child': {
                flex: `0 0 calc(30% - ${theme.spacing(1)}px)`,
                textAlign: 'right'
            },
            '& > :first-child': {
                flex: `0 0 calc(70% - ${theme.spacing(1)}px)`,
                textAlign: 'left'
            }
        },
        expandIcon: {
            transition: `transform ${styles.transitionEffect}`
        },
        expandVat: {
            transform: 'rotate(45deg)'
        }
    }));
    const classes = useStyles();

    const currency = booking.currency;

    const formatAmountOptions = { displayPriceRounding: false };

    const [expandVat, setExpandVat] = useState(false);

    const handleExpandVat = () => {
        setExpandVat(!expandVat);
    };

    const purchaseItemsProps = {
        purchaseItems,
        currency,
        formatAmountOptions,
        showVat,
        expandVat,
        rowClassName: classes.row,
    };

    return (
        <>
            <Box className={classes.table}>
                <PriceSpecificationHeader rowClassName={classes.row} />
                <PriceSpecificationContent {...purchaseItemsProps} />
                <PriceSpecificationFooter {...purchaseItemsProps} />
            </Box>
            {showVat && <Button size="small" onClick={handleExpandVat}>{!expandVat ? strings.priceSpecificationTable.showVat : strings.priceSpecificationTable.hideVat} <AddIcon className={clsx(classes.expandIcon, {[classes.expandVat]: expandVat})}/></Button>}
        </>
    );
};

PriceSpecificationTableNew.propTypes = {
    booking: PropTypes.object.isRequired,
    purchaseItems: PropTypes.array.isRequired,
    showVat: PropTypes.bool
};

export default PriceSpecificationTableNew;
