import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import paymentRecurrences from 'enums/paymentRecurrences';
import { getPriceInfo } from 'helpers/PriceCalculator';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import WithDividerWrapper from 'common/WithDividerWrapper';
import Amount from 'common/Amount';

const useStyles = makeStyles(({ theme }) => ({
    additionalServices: {
        flex: '1',
        '& h5': {
            marginBottom: theme.spacing(2),
            fontSize: theme.spacing(2.25),
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '140%',
            letterSpacing: '-0.01em',
        },
        '& p': {
            margin: theme.spacing(0, 0.5),
            fontSize: theme.spacing(2),
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: '150%',
            letterSpacing: '-0.01em',
        },
        [theme.breakpoints.down('sm')]: {
            borderTop: 'none',
            margin: 0,
            padding: theme.spacing(0, 2, 2, 2)
        }
    },
}));

const AdditionalServices = ({ additionalServices }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    const formatAdditionalServicePrice = additionalService => {
        const additionalServicePriceInfo = getPriceInfo(additionalService.price, appContext, additionalService);
        return (
            <Amount
                value={additionalServicePriceInfo.displayPrice}
                currency={additionalServicePriceInfo.currency}
                formatString={paymentRecurrences[additionalService.paymentRecurrence].priceSuffixTemplate}
            />
        );
    };

    return (
        <Box className={classes.additionalServices}>
            <Typography variant="h5" display="block" gutterBottom>{strings.additionalServices}</Typography>
            <Box>
            {
                additionalServices.map(additionalService => (
                    <Typography variant="body1" display="block" key={additionalService.id}>{additionalService.description}: {formatAdditionalServicePrice(additionalService)}</Typography>
                ))
            }
            </Box>
        </Box>
    );
};

AdditionalServices.propTypes = {
    additionalServices: PropTypes.array.isRequired
};

export default WithDividerWrapper(AdditionalServices);
