import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { useForm } from 'react-final-form';
import { makeStyles } from 'styles/util';
import { mustBeNumber, required } from 'form/validation';
import bookingStatuses from 'enums/bookingStatuses';
import additionalServiceStatuses from 'enums/additionalServiceStatuses';
import paymentRecurrences from 'enums/paymentRecurrences';
import { formatNumber, formatAmount } from 'helpers/StringHelper';
import { createEndAdornment } from 'helpers/FormHelper';

import { FieldArray } from 'react-final-form-arrays';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { TextField, showErrorOnBlur } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import { OnChange } from 'react-final-form-listeners';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import bookingItemTypes from '../../../enums/bookingItemTypes';

const useStyles = makeStyles(({ theme }) => ({
    container: {
        display: 'flex',
        '& > *': {
            paddingRight: theme.spacing(2),
            '&:last-child': {
                paddingRight: 0
            }
        }
    },
    textContainer: {
        flex: '1 1 40%',
    },
    amountContainer: {
        flex: '1 1 20%',
    },
    vatRateContainer: {
        flex: '1 1 20%',
    },
    commissionRateContainer: {
        flex: '1 1 20%',
    },
    removeButtonContainer: {
        flex: '0 0 auto',
        alignSelf: 'center',
        textAlign: 'end'
    },
    addButtonContainer: {
        '& > *': {
            marginRight: theme.spacing(1)
        }
    },
    invisible: {
        visibility: 'hidden'
    }
}));

const formatAmountOptions = { skipCurrencySuffix: true, skipThousandSeparators: true };
const formatNumberOptions = { skipThousandSeparators: true, sanitizeMinusSign: true };

const BookingItemsEditor = ({ booking, storageSite }) => {
    const classes = useStyles();
    const form = useForm();

    const { appContext } = useAppContext();

    const beforePurchase =
        booking.bookingStatus === bookingStatuses.requested.key ||
        booking.bookingStatus === bookingStatuses.confirmed.key;

    const allAdditionalServiceListItems = storageSite.additionalServices
        .map(additionalService => ({
            value: additionalService.id,
            title: additionalService.description
        }));

    const availableAdditionalServices = storageSite.additionalServices
        .filter(additionalService => beforePurchase || additionalService.paymentRecurrence === paymentRecurrences.perMonth.key);

    const availableAdditionalServiceListItems = availableAdditionalServices
        .map(additionalService => ({
            value: additionalService.id,
            title: additionalService.description
        }));

    const storageCommissionRate = booking.bookingItems.find(o => o.type === bookingItemTypes.storage.key).commissionRate;

    const handleAdditionalServiceChange = (newValue, index) => {
        const newAdditionalService = storageSite.additionalServices.find(additionalService => additionalService.id === newValue);
        form.mutators.changeValue({ name: `bookingItems[${index}].referenceId`, value: newValue });
        if(newAdditionalService) {
            form.mutators.changeValue({ name: `bookingItems[${index}].amount`, value: formatAmount(newAdditionalService.price, booking.currency, appContext, formatAmountOptions) });
            form.mutators.changeValue({ name: `bookingItems[${index}].vatRate`, value: formatNumber(100 * newAdditionalService.vatRate, appContext, formatNumberOptions) });
            form.mutators.changeValue({ name: `bookingItems[${index}].commissionRate`, value: formatNumber(100 * newAdditionalService.commissionRate, appContext, formatNumberOptions) });
            form.mutators.changeValue({ name: `bookingItems[${index}].paymentRecurrence`, value: newAdditionalService.paymentRecurrence });
        }
    };

    const handleRemove = (fields, index) => {
        fields.remove(index);
    };

    const handleAdd = (fields, type, paymentRecurrence, isPredefined) => {
        const predefinedService = isPredefined
            ? availableAdditionalServices[0]
            : undefined;
        fields.push({
            id: undefined,
            description: undefined,
            referenceId: predefinedService?.id,
            amount: predefinedService
                ? formatAmount(predefinedService.price, booking.currency, appContext, formatAmountOptions)
                : undefined,
            vatRate: predefinedService
                ? formatNumber(100 * predefinedService.vatRate, appContext, formatNumberOptions)
                : undefined,
            commissionRate: formatNumber(100 * (predefinedService?.commissionRate ?? storageCommissionRate), appContext, formatNumberOptions),
            paymentRecurrence: predefinedService?.paymentRecurrence ?? paymentRecurrence,
            type: predefinedService?.type ?? type,
            isPredefined
        });
    };

    return (
        <FieldArray name="bookingItems">
            {({ fields }) => (
                <>
                    {
                        fields.map((name, index) => {
                            const item = fields.value[index];
                            const disabled =
                                !(
                                    booking.bookingStatus === bookingStatuses.requested.key ||
                                    booking.bookingStatus === bookingStatuses.confirmed.key ||
                                    item.paymentRecurrence === paymentRecurrences.perMonth.key ||
                                    item.paymentRecurrence === undefined
                                );
                            const priceLabel = item.paymentRecurrence === paymentRecurrences.perMonth.key
                                ? 'Monthly price'
                                : 'One-time price';
                            const additionalServiceListItems = disabled
                                ? allAdditionalServiceListItems
                                : availableAdditionalServiceListItems;

                            return (
                                <Box className={classes.container} key={index}>
                                    <Box className={classes.textContainer}>
                                        {
                                            item.isPredefined &&
                                            (
                                                <>
                                                    <TextField
                                                        select
                                                        name={`${name}.referenceId`}
                                                        label="Additional service"
                                                        variant="outlined"
                                                        disabled={disabled}
                                                    >
                                                        {
                                                            additionalServiceListItems
                                                                .map(additionalService => (
                                                                    <MenuItem key={additionalService.value} value={additionalService.value}>
                                                                        {additionalService.title}
                                                                        {
                                                                            additionalService.status === additionalServiceStatuses.deleted.key && ' - deleted'
                                                                        }
                                                                    </MenuItem>
                                                                ))
                                                        }
                                                    </TextField>
                                                    <OnChange name={`${name}.referenceId`}>
                                                        {newValue => handleAdditionalServiceChange(newValue, index)}
                                                    </OnChange>
                                                </>
                                            )
                                        }
                                        {
                                            !item.isPredefined &&
                                            (
                                                <TextField
                                                    name={`${name}.description`}
                                                    label="Additional service"
                                                    variant="outlined"
                                                    disabled={disabled}
                                                    required
                                                    fieldProps={{ validate: required }}
                                                    placeholder="Enter service description"
                                                />
                                            )
                                        }
                                    </Box>

                                    <Box className={classes.amountContainer}>
                                        <TextField
                                            name={`${name}.amount`}
                                            label={priceLabel}
                                            helperText="Price excluding VAT."
                                            variant="outlined"
                                            showError={showErrorOnBlur}
                                            disabled={disabled}
                                            required
                                            fieldProps={{ validate: mustBeNumber }}
                                            InputProps={createEndAdornment(booking.currency)}
                                        />
                                    </Box>

                                    <Box className={classes.vatRateContainer}>
                                        <TextField
                                            name={`${name}.vatRate`}
                                            label="VAT rate"
                                            variant="outlined"
                                            showError={showErrorOnBlur}
                                            disabled={disabled}
                                            required
                                            fieldProps={{ validate: mustBeNumber }}
                                            InputProps={createEndAdornment('%')}
                                        />
                                    </Box>

                                    <Box className={classes.commissionRateContainer}>
                                        <TextField
                                            name={`${name}.commissionRate`}
                                            label="Commission rate"
                                            variant="outlined"
                                            showError={showErrorOnBlur}
                                            disabled={disabled}
                                            required
                                            fieldProps={{ validate: mustBeNumber }}
                                            InputProps={createEndAdornment('%')}
                                        />
                                    </Box>

                                    <Box className={classes.removeButtonContainer}>
                                        <IconButton
                                            className={disabled ? classes.invisible : undefined}
                                            onClick={() => handleRemove(fields, index)}
                                            >
                                            <DeleteOutlineOutlinedIcon/>
                                        </IconButton>
                                    </Box>
                                </Box>
                            );
                        })
                    }
                    <Box className={classes.addButtonContainer}>
                        {
                            availableAdditionalServiceListItems.length > 0 &&
                            (
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => handleAdd(fields, bookingItemTypes.service.key, undefined, true)}
                                >
                                    Add predefined service
                                </Button>
                            )
                        }
                        <Button
                            size="small"
                            color="primary"
                            variant="outlined"
                            onClick={() => handleAdd(fields, bookingItemTypes.service.key, paymentRecurrences.perMonth.key, false)}
                        >
                            Add free-text service paid by month
                        </Button>
                        {
                            beforePurchase &&
                            (
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => handleAdd(fields, bookingItemTypes.service.key, paymentRecurrences.oneTime.key, false)}
                                >
                                    Add free-text service paid once
                                </Button>
                            )
                        }
                    </Box>
                </>
            )}
        </FieldArray>
    );
};

BookingItemsEditor.propTypes = {
    booking: PropTypes.object.isRequired,
    storageSite: PropTypes.object.isRequired
};

export default BookingItemsEditor;
