import adminBookingStatuses from 'enums/adminBookingStatuses';
import strings from 'localization/strings';
import { formatTimePeriod, convertToNumber } from 'helpers/StringHelper';
import queryString from 'query-string';

export const getTabs = (excludeCanceledBookings = false) => {
    const tabs = [
        { key: 'all' },
        { adminBookingStatus: adminBookingStatuses.requested },
        { adminBookingStatus: adminBookingStatuses.confirmed },
        excludeCanceledBookings ? undefined : { adminBookingStatus: adminBookingStatuses.canceled },
        { adminBookingStatus: adminBookingStatuses.notStarted },
        { adminBookingStatus: adminBookingStatuses.active },
        { adminBookingStatus: adminBookingStatuses.subscriptionEnding },
        { adminBookingStatus: adminBookingStatuses.ended }
    ].filter(o => o);

    tabs.forEach(tab => {
        tab.key = tab.key ?? tab.adminBookingStatus.key;
        tab.filter = booking => tab.key === 'all'
            ? true
            : booking.adminBookingStatus === tab.adminBookingStatus.key;
        tab.label = tab.adminBookingStatus
            ? tab.adminBookingStatus.title
            : strings.all;
    });

    return tabs;
};

export const getSelectedTab = (tabs, filterParams) => tabs.find(tab => tab.key === filterParams.adminBookingStatus) ?? tabs[0];

export const isStale = fetchedTime => {
    // we're caching bookings for two minutes
    const now = new Date().getTime();
    if(fetchedTime === undefined) {
        return false;
    }
    return now - fetchedTime > 120000;
};

export const getBookingChipText = (booking) => {
    const prefix = strings.bookingLabelPrefixes[booking.adminBookingStatus];
    const type = booking.subscriptionBooking
        ? strings.subscriptionBookingLowercase
        : strings.periodBookingLowercase;
    return `${prefix} ${type}`;
};

export const getBookingPeriod = (booking) => {
    return formatTimePeriod(booking);
};

export const parseFilterParams = location => {
    const filterParams = queryString.parse(location.search);
    filterParams.storageSiteId = convertToNumber(filterParams.storageSiteId);
    filterParams.q = filterParams.q ? filterParams.q : undefined;
    filterParams.page = convertToNumber(filterParams.page) ?? 1; // page numbers are one-based
    filterParams.pageSize = convertToNumber(filterParams.pageSize) ?? defaultPageSize;
    return filterParams;
};

const defaultPageSize = 20;
