import * as reports from 'actions/reports';
import { sub, startOfMonth, endOfMonth } from 'date-fns';
import { formatIsoDate, formatUtcIsoDateTime } from 'helpers/DateHelper';
import strings from 'localization/strings';

const getFromMonthInputField = monthsBack => ({
    name: 'from',
    label: 'From month',
    type: 'month',
    defaultValueGetter: () => startOfMonth(sub(new Date(), { months: monthsBack }))
});

const getToMonthInputField = monthsBack => ({
    name: 'to',
    label: 'To month',
    type: 'month',
    defaultValueGetter: () => endOfMonth(sub(new Date(), { months: monthsBack }))
});

const timePeriodGroupingInputField = {
    name: 'timePeriodGrouping',
    label: 'Group by',
    type: 'dropdown',
    data: [{ label: 'day', value: 'day' }, { label: 'month', value: 'month' }, { label: 'year', value: 'year' }],
    defaultValueGetter: () => 'month'
};

const adminReportDefinitions = {};

strings.addLoadCallback(appContext => {
    const categoryDropdownInputField = {
        name: 'category',
        label: 'Category',
        type: 'dropdown',
        data: [{ title: 'all', key: 'all' }].concat(appContext.storageGroupCategories).map(o => ({ label: o.title, value: o.key })),
        defaultValueGetter: () => 'all'
    };

    adminReportDefinitions.earnings = {
        title: 'Earnings',
        inputFields: [
            getFromMonthInputField(12),
            getToMonthInputField(1),
            categoryDropdownInputField,
            timePeriodGroupingInputField
        ],
        fetchData: values => {
            const inputFields = values.inputFields[adminReportDefinitions.earnings.key];
            const parameters = {
                from: formatIsoDate(startOfMonth(inputFields.from)),
                to: formatIsoDate(endOfMonth(inputFields.to)),
                category: inputFields.category === 'all'
                    ? undefined
                    : inputFields.category,
                timePeriodGrouping: inputFields.timePeriodGrouping
            };
            return values.format === 'excel'
                ? reports.getEarningsReportExcelFile(parameters)
                : reports.getEarningsReport(parameters);
        }
    };

    adminReportDefinitions.newActors = {
        title: 'New actors',
        inputFields: [
            { name: 'since', label: 'Since', type: 'date', defaultValueGetter: () => sub(new Date(), { months: 1 }) }
        ],
        fetchData: values => {
            const inputFields = values.inputFields[adminReportDefinitions.newActors.key];
            const parameters = { since: formatIsoDate(inputFields.since) };
            return values.format === 'excel'
                ? reports.getNewActorsReportExcelFile(parameters)
                : reports.getNewActorsReport(parameters);
        }
    };

    adminReportDefinitions.kpis = {
        title: 'KPIs',
        inputFields: [
            getFromMonthInputField(12),
            getToMonthInputField(1),
            categoryDropdownInputField,
            timePeriodGroupingInputField
        ],
        fetchData: values => {
            const inputFields = values.inputFields[adminReportDefinitions.kpis.key];
            const parameters = {
                from: formatIsoDate(startOfMonth(inputFields.from)),
                to: formatIsoDate(endOfMonth(inputFields.to)),
                category: inputFields.category === 'all'
                    ? undefined
                    : inputFields.category,
                    timePeriodGrouping: inputFields.timePeriodGrouping
            };
            return values.format === 'excel'
                ? reports.getKpisReportExcelFile(parameters)
                : reports.getKpisReport(parameters);
        }
    };

    adminReportDefinitions.storage365Payout = {
        title: 'Storage365 payout',
        inputFields: [
            getFromMonthInputField(1),
            getToMonthInputField(1)
        ],
        fetchData: values => {
            const inputFields = values.inputFields[adminReportDefinitions.storage365Payout.key];
            const parameters = {
                from: formatUtcIsoDateTime(startOfMonth(inputFields.from)),
                to: formatUtcIsoDateTime(endOfMonth(inputFields.to))
            };
            return values.format === 'excel'
                ? reports.getStorage365PayoutReportExcelFile(parameters)
                : reports.getStorage365PayoutReport(parameters);
        }
    };

    adminReportDefinitions.paymentProviderTransactionSummary = {
        title: 'Payment provider transaction summary',
        inputFields: [
            getFromMonthInputField(1),
            getToMonthInputField(1)
        ],
        fetchData: values => {
            const inputFields = values.inputFields[adminReportDefinitions.paymentProviderTransactionSummary.key];
            const parameters = {
                from: formatUtcIsoDateTime(startOfMonth(inputFields.from)),
                to: formatUtcIsoDateTime(endOfMonth(inputFields.to))
            };
            return values.format === 'excel'
                ? reports.getPaymentProviderTransactionSummaryExcelFile(parameters)
                : reports.getPaymentProviderTransactionSummary(parameters);
        }
    };

    Object.keys(adminReportDefinitions).forEach(key => {
        adminReportDefinitions[key].key = key;
    });
});

export default adminReportDefinitions;
