import React from 'react';
import { useAppContext } from 'context/AppContext';
import { useSelector } from 'react-redux';
import { getOfficeMenuItems } from './officeMenuItems';

import Hidden from '@material-ui/core/Hidden';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';

const OfficeNavigation = () => {
    const { appContext } = useAppContext();
    const { isAuthenticated, selectedActor } = useSelector(state => state.authentication.context);
    const hasTenantBookings = selectedActor?.tenantBookingCount > 0;

    return (
        <>
            <Hidden smDown>
                <DesktopNavigation menuItems={getOfficeMenuItems('desktop', appContext, hasTenantBookings, isAuthenticated)} />
            </Hidden>
            <Hidden mdUp>
                <MobileNavigation menuItems={getOfficeMenuItems('mobile', appContext, hasTenantBookings, isAuthenticated)} />
            </Hidden>
        </>
    );
};

export default OfficeNavigation;
