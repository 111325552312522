import { formatShortDate } from 'helpers/DateHelper';

export const formatPurchaseItemDescription = (purchaseItem, appContext) => {
    const timePeriodSpecification = formatPurchaseItemTimePeriodSpecification(purchaseItem, appContext);
    return purchaseItem.description + (timePeriodSpecification ? `, ${timePeriodSpecification}` : '');
};

export const formatPurchaseItemTimePeriodSpecification = (purchaseItem, appContext) => {
    let timePeriodSpecification = purchaseItem.timePeriodSpecification;
    if (!timePeriodSpecification && (purchaseItem.startDate || purchaseItem.endDate)) {
        const startDateString = formatShortDate(purchaseItem.startDate, appContext);
        const endDateString =  formatShortDate(purchaseItem.endDate, appContext);
        timePeriodSpecification = `${startDateString} - ${endDateString}`.trim();
    }
    return timePeriodSpecification;
};
