import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { getStorageSite, synchronizeStorageSiteWithParakey } from 'actions/admin/storageSites';
import { handleResponse } from 'actions/actionHelpers';
import routes from 'routes';
import { useHistory } from 'react-router-dom';
import { updateStorageSite } from 'actions/admin/storageSites';
import { storageSiteHasLockCodes, storageSiteHasLockType } from 'helpers/StorageSiteHelper';
import lockTypes from 'enums/lockTypes';

import Loader from 'common/Loader';
import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import AdminActionWrapper from '../../presentational/AdminActionWrapper';
import EditStorageSiteForm from './EditStorageSiteForm';
import Button from '@material-ui/core/Button';
import GetLockCodesDialog from './GetLockCodesDialog';
import { Link } from 'react-router-dom';

const StorageSite = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();

    const [storageSite, setStorageSite] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [lockCodesDialogOpen, setLockCodesDialogOpen] = useState(false);

    const { storageSiteId } = params;

    const hasLockCodes = storageSite && storageSiteHasLockCodes(storageSite);
    const hasParakey = storageSite && storageSiteHasLockType(storageSite, lockTypes.parakey.key);

    useEffect(() => {
       loadStorageSite();
    }, []);

    const handleOpenLockCodesDialogButtonClick = () => {
        setLockCodesDialogOpen(true);
    };

    const handleParakeySynchronizationButtonClick = () => {
        setIsLoading(true);
        dispatch(synchronizeStorageSiteWithParakey(storageSiteId))
        .then(handleResponse(
            () => {
                setIsLoading(false);
                return { snackbar: { message: 'Storage site was synchronized with Parakey.' } };
            },
            () => {
                setIsLoading(false);
            }
        ));
    };

    const loadStorageSite = () => {
        setIsLoading(true);
        dispatch(getStorageSite(storageSiteId))
            .then(handleResponse(
                response => {
                    setStorageSite(response.payload);
                    setIsLoading(false);
                },
                () => setIsLoading(false)
            ));
    };

    const createAdminUpdateStorageSiteRequest = formValues => ({
      ownerActorId: formValues.ownerActorId
    });

    const handleSubmit = formValues => {
        setIsSubmitting(true);
        const request = createAdminUpdateStorageSiteRequest(formValues);
        dispatch(updateStorageSite(storageSiteId, request))
            .then(handleResponse(
                () => {
                    history.push(routes.admin.storageSites);
                    setIsSubmitting(false);
                    return { snackbar: { message: 'Storage site was successfully saved.' } };
                },
                () => setIsSubmitting(false)
            ));
    };

    const handleCancel = () => {
        history.push(routes.admin.storageSites);
    };

    const handleGetLockCodesDialogClose = () => {
        setLockCodesDialogOpen(false);
    };

    const title = `Storage site: ${storageSiteId}`;

    return(
        <>
            <AdminPage title={title}>
                {
                    isLoading && <Loader />
                }
                {
                    !isLoading && storageSite && (
                        <AdminContentWrapper type="detail">

                            <EditStorageSiteForm
                                storageSite={storageSite}
                                onSubmit={handleSubmit}
                                onCancel={handleCancel}
                                isSubmitting={isSubmitting}
                            />

                            <AdminActionWrapper>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    to={`${routes.admin.createTakeover}?storageSiteId=${storageSite.id}`}
                                >
                                    Create takeover
                                </Button>
                                {
                                    hasLockCodes &&
                                    (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleOpenLockCodesDialogButtonClick}
                                        >
                                            Get lock codes
                                        </Button>
                                    )
                                }
                                {
                                    hasParakey &&
                                    (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleParakeySynchronizationButtonClick}
                                        >
                                            Synchronize with Parakey
                                        </Button>
                                    )
                                }
                            </AdminActionWrapper>

                        </AdminContentWrapper>
                    )
                }
                {
                    hasLockCodes &&
                    (
                        <GetLockCodesDialog
                            storageSite={storageSite}
                            open={lockCodesDialogOpen}
                            onClose={handleGetLockCodesDialogClose}
                        />
                    )
                }
            </AdminPage>
        </>
    );
};

export default StorageSite;
