import React from 'react';
import PropTypes from 'prop-types';
import { required as validationRequired } from 'form/validation';
import takeoverStatuses from 'enums/takeoverStatuses';
import takeoverTenantSelections from 'enums/takeoverTenantSelections';
import { getBusinessOrPrivateName } from 'helpers/ActorHelper';

import { TextField, Checkboxes, showErrorOnBlur } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';

const EmailFormFields = ({ takeover, takeoverTenants, selections, formValues, required = false }) => {
    const tenantItems = takeoverTenants.map(o => ({
        label: getBusinessOrPrivateName(o),
        value: o.id
    }));

    const fieldProps = required
        ? { validate: validationRequired }
        : undefined;

    const bodyHelperText = (
            takeover.status === takeoverStatuses.draft
                ? 'Codes that can be used: {name}, {storageSiteTitle}, {storageTitle}.'
                : 'Use {link} to define a unique registration+payment link for the tenant. Other codes that can be used: {name}, {storageSiteTitle}, {storageTitle}.'
        )
        + ' The message will be wrapped in the standard email template with a Storage365 logotype and footer.';

    return (
        <>
            <TextField
                name="subject"
                label="Subject"
                type="text"
                variant="outlined"
                required={required}
                fieldProps={fieldProps}
                showError={showErrorOnBlur}
            />
            <TextField
                name="body"
                label="Body"
                type="text"
                variant="outlined"
                multiline
                rows={10}
                required={required}
                fieldProps={fieldProps}
                showError={showErrorOnBlur}
                helperText={bodyHelperText}
            />
            <TextField
                select
                name="filter.selection"
                label="Selection of tenants"
                variant="outlined"
            >
                {
                    selections.map(o => (
                        <MenuItem key={o.key} value={o.key}>
                            {o.label}
                        </MenuItem>
                    ))
                }
            </TextField>

            {
                formValues.filter.selection === takeoverTenantSelections.custom.key &&
                (
                    <Checkboxes
                        name="filter.customSelectedTakeoverTenantIds"
                        label="Select tenants"
                        data={tenantItems}
                    />
                )
            }
        </>
    );
};

EmailFormFields.propTypes = {
    takeover: PropTypes.object.isRequired,
    takeoverTenants: PropTypes.array.isRequired,
    selections: PropTypes.array.isRequired,
    formValues: PropTypes.object.isRequired,
    required: PropTypes.bool
};

export default EmailFormFields;
