import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';

export const PARAKEY_FETCH_KEYS = 'PARAKEY_FETCH_KEYS';
export const PARAKEY_SYNCHRONIZE_STORAGE_SITE = 'PARAKEY_SYNCHRONIZE_STORAGE_SITE';

export const fetchParakeyKeysForDomainId = parakeyDomainId => ({
    [RSAA]: {
        types: createRsaaTypes(PARAKEY_FETCH_KEYS),
        endpoint: `/api/parakey/domains/${parakeyDomainId}/parakeyKeys`,
        headers: createHeaders(false),
        method: 'GET'
    }
});
