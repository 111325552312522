import React, { useEffect, useState } from 'react';
import { useAppContext } from 'context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import { updateActor, fetchActorUserConnections } from 'actions/actors';
import { refreshAuthenticationContext } from 'actions/authentication';
import strings from 'localization/strings';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { required, email, emailOptional } from 'form/validation';
import arrayMutators from 'final-form-arrays';
import organizationTypes from 'enums/organizationTypes';
import contactTypes from 'enums/contactTypes';
import paymentProviders from 'enums/paymentProviders';
import { getContact } from 'helpers/ActorHelper';

import LoadingWrapper from 'form/LoadingWrapper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Form from 'form/Form';
import { TextField, Checkboxes, showErrorOnBlur } from 'mui-rff';
import ButtonContainer from 'common/ButtonContainer';
import TooltipIcon from 'common/TooltipIcon';
import UserConnectionsEditor from './UserConnectionsEditor';
import Separator from 'common/Separator';
import GenericDialog from 'common/GenericDialog';

const handledContactTypes = [contactTypes.mainEmail.key, contactTypes.mainMobileTelephone.key, contactTypes.invoiceEmail.key];

const ActorSettings = () => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const { appContext } = useAppContext();
    const dispatch = useDispatch();

    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;

    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [initialValues, setInitialValues] = useState(undefined);
    const [confirmRemoveUserConnectionDialogOpen, setConfirmRemoveUserConnectionDialogOpen] = useState(false);
    const [confirmRemoveUserConnectionDialogData, setConfirmRemoveUserConnectionDialogData] = useState({ onOk: () => {} });

    const preventChangeBusinessDetails = appContext.actorInformation.enabled && selectedActor.organizationType === organizationTypes.business.key;

    useEffect(() => {
        fetchUserConnections();
    }, []);

    useEffect(() => {
        if(initialValues) {
            // when saving the actor, we need to pick up the newly saved values and show in the form
            initInitialValues();
        }
    }, [selectedActor]);

    const fetchUserConnections = afterSuccessFunction => {
        setIsLoading(true);
        dispatch(fetchActorUserConnections(selectedActor.id))
            .then(handleResponse(
                response => {
                    setIsLoading(false);
                    initInitialValues(createUserConnectionsForForm(response.payload));
                    return afterSuccessFunction
                        ? afterSuccessFunction()
                        : undefined;
                },
                () => setIsLoading(false)
            ));
    };

    const initInitialValues = userConnections => {
        setInitialValues({
            name: selectedActor.name,
            organizationNumber: selectedActor.organizationNumber,
            email: getContact(selectedActor, contactTypes.mainEmail),
            mobileTelephone: getContact(selectedActor, contactTypes.mainMobileTelephone),
            sendSms: selectedActor.sendSms,
            useInvoiceEmail: !!getContact(selectedActor, contactTypes.invoiceEmail),
            invoiceEmail: getContact(selectedActor, contactTypes.invoiceEmail),
            userConnections: userConnections ?? initialValues.userConnections
        });
    };

    const createUserConnectionsForForm = userConnections =>
        userConnections.map(uc => ({
            ...uc,
            remove: false,
            invite: false
        }));

    const createUserConnectionChanges = userConnections =>
        userConnections
            .filter(uc => uc.remove || uc.invite)
            .map(uc => ({
                id: uc.id,
                type: uc.type,
                action: uc.remove ? 'remove' : 'invite',
                invitationEmail: uc.type === 'userInvitation' ? uc.email : undefined
            }));

    const handleFormSubmit = formValues => {
        const updateActorRequest = {
            name: formValues.name,
            organizationNumber: formValues.organizationNumber,
            contacts: [
                ...selectedActor.contacts.filter(o => !handledContactTypes.includes(o.type)),
                { type: contactTypes.mainEmail.key, value: formValues.email },
                { type: contactTypes.mainMobileTelephone.key, value: formValues.mobileTelephone },
                { type: contactTypes.invoiceEmail.key, value: formValues.useInvoiceEmail ? formValues.invoiceEmail : undefined }
            ],
            sendSms: formValues.sendSms,
            userConnectionChanges: createUserConnectionChanges(formValues.userConnections)
        };

        const userConnectionIsAboutToBeRemoved = updateActorRequest.userConnectionChanges.find(o => o.action === 'remove' && o.id) !== undefined;
        if (userConnectionIsAboutToBeRemoved) {
            setConfirmRemoveUserConnectionDialogData({
                onOk: () => {
                    performUpdateActor(updateActorRequest);
                    setConfirmRemoveUserConnectionDialogOpen(false);
                },
                onCancel: () => {
                    setConfirmRemoveUserConnectionDialogOpen(false);
                }
            });
            setConfirmRemoveUserConnectionDialogOpen(true);
        } else {
            performUpdateActor(updateActorRequest);
        }
    };

    const performUpdateActor = updateActorRequest => {
        setIsSaving(true);
        dispatch(updateActor(selectedActor.id, updateActorRequest))
            .then(handleResponse(
                () => {
                    fetchUserConnections(() => {
                        dispatch(refreshAuthenticationContext())
                            .then(handleResponse(
                                () => {
                                    // context in Redux state is updated by reducer
                                    setIsSaving(false);

                                    const invitedUsers = updateActorRequest.userConnectionChanges.filter(o => o.action === 'invite');
                                    if (invitedUsers.length > 0) {
                                        return {
                                            dialog: {
                                                title: strings.actorSettingsUpdatedUsersInvitedTitle,
                                                body: strings.formatString(strings.actorSettingsUpdatedUsersInvitedBody, invitedUsers.map(o => o.invitationEmail).join(', '))
                                            }
                                        };
                                    }

                                    return {
                                        snackbar: {
                                            message: strings.actorSettingsUpdated
                                        }
                                    };
                                },
                                () => setIsSaving(false)
                            ));
                        });
                },
                () => setIsSaving(false)
            ));
    };

    return (
        <LoadingWrapper
            isLoading={isLoading}
            isSaving={isSaving}
        >
            <Form
                initialValues={initialValues}
                mutators={arrayMutators}
                onSubmit={handleFormSubmit}
            >
                {({ values, handleSubmit, submitting, invalid }) => (
                    <form onSubmit={handleSubmit}>

                        <TextField
                            name="name"
                            label={strings.companyNameLabel}
                            variant="outlined"
                            showError={showErrorOnBlur}
                            required
                            disabled={preventChangeBusinessDetails}
                            fieldProps={{ validate: required }}
                        />

                        <TextField
                            name="email"
                            label={strings.email}
                            variant="outlined"
                            showError={showErrorOnBlur}
                            required
                            fieldProps={{ validate: email }}
                            helperText={strings.actorEmailHelperText}
                        />

                        <TextField
                            name="organizationNumber"
                            label={strings.organizationNumber}
                            variant="outlined"
                            showError={showErrorOnBlur}
                            required
                            disabled={preventChangeBusinessDetails}
                            fieldProps={{ validate: required }}
                        />

                        <TextField
                            name="mobileTelephone"
                            label={strings.phoneNumber}
                            variant="outlined"
                            showError={showErrorOnBlur}
                            required
                            fieldProps={{ validate: required }}
                        />

                        <Box>
                            <Checkboxes
                                name="sendSms"
                                data={{
                                    label: <>{strings.smsCheckbox}<TooltipIcon text={strings.smsCheckboxTooltip}/></>, value: true
                                }}
                            />
                        </Box>

                        {
                            appContext.payment.availablePaymentProviders.includes(paymentProviders.billecta.key) &&
                            (
                                <>
                                    <Box>
                                        <Checkboxes
                                            name="useInvoiceEmail"
                                            data={{
                                                label: <>{strings.useInvoiceEmailCheckbox}<TooltipIcon text={strings.useInvoiceEmailTooltip}/></>, value: true
                                            }}
                                        />
                                    </Box>

                                    {
                                        values.useInvoiceEmail &&
                                        (
                                            <TextField
                                                name="invoiceEmail"
                                                label={strings.invoiceEmail}
                                                variant="outlined"
                                                showError={showErrorOnBlur}
                                                fieldProps={{ validate: emailOptional }}
                                            />
                                        )
                                    }
                                </>
                            )
                        }

                        <Separator spacing={4}/>

                        <Typography variant="h6" gutterBottom>
                            {strings.userConnections}
                        </Typography>

                        <UserConnectionsEditor name="userConnections"/>

                        <ButtonContainer>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={submitting || invalid || isSaving}
                                fullWidth={isMobile}
                            >
                                {strings.save}
                            </Button>
                        </ButtonContainer>
                    </form>
                )}
            </Form>
            <GenericDialog
                open={confirmRemoveUserConnectionDialogOpen}
                title={strings.confirmRemoval}
                body={strings.confirmRemoveUserConnections}
                okLabel={strings.yes}
                cancelLabel={strings.no}
                {...confirmRemoveUserConnectionDialogData}
            />
        </LoadingWrapper>
    );
};

export default ActorSettings;
