import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { required } from 'form/validation';
import strings from 'localization/strings';
import paymentRecurrences from 'enums/paymentRecurrences';
import organizationTypes from 'enums/organizationTypes';
import { createEndAdornment } from 'helpers/FormHelper';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { TextField, Checkboxes, showErrorOnBlur } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(() => ({
    eligibleForVatContainer: {
        paddingTop: '27px !important'
    }
}));

const AdditionalServiceEditor = ({ fieldNamePrefix, actor }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;

    const showEligibleForVat = actor.organizationType === organizationTypes.business.key;

    return (
        <Box>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <TextField
                        name={getName('description')}
                        label={strings.description}
                        size="small"
                        variant="outlined"
                        showError={showErrorOnBlur}
                        required
                        fieldProps={{ validate: required }}
                    />
                </Grid>

                <Grid item xs={showEligibleForVat ? 6 : 12} md={showEligibleForVat ? 4 : 6}>
                    <TextField
                        name={getName('price')}
                        label={strings.price}
                        size="small"
                        variant="outlined"
                        showError={showErrorOnBlur}
                        helperText={strings.enterAmountExcludingVat}
                        required
                        fieldProps={{ validate: required }}
                        InputProps={createEndAdornment(appContext.currency.label)}
                    />
                </Grid>

                {
                    showEligibleForVat &&
                    (
                        <Grid item xs={6} md={4} className={classes.eligibleForVatContainer}>
                            <Checkboxes
                                name={getName('eligibleForVat')}
                                data={
                                    { label: strings.eligibleForVat, value: true }
                                }
                            />
                        </Grid>
                        )
                }

                <Grid item xs={12} md={showEligibleForVat ? 4 : 6}>
                    <TextField
                        select
                        name={getName('paymentRecurrence')}
                        label={strings.paymentRecurrence}
                        size="small"
                        variant="outlined"
                    >
                        {
                            Object.values(paymentRecurrences).map(pt =>
                                <MenuItem key={pt.key} value={pt.key}>{pt.title}</MenuItem>
                            )
                        }
                    </TextField>
                </Grid>
            </Grid>
        </Box>
    );
};

AdditionalServiceEditor.propTypes = {
    fieldNamePrefix: PropTypes.string.isRequired,
    actor: PropTypes.object.isRequired
};

export default AdditionalServiceEditor;
