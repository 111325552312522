import React, { useEffect, useRef } from 'react';
import { useAppContext } from 'context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import { searchBookings, setSearchParameters } from 'actions/admin/bookings';
import { performSearch, mergeSearchParameters } from 'components/adminTable/AdminTableHelper';
import bookingStatuses from 'enums/bookingStatuses';
import routes from 'routes';
import { formatShortDateTime } from 'helpers/DateHelper';

import Loader from 'common/Loader';
import Box from '@material-ui/core/Box';
import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import SearchBookingsForm from './SearchBookingsForm';
import AdminTable from 'components/adminTable/AdminTable';
import Tooltip from '@material-ui/core/Tooltip';
import FlagIcon from '@material-ui/icons/Flag';
import TimeAgo from 'common/TimeAgo';
import ShortDate from 'common/ShortDate';

const Bookings = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { appContext } = useAppContext();

    const searchResult = useSelector(state => state.admin.bookings.searchResult);
    const searchParametersFromState = useSelector(state => state.admin.bookings.searchParameters);

    const defaultParameters = {
        bookingId: undefined,
        ownerQuery: undefined,
        tenantQuery: undefined,
        storageSiteTitle: undefined,
        status: bookingStatuses.requested.key,
        page: 0,
        pageSize: 20,
        sortByColumn: 'updatedTime',
        sortDirection: 'desc'
    };

    const formatNote = note => {
        if(!note?.text) {
            return undefined;
        }
        return `${note.createdByUserName} (${formatShortDateTime(note.createdTime, appContext)}): ${note.text}`;
    };

    const columns = [
        {
            id: 'flagged',
            render: (booking) => booking.flagged
                ? <Tooltip title={formatNote((booking.notes ?? [])[0])}><FlagIcon/></Tooltip>
                : undefined
        },
        {
            title: 'ID',
            id: 'id',
            render: (booking) => <Link to={routes.admin.booking.replace(':bookingId', booking.id)}>{booking.id}</Link>,
            align: 'right'
        },
        {
            title: 'Storage',
            id: 'storageSiteTitle',
            render: (booking) => <Link to={routes.storageSiteDetails.replace(':storageSiteId', booking.storageSiteId)} target="_blank">{booking.storageSiteTitle}</Link>
        },
        {
            title: 'Owner',
            id: 'ownerName',
            render: (booking) => <Link to={routes.admin.actor.replace(':actorId', booking.ownerActorId)}>{booking.ownerName}</Link>
        },
        {
            title: 'Tenant',
            id: 'tenantName',
            render: (booking) => <Link to={routes.admin.actor.replace(':actorId', booking.tenantActorId)}>{booking.tenantName}</Link>
        },
        {
            title: 'Subscription',
            id: 'subscriptionBooking',
            render: (booking) => booking.subscriptionBooking ? 'yes' : 'no'
        },
        searchParametersFromState.status === 'all'
            ? {
                title: 'Lifecycle',
                id: 'status',
                render: (booking) => booking.adminBookingStatus
            }
            : undefined,
        {
            title: 'Payment provider',
            id: 'paymentProvider',
            render: (booking) => booking.paymentProvider
        },
        {
            title: 'Start date',
            id: 'startDate',
            whiteSpace: 'nowrap',
            render: (booking) => <ShortDate value={booking.startDate}/>
        },
        {
            title: 'End date',
            id: 'endDate',
            whiteSpace: 'nowrap',
            render: (booking) => <ShortDate value={booking.actualEndDate ?? booking.endDate ?? booking.subscriptionEndDate} defaultString="until further" />
        },
        {
            title: 'Subscription end reason',
            id: 'subscriptionEndReason',
            whiteSpace: 'nowrap',
            render: (booking) => booking.subscriptionEndReason
        },
        {
            title: 'Updated',
            id: 'updatedTime',
            render: (booking) => <TimeAgo date={booking.updatedTime}/>
        }
    ].filter(o => o);

    const search = parameters => {
        const searchParameters = mergeSearchParameters(parameters, location, searchParametersFromState, defaultParameters);
        performSearch(performSearchObject, searchParameters);
    };

    const getParametersForApiCall = parameters => ({
        ...parameters,
        status: parameters.status === 'all'
            ? undefined
            : parameters.status
    });

    const performSearchObject = {
        searchParametersFromState,
        setSearchParametersToState: parameters => dispatch(setSearchParameters(parameters)),
        searchResult,
        search,
        apiCall: parameters => dispatch(searchBookings(getParametersForApiCall(parameters))),
        location,
        history,
        isInitializedRef: useRef()
    };

    useEffect(() => {
        const searchParameters = mergeSearchParameters({}, location, searchParametersFromState, defaultParameters);
        performSearch(performSearchObject, searchParameters);
    }, []);

    const handleSearchFormSubmit = (formValues) => {
        // special handling for booking id
        if(formValues.bookingId) {
            history.push(routes.admin.booking.replace(':bookingId', formValues.bookingId));
            return;
        }

        search({ ...defaultParameters, ...formValues, page: 0 });
    };

    const handleSort = (sortingParameters) => {
        search(sortingParameters);
    };

    const handlePageChange = (newPage) => {
        search({ page: newPage });
    };

    const handlePageChangeSize = (newPageSize) => {
        search({ page: 0, pageSize: newPageSize });
    };

    return (
        <AdminPage title="Bookings">
            <AdminContentWrapper type="listing">

                <SearchBookingsForm
                    searchParameters={searchParametersFromState}
                    onSubmit={handleSearchFormSubmit}
                />

                <Box>
                    {
                        searchResult.isLoading && <Loader />
                    }
                    {
                        searchResult.items && (
                            <AdminTable
                                isPaged
                                items={searchResult.items}
                                count={searchResult.count}
                                page={searchResult.page}
                                pageSize={searchResult.pageSize}
                                columns={columns}
                                sortByColumn={columns.find(c => c.id === searchParametersFromState.sortByColumn)}
                                sortDirection={searchParametersFromState.sortDirection}
                                onSort={handleSort}
                                onPageChange={handlePageChange}
                                onPageChangeSize={handlePageChangeSize}
                            />
                        )
                    }
                </Box>

            </AdminContentWrapper>
        </AdminPage>
    );
};

export default Bookings;
