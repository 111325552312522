import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { formatPurchaseItemTimePeriodSpecification } from 'helpers/PurchaseItemHelper';

import VatDetails from 'common/PriceSpecification/VatDetails';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Amount from 'common/Amount';

const useStyles = makeStyles(({ theme, colors }) => ({
    footer: {
        borderTop: `1px solid ${colors.lightGreyBorder ?? colors.mediumGrey}`,
        padding: theme.spacing(1.875),
        '& *': {
            fontSize: theme.typography.body2.fontSize
        }
    },
    tableContentRow: {
        '&:not(:last-child)': {
            paddingBottom: theme.spacing(1.875)
        }
    },
    tableRowInfo: {
        color: colors.mediumDarkGrey
    },
    vatInfo: {
        '& > *': {
            color: colors.mediumDarkGrey
        }
    }
}));


const PurchaseItem = ({purchaseItem, currency, formatAmountOptions, showVat = false, expandVat = false, rowClassName}) => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const timePeriodSpecification = formatPurchaseItemTimePeriodSpecification(purchaseItem, appContext);
    return (
        <Box className={classes.tableContentRow}>
            <Box className={clsx(rowClassName)}>
                <Typography variant="body2">{purchaseItem.description}</Typography>
                <Box>
                    <Typography variant="body2"><Amount value={purchaseItem.amount + (purchaseItem.vat ?? 0)} currency={currency} {...formatAmountOptions}/></Typography>
                    {
                        showVat && <VatDetails vat={purchaseItem.vat ?? 0} currency={currency} {...formatAmountOptions} expandVat={expandVat}  />
                    }
                </Box>
            </Box>
            {
                timePeriodSpecification &&
                (
                    <Box className={classes.tableRowInfo}>
                        <Typography variant="body2">{timePeriodSpecification}</Typography>
                    </Box>
                )
            }
        </Box>
    );
};

PurchaseItem.propTypes = {
    purchaseItem: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    formatAmountOptions: PropTypes.object,
    showVat: PropTypes.bool,
    expandVat: PropTypes.bool,
    rowClassName: PropTypes.string
};

export default PurchaseItem;
