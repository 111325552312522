import React, { useState, useEffect } from 'react';
import { useAppContext } from 'context/AppContext';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { useLocation, useHistory } from 'react-router-dom';
import { handleResponse } from 'actions/actionHelpers';
import queryString from 'query-string';
import { logIn, setRedirectUrl, setLogInPageMessage } from 'actions/authentication';
import { storeBookingRequestValues, storeBookingRequestValuesAtLoginRedirect } from 'actions/bookingRequest';
import { showSuccessDialog, showErrorDialog } from 'actions/responses';
import rentalTypes from 'enums/rentalTypes';
import { getResizedImageUrl } from 'helpers/ImageHelper';
import { alpha } from '@material-ui/core/styles';
import { formatAmount, convertToNumber } from 'helpers/StringHelper';
import { getStorageTypeItem, getAreaExplanation, getStorageSiteAttentionText, sanitizeSelectedCategory } from 'helpers/StorageSiteHelper';
import { getAreaAndPrice } from 'helpers/StorageAreaCalculator';
import { makeStyles } from 'styles/util';
import { getInitialBookingFormValues, validateBookingFormValues, getSelectedStorageGroupId } from 'logic/bookingLogic';
import { googleTagManagerConstants, createGoogleTagManagerUserAction } from 'integration/google-tag-manager/googleTagManagerHelper';
import { makePayment } from 'logic/paymentLogic';
import purchaseTypes from 'enums/purchaseTypes';
import { getFormValues, getFormValuesAtLoginRedirect } from 'selectors/bookingRequest';
import { enhanceStripeErrorMessage } from 'helpers/StripeHelper';
import storageSiteStatuses from 'enums/storageSiteStatuses';
import routes from 'routes';
import { getBusinessOrPrivateName } from 'helpers/ActorHelper';

import PageTitle from 'common/PageTitle';
import OpenGraphImage from 'common/OpenGraphImage';
import StorageSiteFacebookMetaTags from './StorageSiteFacebookMetaTags';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ImageCarousel from './ImageCarousel';
import StorageSiteImage from './StorageSiteImage';
import FullScreenLoader from 'common/FullScreenLoader';
import BookingRequestDialog from './BookingRequestDialog';
import StorageBookingForm from './StorageBookingForm';
import Address from './Address';
import StorageSiteMap from './StorageSiteMap';
import DimensionsAndFacts from './DimensionsAndFacts';
import OpeningTimeSpanRules from './OpeningTimeSpanRules';
import ContactOwner from './ContactOwner';
import InitialPaymentDialog from 'account/bookings/InitialPaymentDialog';
import DocumentsAndServices from './DocumentsAndServices';
import Facilities from './Facilities';
import AreaAndPrice from 'common/AreaAndPrice';
import Description from './Description';
import Hidden from '@material-ui/core/Hidden';
import Modal from '@material-ui/core/Modal';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import StorageSiteDetailsTitle from './StorageSiteDetailsTitle';
import Alert from '@material-ui/lab/Alert';
import Container from 'common/Container';

const useStyles = makeStyles(({ theme, colors, fonts }) => ({
    previewContainer: {
        padding: theme.spacing(7, 0),
        background: colors.secondaryColor,
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0)
        }
    },
    infoWrapper: {
        display: 'flex',
        marginTop: theme.spacing(5),
        gap: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 0, 2, 0),
            flexDirection: 'column'
        }
    },
    imageContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(0.5),
        marginBottom: theme.spacing(5),
        position: 'relative'
    },
    bookingContainer: {
        minWidth: '300px',
        flex: 1
    },
    infoContainer: {
        flex: '1 0 70%',
        [theme.breakpoints.down('sm')]: {
            flex: '1 0 100%'
        },
        position: 'relative'
    },
    attentionText: {
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2),
        zIndex: 1,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        lineHeight: 1.4,
        overflow: 'hidden',
        borderRadius: '100px',
        padding: theme.spacing(1.25, 2),
        color: colors.attentionForeground,
        backgroundColor: colors.attentionBackground,
        fontFamily: fonts.medium
    },
    actionButtonContainer: {
        margin: theme.spacing(4, 0),
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            padding: theme.spacing(2)
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2, 0)
        },
        '& > *': {
            marginRight: theme.spacing(2),
            fontSize: theme.spacing(2),
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: '150%',
            letterSpacing: '-0.01em',
            [theme.breakpoints.down('sm')]: {
                marginRight: 0,
                marginBottom: theme.spacing(2),
                '&:last-child': {
                    marginBottom: 0
                }
            }
        }
    },
    ownerName: {
        marginBottom: theme.spacing(2),
        fontStyle: 'italic',
        display: 'block'
    },
    bookingModal: {
        display: 'flex',
        justifyContent: 'center'
    },
    openBookingDialogButtonContainer: {
        zIndex: '1000',
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        backgroundColor: alpha(colors.secondaryColor, 0.67),
        padding: theme.spacing(2),
        width: '100%',
        '& button': {
            width: '100%',
            minHeight: '50px',
            fontSize: theme.spacing(2),
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: '150%',
        }
    }
}));

const StorageSiteDetails = ({ storageSite, selectedCategory, isMobile, isPreview = false }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const querystringParameters = queryString.parse(location.search);
    const requestDialogOpenFromQuerystring = !!(querystringParameters.requestDialogOpen || false);
    const authenticationContext = useSelector(state => state.authentication.context);
    const { user, selectedActor } = authenticationContext;

    const formValuesAtLoginRedirect = useSelector(getFormValuesAtLoginRedirect);
    const formValuesFromState = {
        ...useSelector(getFormValues),
        ...(requestDialogOpenFromQuerystring ? formValuesAtLoginRedirect : undefined)
    };
    const successDialogOpen = useSelector(state => state.responses.successDialogOpen);
    const successDialogData = useSelector(state => state.responses.successDialogData);
    const storageTypeItem = getStorageTypeItem(storageSite.storageType);
    const sanitizedSelectedCategory = sanitizeSelectedCategory(storageSite, selectedCategory);

    const imageOptions = {
        width: 800,
        height: 500,
        mode: 'crop'
    };

    useEffect(() => {
        if(!successDialogOpen && successDialogData?.closeAction) {
            // redirect to booking details page
            history.push(successDialogData?.closeAction);
        }
    }, [successDialogOpen, successDialogData]);


    const imageDisplay = storageSite.images.length >= 1 || storageSite.videos.length >= 1
        ? (
            <ImageCarousel images={storageSite.images} videos={storageSite.videos} width={imageOptions.width} height={imageOptions.height} />
        )
        : (
            <StorageSiteImage
                storageSite={storageSite}
                storageTypeItem={storageTypeItem}
                imageOptions={imageOptions}
            />
        );

    const getDataForBookingRequestDialog = () => {
        const storageGroupId = getSelectedStorageGroupId(formValuesFromState);
        const storageGroup = storageSite.storageGroups.find(sg => sg.id === storageGroupId);
        const isCustomArea = storageGroup && storageGroup.rentalType === rentalTypes.customArea.key && formValuesFromState.categories;
        return {
            storageGroup,
            requestedStartDate: formValuesFromState.requestedStartDate,
            requestedEndDate: formValuesFromState.requestedEndDate,
            width: isCustomArea ? convertToNumber(formValuesFromState.categories[storageGroup.category].width) : undefined,
            length: isCustomArea ? convertToNumber(formValuesFromState.categories[storageGroup.category].length) : undefined,
            tenantOrganizationType: undefined // TODO
        };
    };

    const { areaString, pricePrefix, displayPrice, currency, items } = getAreaAndPrice(storageSite, sanitizedSelectedCategory, appContext, selectedActor?.organizationType);

    const [initialPaymentDialogOpen, setInitialPaymentDialogOpen] = useState(false);
    const [savedBooking, setSavedBooking] = useState(undefined);

    const [errors, setErrors] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    // use useState to load only once
    const [initialFormValues] = useState(getInitialBookingFormValues(storageSite, formValuesFromState.requestedStartDate, formValuesFromState.requestedEndDate, formValuesFromState.categories, sanitizedSelectedCategory, getSelectedStorageGroupId(formValuesFromState), appContext, isPreview));
    const [requestDialogOpen, setRequestDialogOpen] = useState(requestDialogOpenFromQuerystring && !!formValuesAtLoginRedirect);

    const handleStorageBookingFormSubmit = values => {
        // storeBookingRequestValues stores in Redux state so it can be picked up by BookingRequestDialog through getDataForBookingRequestDialog
        // also, some data is persisted in local storage so it can be pre-filled next time the user comes back
        dispatch(storeBookingRequestValues(values));

        const validationErrors = validateBookingFormValues(storageSite, values, appContext);
        setErrors(validationErrors);
        if(validationErrors.length === 0) {
            if(!selectedActor) {
                // not logged in, redirect
                dispatch(storeBookingRequestValuesAtLoginRedirect(values)); // we wish to keep the values when coming back after logging in
                handleLogIn({ requestDialogOpen: true }, strings.loginRequiredForBookingRequestBody);
            } else {
                // open the booking request dialog where the actor provides additional booking information
                setRequestDialogOpen(true);
            }
        }
    };

    const handleInitialPaymentDialogOk = ({ values, onCompleted }) => {
        const googleTagManagerMetadata = `${savedBooking.storageGroup.category}-${(savedBooking.subscriptionBooking ? 'subscription' : 'period')}`;
        dispatch(createGoogleTagManagerUserAction(googleTagManagerConstants.userActions.bookingPaymentInitialized, googleTagManagerMetadata));
        makePayment({
            payment: values.payment,
            dispatch,
            booking: savedBooking,
            type: savedBooking.subscriptionBooking ? purchaseTypes.subscriptionBookingInitialPurchase.key : purchaseTypes.periodBookingPurchase.key,
            successUrlParameters: {
                action: googleTagManagerConstants.userActions.bookingPaymentSucceeded
            },
            onFailure: response => {
                setInitialPaymentDialogOpen(false);
                onCompleted();
                dispatch(showErrorDialog(enhanceStripeErrorMessage(response), { title: strings.paymentFailedTitle, body: strings.paymentFailedBody }));
            }
        });
    };

    const handleInitialPaymentDialogCancel = () => {
        setInitialPaymentDialogOpen(false);
    };

    const handleBookingSuccess = booking => {
        setSavedBooking(booking);
        setRequestDialogOpen(false);

        // pay immediately or later?
        if(booking.immediateBookingRequestId) {
            setInitialPaymentDialogOpen(true);
        } else {
            dispatch(showSuccessDialog({
                title: strings.bookingRequestSent,
                body: strings.bookingRequestDescriptionFirst + ' ' + strings.bookingRequestDescriptionSecond,
                useAnimation: true,
                closeAction: routes.account.tenantBookingDetails.replace(':bookingId', booking.id)
            }));
        }
    };

    const handleLogIn = (params, message) => {
        const q = queryString.stringify(params);
        dispatch(setRedirectUrl(location.pathname + (q ? '?' + q : '')));
        dispatch(setLogInPageMessage({ title: strings.loginRequired, body: message }));
        history.push(routes.logIn);
    };

    const handleLogInAsOwner = () => {
        dispatch(logIn({ impersonationActorId: storageSite.ownerActor.id }))
        .then(handleResponse(
            () => history.push(routes.account.storageSites)
        ));
    };

    const attentionText = getStorageSiteAttentionText(storageSite, appContext);

    return (
        <>
            <PageTitle>{storageSite.title}</PageTitle>
            {
                storageSite.images[0] &&
                (
                    <OpenGraphImage
                        url={getResizedImageUrl(storageSite.images[0].mediaUrl, imageOptions)}
                        width={imageOptions.width}
                        height={imageOptions.height}
                        alt={storageSite.title}
                    />
                )
            }
            <StorageSiteFacebookMetaTags storageSite={storageSite} />

            <FullScreenLoader open={isSaving} />

            <Container smMaxWidth="585px" withPageMargins className={isPreview ? classes.previewContainer : ''}>
                <StorageSiteDetailsTitle storageSite={storageSite} />
                {
                    storageSite.status !== storageSiteStatuses.approved.key &&
                    (
                        <Alert severity="warning">{strings.storageSiteNotApproved}</Alert>
                    )
                }
                <Box className={classes.infoWrapper}>
                    <Box className={classes.infoContainer}>
                        <Box className={classes.imageContainer}>
                            {imageDisplay}
                            {
                                attentionText && <Box className={classes.attentionText}>{attentionText}</Box>
                            }
                            <AreaAndPrice
                                horizontal
                                areaTitle={getAreaExplanation(storageSite)}
                                areaText={areaString}
                                priceTitle={pricePrefix}
                                priceText={`${formatAmount(displayPrice, currency, appContext)}/${strings.monthAbbreviated}`}
                                backgroundColor={appContext.colors.secondaryColor}
                                relevantStorageSites
                            />
                        </Box>
                        <Box>
                            {
                                authenticationContext.isSuperAdminOrImpersonated && storageSite.ownerActor &&
                                (
                                    <Box className={classes.ownerName} component="span">{strings.owner}: {getBusinessOrPrivateName(storageSite.ownerActor)}</Box>
                                )
                            }
                            <Description title={strings.description} titleVariant="h2" html={storageSite.description} marginBottom={4} />
                            {
                                storageSite.ownerActor?.description &&
                                (
                                    <Description titleVariant="h3" title={strings.ownerDescription} html={storageSite.ownerActor?.description} marginBottom={4} />
                                )
                            }
                            <Facilities storageSite={storageSite} marginBottom={4} />
                            <DimensionsAndFacts storageSite={storageSite} items={items} marginBottom={4}/>
                            <Address storageSite={storageSite} />
                            <OpeningTimeSpanRules show={storageSite.openingTimeSpanRules?.length} storageSite={storageSite} />
                            <DocumentsAndServices storageSite={storageSite} />
                            <Box className={classes.actionButtonContainer}>
                                <Hidden mdUp>
                                    <Button
                                        type="button"
                                        color="primary"
                                        variant="contained"
                                        fullWidth={isMobile}
                                        onClick={() => setModalOpen(prev => !prev)}
                                    >
                                        {strings.seePriceAndSendBooking}
                                    </Button>
                                </Hidden>
                                <ContactOwner storageSite={storageSite} userId={user?.id} actorId={selectedActor?.id} setIsSaving={setIsSaving} onLogIn={handleLogIn}/>
                                {
                                    authenticationContext.isSuperAdminOrImpersonated &&
                                    (
                                        <Button
                                            type="button"
                                            color="primary"
                                            variant="contained"
                                            fullWidth={isMobile}
                                            onClick={handleLogInAsOwner}
                                        >
                                            {strings.logInAsOwner}
                                        </Button>
                                    )
                                }
                            </Box>
                            <StorageSiteMap storageSite={storageSite} />
                        </Box>
                    </Box>
                    <Hidden smDown>
                        <Box className={classes.bookingContainer}>
                            <StorageBookingForm
                                storageSite={storageSite}
                                selectedCategory={sanitizedSelectedCategory}
                                initialValues={initialFormValues}
                                onSubmit={handleStorageBookingFormSubmit}
                                errors={errors}
                                isPreview={isPreview}
                            />
                        </Box>
                    </Hidden>
                </Box>
                { requestDialogOpen && <BookingRequestDialog open onClose={() => setRequestDialogOpen(false)} onBookingSuccess={handleBookingSuccess} storageSite={storageSite} bookingRequestData={getDataForBookingRequestDialog()}/> }
                { initialPaymentDialogOpen && <InitialPaymentDialog booking={savedBooking} open onOk={handleInitialPaymentDialogOk} onCancel={handleInitialPaymentDialogCancel} /> }

            </Container>

            <Hidden mdUp>
                <Box className={classes.openBookingDialogButtonContainer}>
                    <Button
                        onClick={() => setModalOpen(prev => !prev)}
                        color="primary"
                        variant="contained"
                        endIcon={<KeyboardArrowUp style={{ fontSize: 22 }} />}
                    >
                        {strings.seePriceAndSendBooking}
                    </Button>
                </Box>

                <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(prev => !prev)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{ overflow: 'scroll' }}
                    className={classes.bookingModal}
                >
                    <Box>
                        <StorageBookingForm
                            storageSite={storageSite}
                            selectedCategory={sanitizedSelectedCategory}
                            initialValues={initialFormValues}
                            onSubmit={handleStorageBookingFormSubmit}
                            errors={errors}
                            isPreview={isPreview}
                            closeModal={() => setModalOpen(false)}
                        />
                    </Box>
                </Modal>
            </Hidden>
        </>
    );
};

StorageSiteDetails.propTypes = {
    storageSite: PropTypes.object.isRequired,
    selectedCategory: PropTypes.object,
    isMobile: PropTypes.bool.isRequired,
    isPreview: PropTypes.bool
};

export default StorageSiteDetails;
