import { formatUtcIsoDateTime, formatIsoDate } from 'helpers/DateHelper';
import { convertToNumber } from 'helpers/StringHelper';
import cancellationReasons from 'enums/cancellationReasons';
import { startOfDay } from 'date-fns';

export const createUpdateBookingRequest = formValues => ({
    startDate: formatIsoDate(formValues.startDate),
    endDate: formatIsoDate(formValues.endDate),
    actualEndDate: formatIsoDate(formValues.actualEndDate),
    confirmationDeadline: formatUtcIsoDateTime(formValues.confirmationDeadline),
    initialPaymentDeadline: formatUtcIsoDateTime(formValues.initialPaymentDeadline),
    flagged: formValues.flagged,
    cancellationReason: formValues.cancellationReason,
    cancellationReasonText: formValues.cancellationReason === cancellationReasons.other.key
        ? formValues.cancellationReasonText
        : undefined,
    subscriptionPeriodStoragePrice: convertToNumber(formValues.subscriptionPeriodStoragePrice),
    storageVatRate: convertToNumber(formValues.storageVatRate) / 100,
    bookingItems: formValues.bookingItems.map(bookingItem => ({
        id: bookingItem.id,
        description: bookingItem.description,
        amount: convertToNumber(bookingItem.amount),
        vatRate: convertToNumber(bookingItem.vatRate) / 100,
        commissionRate: convertToNumber(bookingItem.commissionRate) / 100,
        referenceId: bookingItem.referenceId,
        paymentRecurrence: bookingItem.paymentRecurrence,
        type: bookingItem.type
    })),
    storageGroupId: formValues.storageGroupId,
    storageId: formValues.storageId,
    effectDate: formatIsoDate(formValues.effectDate) ?? formatIsoDate(startOfDay(new Date())),
    tenantActorId: formValues.tenantActorId,
    bookingStatus: formValues.bookingStatus,
    lockConfiguration: formValues.lockConfiguration,
    tenantReferenceText: formValues.tenantReferenceText
        ? formValues.tenantReferenceText
        : undefined
});
