import React from 'react';
import strings from 'localization/strings';
import { questionAndAnswerItemsTenantSmall } from 'helpers/QuestionAndAnswersHelper';
import { Link } from 'react-router-dom';

import { useAppContext } from 'context/AppContext';
import { useSelector } from 'react-redux';
import { makeStyles } from 'styles/util';
import landingPages from 'enums/landingPages';
import { getUserLocation } from 'helpers/UserHelper';
import { loadSearchParametersFromLocalStorage } from 'helpers/SearchHelper';
import storageGroupCategories, { getStorageGroupCategoryOrDefault } from 'enums/storageGroupCategories';
import routes from 'routes';

import PageTitle from 'common/PageTitle';
import VisibleFor from 'common/VisibleFor';
import { Helmet } from 'react-helmet-async';
import StartPageSearchForm from './StartPageSearchForm';

import FeatureHighlightIcon from 'components/FeatureHighlightIcon';
import FeatureHighlightNumber from 'components/FeatureHighlightNumber';
import StyledList from 'components/StyledList';
import StyledListItem from 'components/StyledListItem';
import ContentBox from 'common/ContentBox';
import ContactBox from 'common/ContactBox';
import ExpandableTitleDescriptionList from 'components/expandableTitleDescriptionList/ExpandableTitleDescriptionList';

import MuiContainer from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import MockStorageSite from 'components/MockStorageSite';

import merryChristmas from 'assets/images/merry-christmas.jpg';

const useStyles = makeStyles(({ theme, colors, images, fonts }) => ({
    defaultSpacing: {
        paddingTop: theme.spacing(7.5),
        '&:last-of-type': {
            paddingBottom: theme.spacing(7.5)
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(5),
            '&:last-of-type': {
                paddingBottom: theme.spacing(5)
            }
        }
    },
    relevantStorageSitesWrapper: {
        background: colors.white,
        padding: theme.spacing(4, 0)
    },
    mainImageContainer: {
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center right',
        backgroundBlendMode: 'multiply',
        padding: theme.spacing(0, 5),
        position: 'relative'
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '30px',
        minHeight: '590px',
        maxWidth: '530px',
        margin: '0 auto',
        paddingTop: theme.spacing(6.25),
        paddingBottom: theme.spacing(6.25),
        [theme.breakpoints.down('sm')]: {
            minHeight: '630px'
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: '550px',
            paddingBottom: theme.spacing(5)
        }
    },
    mainCaption: {
        color: colors.white,
        textAlign: 'center',
        fontSize: '4.5rem',
        letterSpacing: '-2.5px',
        lineHeight: 0.92,
        [theme.breakpoints.down('xs')]: {
            fontSize: '3.2rem',
            margin: '0 -20px'
        }
    },
    subtitle: {
        color: colors.white,
        textAlign: 'center',
        marginTop: theme.spacing(2.5),
        fontFamily: fonts.regular
    },
    introWrapper: {
        textAlign: 'center',
        color: colors.white,
        padding: theme.spacing(7.5, 0, 8.75),
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(3.75)
        }
    },
    mainBackgroundColor: {
        backgroundColor: colors.primaryColor,
        paddingBottom: theme.spacing(4)
    },
    clippedBgWrapper: {
        position: 'relative',
        zIndex: '1',
        overflow: 'hidden',
        marginTop: '-5rem',
        paddingTop: '6rem',
        background: `url(${images.clippedBgLight}) no-repeat left top`,
        backgroundSize: '100%',
        [theme.breakpoints.down('sm')]: {
            backgroundImage: `url(${images.clippedBgLightSm})`,
            paddingTop: '5rem',
            marginTop: '-5rem',
        },
        [theme.breakpoints.down('xs')]: {
            backgroundImage: `url(${images.clippedBgLightXs})`,
        }
    },
    showcaseAds: {
        backgroundColor: colors.lightBackgroundColor ?? colors.white,
        paddingTop: theme.spacing(7.5),
        paddingBottom: theme.spacing(7.5),
        [theme.breakpoints.down('sm')]: {
            '& > div': {
                maxWidth: '670px'
            }
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(4.25),
            paddingBottom: theme.spacing(4.25)
        }
    },
    reverseMobile: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse',
            '& > div:last-child': {
                textAlign: 'center'
            }
        }
    },
    btnFullwidth: {
        width: '100%'
    },
    maxWidthSm: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: `${theme.themeContentWidth.xxs} !important`
        }
    },
    faqCta: {
        paddingTop: theme.spacing(5),
        '& a': {
            color: colors.textColor
        }
    },
    merryChristmasMobile: {
        margin: '0 auto',
        textAlign: 'center',
        lineHeight: 0,
        '& img': {
            width: '100%',
            maxHeight: '512px',
            maxWidth: '512px'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    merryChristmasDesktop: {
        position: 'absolute',
        textAlign: 'right',
        top: theme.spacing(4),
        bottom: theme.spacing(4),
        right: theme.spacing(4),
        width: `calc(50% - ${300 + theme.spacing(4)}px)`,
        '& img': {
            width: '100%',
            maxHeight: '530px',
            maxWidth: '530px'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
     }
}));

const StartPage = () => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const startPageCategory = getStorageGroupCategoryOrDefault(useSelector(state => state.localStorage.startPageCategory), appContext);
    let storedSearchParameters = loadSearchParametersFromLocalStorage();
    const location = useSelector(state => storedSearchParameters.lat && storedSearchParameters.lng
        ? { lat: storedSearchParameters.lat, lng: storedSearchParameters.lng }
        : getUserLocation(state.authentication.ipLocation));
    storedSearchParameters = { ...storedSearchParameters, ...location };

    const selectedLandingPage = landingPages[startPageCategory.key];

    // Requested by the designer to add line breaks on commas in the text
    const addLineBreakOnComma = (text) => {
        return text.split(',').flatMap((item, index, array) => {
            return index < array.length - 1 ? [item, ', ', <br key={index} />] : [item];
        });
    };

    return (
        <>
            <PageTitle>{strings.startPageTitle}</PageTitle>
            <Helmet defer={false}>
                <meta name="description" content={strings.headerDescriptionHome}/>
            </Helmet>
            {
                new Date().getMonth() === 11 &&
                (
                    <VisibleFor branch="storage" market="sweden">
                        <Box className={classes.merryChristmasMobile}>
                            <img src={merryChristmas}/>
                        </Box>
                    </VisibleFor>
                )
            }
            <MuiContainer className={classes.mainImageContainer} maxWidth={false} style={{ backgroundImage: `url(${selectedLandingPage?.imageSrc}` }}>
                {
                    new Date().getMonth() === 11 &&
                    (
                        <VisibleFor branch="storage" market="sweden">
                            <Box className={classes.merryChristmasDesktop}>
                                <img src={merryChristmas}/>
                            </Box>
                        </VisibleFor>
                    )
                }
                <Box className={classes.mainContainer}>
                    <Box>
                        <Typography variant="h1" className={classes.mainCaption}>
                            {strings.formatString(strings.findStorageNearYou, selectedLandingPage?.shortLabel.toLowerCase())}
                        </Typography>
                        <Typography variant="h3" component="p" className={classes.subtitle}>{strings.landingPagePayoff}</Typography>
                    </Box>
                    <StartPageSearchForm searchParameters={storedSearchParameters} />
                </Box>
            </MuiContainer>
            <MuiContainer maxWidth={false} className={classes.mainBackgroundColor}>
                <ContentBox className={classes.introWrapper} themeMaxWidth="xxs" widgetSpacing="20px">
                    <Typography variant="h2">
                        {strings.largestOnlineMarketplace}
                    </Typography>
                    <Typography variant="body1" className={classes.mainDescription}>
                        {strings.independentActor}
                    </Typography>
                </ContentBox>
            </MuiContainer>
            <MuiContainer className={classes.clippedBgWrapper} maxWidth={false}>
                <ContentBox themeMaxWidth="md">
                    <Grid container spacing={5} justifyContent="center">
                        <Grid item sm={12} md={4}>
                            <FeatureHighlightIcon
                                heading={strings.usp2Heading}
                                text={strings.usp2Text}
                                icon={appContext.images.secureIcon}
                                iconHeight="120"
                                iconWidth="220"
                                />
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <FeatureHighlightIcon
                                heading={strings.usp3Heading}
                                text={strings.usp3Text}
                                icon={appContext.images.sustainableIcon}
                                iconHeight="120"
                                iconWidth="220"
                                />
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <FeatureHighlightIcon
                                heading={strings.usp1Heading}
                                text={strings.usp1Text}
                                icon={appContext.images.flexibleStorageIcon}
                                iconHeight="120"
                                iconWidth="220"
                            />
                        </Grid>
                    </Grid>
                </ContentBox>
            </MuiContainer>
            <MuiContainer>
                <ContentBox themeMaxWidth="md">
                    <FeatureHighlightNumber featureNumber={10000 /* appContext.bookingCount */} eyebrowText={strings.featureNumberEyebrow} numberDescription={strings.featureNumberDescription}/>
                </ContentBox>
            </MuiContainer>
            <MuiContainer className={classes.showcaseAds} maxWidth={false}>
                <ContentBox themeMaxWidth="md">
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={6}>
                            <ContentBox widgetSpacing="30px">
                                <Typography variant="h2">{strings.quickStartGuideHeading}</Typography>
                                <StyledList>
                                    <StyledListItem>
                                        <Typography component="span" variant="body1">
                                            {strings.quickStartGuideStep1}
                                        </Typography>
                                    </StyledListItem>
                                    <StyledListItem>
                                        <Typography component="span" variant="body1">
                                            {strings.quickStartGuideStep2}
                                        </Typography>
                                    </StyledListItem>
                                    <StyledListItem>
                                        <Typography component="span" variant="body1">
                                            {strings.quickStartGuideStep3}
                                        </Typography>
                                    </StyledListItem>
                                    <StyledListItem>
                                        <Typography component="span" variant="body1">
                                            {strings.quickStartGuideStep4}
                                        </Typography>
                                    </StyledListItem>
                                </StyledList>
                                <Box>
                                    <Button className={classes.btnFullwidth} href={selectedLandingPage.url} variant="contained" color="primary">
                                        {strings.showAllAds}
                                    </Button>
                                </Box>
                            </ContentBox>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MockStorageSite
                                mockData={selectedLandingPage.mockDataAd}
                                storageGroupCategory={storageGroupCategories[selectedLandingPage.key]}
                            />
                        </Grid>
                    </Grid>
                </ContentBox>
            </MuiContainer>
            <MuiContainer className={classes.defaultSpacing} maxWidth={false}>
                <ContentBox className={classes.maxWidthSm} themeMaxWidth="xxxs" widgetSpacing="20px">
                    <img src={appContext.images.nowRentingSign} alt={strings.propertyOwnerEmptySpaceImageAlt} style={{alignSelf: 'center'}} width="317" height="229" />
                    <Typography variant="h2" align="center">{addLineBreakOnComma(strings.propertyOwnerEmptySpacePrompt)}</Typography>
                    <Typography variant="body1" align="center">{strings.propertyOwnerEmptySpaceDescription}</Typography>
                    <Button href={routes.rentOutStorage} variant="contained" color="primary" style={{alignSelf: 'center'}}>{strings.rentOut}</Button>
                </ContentBox>
            </MuiContainer>
            <MuiContainer className={classes.defaultSpacing}>
                <ContentBox themeMaxWidth="lg">
                    <Divider/>
                </ContentBox>
            </MuiContainer>
            <MuiContainer className={classes.defaultSpacing}>
                <ContentBox className={classes.maxWidthSm} themeMaxWidth="lg">
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h2">{strings.faqHeading}</Typography>
                            <ExpandableTitleDescriptionList items={questionAndAnswerItemsTenantSmall}/>
                            <Typography className={classes.faqCta} variant="body1">{strings.faqCtaText} <Link to={routes.questionsAndAnswers}>{strings.faqCtaLink}</Link></Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={3} className={classes.reverseMobile}>
                                <Grid item xs={12} sm={7}>
                                    <ContentBox widgetSpacing="20px">
                                        <Typography variant="h2">{strings.doYouNeedHelp}</Typography>
                                        <Typography variant="body1">{strings.supportStartPageDescription}</Typography>
                                        <ContactBox size="large" />
                                    </ContentBox>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <img src={appContext.images.supportPerson} alt={strings.supportStartPageImageAlt} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ContentBox>
            </MuiContainer>
        </>
    );
};

export default StartPage;
