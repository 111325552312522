import React, { useEffect } from 'react';
import { makeStyles } from 'styles/util';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { userAgentIsBot } from 'helpers/ConfigurationHelper';
import { getAuthenticationContext } from 'actions/authentication';
import { getIpLocation } from 'actions/ipLocations';
import { clearRedirectUrl, clearLogInPageMessage } from 'actions/authentication';
import { fetchNotifications, setNotificationsRead } from 'actions/notifications';
import { shouldRedirectToAcceptTermsOfService } from 'helpers/UserHelper';
import { fetchStripeAccountInfo } from 'actions/stripe';
import { googleTagManagerConstants, createGoogleTagManagerUserAction, setDataLayerVariable, getGoogleAnalyticsUserType } from 'integration/google-tag-manager/googleTagManagerHelper';
import { handleResponse } from 'actions/actionHelpers';
import { querystringParameterSet } from 'helpers/BrowserHelper';
import routes from 'routes';

import ApiVersionChecker from 'components/ApiVersionChecker';
import Box from '@material-ui/core/Box';
import PrivateRoute from './PrivateRoute';
import Header from 'components/header/Header';
import Footer from './Footer';
import GeolocationManager from 'lib/geolocation/GeolocationManager';
import RentOutStoragePage from 'pages/rentOutStorage/RentOutStoragePage';
import FindStoragePage from 'pages/findStorage/FindStoragePage';
import LandingPage from 'pages/landing/LandingPage';
import StorageSiteDetailsPage from 'pages/storageSiteDetails/StorageSiteDetailsPage';
import PageNotFoundPage from 'pages/pageNotFound/PageNotFoundPage';
import CookiePolicyPage from 'pages/policies/CookiePolicyPage';
import StoringPolicyPage from 'pages/policies/StoringPolicyPage';
import GeneralPolicyPage from 'pages/policies/GeneralPolicyPage';
import IntegrityPolicyPage from 'pages/policies/IntegrityPolicyPage';
import NotAllowedPolicyPage from 'pages/policies/NotAllowedPolicyPage';
import EnvironmentPolicyPage from 'pages/policies/EnvironmentPolicyPage';
import StartPage from 'pages/startPage/StartPage';
import LoginPage from 'pages/login/LoginPage';
import EidLoginPage from 'pages/login/EidLoginPage';
import UsernameAndPasswordLoginPage from 'pages/login/UsernameAndPasswordLoginPage';
import MockLoginPage from 'pages/login/MockLoginPage';
import CreateAccountPage from 'pages/login/CreateAccountPage';
import LogoutPage from 'pages/logout/LogoutPage';
import AccountRoutes from 'account/AccountRoutes';
import AcceptTermsOfServicePage from 'pages/acceptTermsOfService/AcceptTermsOfServicePage';
import AcceptUserInvitationPage from 'pages/login/AcceptUserInvitationPage';
import HowItWorksPage from 'pages/howItWorks/HowItWorksPage';
import QuestionAndAnswersPage from 'pages/questionAndAnswers/QuestionAndAnswersPage';
import AboutPage from 'pages/about/AboutPage';
import GeographyListPage from 'pages/geographyList/GeographyListPage';
import SuccessSnackbar from './SuccessSnackbar';
import SuccessDialog from 'common/SuccessDialog';
import ErrorDialog from 'common/ErrorDialog';
import SwitchActorDialog from './SwitchActorDialog';
import TakeoverTenantPage from 'pages/takeoverTenant/TakeoverTenantPage';
import SeoGeoItemsEditorPage from 'pages/seoGeoItemsEditor/SeoGeoItemsEditorPage';

const useStyles = makeStyles(({ theme }) => ({
    app: {
        paddingTop: theme.spacing(10),
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(7.5)
        },
        '@media print': {
            paddingTop: 0
        }
    }
}),
({ theme }) => ({
    office: {
        app: {
            paddingTop: '80px',
            [theme.breakpoints.down('xs')]: {
                paddingTop: '60px'
            },
            '@media print': {
                paddingTop: 0
            }
        }
    }
}));

const App = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;
    const token = useSelector(state => state.authentication.token);
    const redirectUrl = useSelector(state => state.authentication.redirectUrl);
    const minimal = querystringParameterSet('minimal');

    // when a new authentication token: fetch the authentcation context
    useEffect(() => {
        if(token) {
            dispatch(getAuthenticationContext())
                .then(handleResponse(
                    response => {
                        // the user logged in, and we have an authentication context to work with
                        const ac = response.payload;

                        setDataLayerVariable('userId', ac.user.id);
                        setDataLayerVariable('actorId', ac.selectedActor?.id);

                        if(ac.selectedActor) {
                            // log that an actor logged in
                            if(ac.selectedActor.isNewActor) {
                                // the actor was created right now; log this event separately
                                dispatch(createGoogleTagManagerUserAction(`${googleTagManagerConstants.userActions.newUserCreated}-${getGoogleAnalyticsUserType(ac.selectedActor)}`));
                            }
                            dispatch(createGoogleTagManagerUserAction(`${googleTagManagerConstants.userActions.userLoggedIn}-${getGoogleAnalyticsUserType(ac.selectedActor)}`));
                        }

                        // if there is an updated terms of service version, the user must accept the new version before doing anything else
                        if(shouldRedirectToAcceptTermsOfService(ac, location)) {
                            history.push(routes.acceptTermsOfService);
                            return;
                        }

                        if(ac.selectedActor) {
                            dispatch(fetchStripeAccountInfo());
                            dispatch(fetchNotifications(ac.selectedActor.id));
                        }

                        if(redirectUrl) {
                            history.push(redirectUrl);
                        }
                        dispatch(clearRedirectUrl());
                        dispatch(clearLogInPageMessage());
                    }
                ));
            }
    }, [token]);

    useEffect(() => {
        if(!userAgentIsBot()) {
            dispatch(getIpLocation());
        }
    }, []);

    useEffect(() => {
        const geolocationManager = new GeolocationManager();
        if(geolocationManager.isEnabled()) {
            geolocationManager.getCurrentLocation();
        } else {
            geolocationManager.askToEnableForFirstTime(() => geolocationManager.getCurrentLocation());
        }
    }, []);

    // clear unread notifications heads-up
    useEffect(() => {
        if(selectedActor) {
            let params = null;
            if (location.pathname === routes.account.conversations) {
                params = { readMessages: true };
            } else if (location.pathname === routes.account.ownerBookings || location.pathname === routes.account.tenantBookings) {
                params = { readBookings: true };
            }
            if (params) {
                dispatch(setNotificationsRead(selectedActor.id, params));
            }
        }
    }, [location.pathname, selectedActor]);

    // if multiple actors to choose from, redirect to actor selection page
    if(authenticationContext && !authenticationContext.selectedActor && authenticationContext.actors.length > 1) {
        return <Redirect to={routes.account.selectActor}/>;
    }

    return (
        <Box className={classes.app}>
            <Header />

            { !minimal && <ApiVersionChecker /> }

            <Switch>
                <Route path={routes.eidLogIn} component={EidLoginPage}/>
                <Route path={routes.usernameAndPasswordLogIn} component={UsernameAndPasswordLoginPage}/>
                <Route path={routes.mockLogIn} component={MockLoginPage}/>
                <Route path={routes.logIn} component={LoginPage}/>
                <Route path={routes.createAccount} component={CreateAccountPage}/>
                <Route path={routes.acceptUserInvitation} component={AcceptUserInvitationPage}/>
                <Route path={routes.takeoverTenant} component={TakeoverTenantPage}/>
                <Route path="*" exact>
                    <Switch>
                        <Route path={routes.index} component={StartPage} exact/>
                        <Route path={[
                                routes.storageSiteDetailsExtended,
                                routes.storageSiteDetails
                            ]} component={StorageSiteDetailsPage}/>
                        <Route path={routes.findStorage} component={FindStoragePage}/>

                        <Route path={routes.rentOutStorage} component={RentOutStoragePage}/>
                        <Route path={routes.howItWorks} component={HowItWorksPage}/>
                        <Route path={routes.questionsAndAnswers} component={QuestionAndAnswersPage}/>
                        <Route path={routes.geographyList} component={GeographyListPage}/>
                        <Route path={routes.about} component={AboutPage}/>

                        <Route path={routes.policies.storing} component={StoringPolicyPage}/>
                        <Route path={routes.policies.integrity} component={IntegrityPolicyPage}/>
                        <Route path={routes.policies.cookie} component={CookiePolicyPage}/>
                        <Route path={routes.policies.material} component={NotAllowedPolicyPage}/>
                        <Route path={routes.policies.environment} component={EnvironmentPolicyPage}/>
                        <Route path={routes.policies.general} component={GeneralPolicyPage}/>

                        <PrivateRoute path={routes.account.index} component={AccountRoutes}/>

                        <Route path={routes.logOut} component={LogoutPage}/>
                        <Route path={routes.acceptTermsOfService} component={AcceptTermsOfServicePage} />
                        <Route path={routes.seoGeoItemsEditor} component={SeoGeoItemsEditorPage} />
                        <Route path={[routes.landingPageWithLocation, routes.landingPage]} component={LandingPage}/>
                        <Route path="*" exact component={PageNotFoundPage} />
                    </Switch>
                    <Footer/>
                </Route>
            </Switch>

            <SuccessSnackbar />
            <SuccessDialog />
            <ErrorDialog />
            <SwitchActorDialog />
        </Box>
    );
};

export default App;
