import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { handleResponse } from 'actions/actionHelpers';
import { fetchOwnerBookings } from 'actions/account/ownerBookingsSection';
import strings from 'localization/strings';
import { changeValueMutator } from 'helpers/FormHelper';
import { getStorageSiteAndStorageTitle } from 'helpers/StorageSiteHelper';
import { distinct } from 'helpers/ArrayHelper';
import { formatTimePeriod } from 'helpers/StringHelper';
import { setSuccessResponse, setErrorResponse } from 'actions/responses';
import { makeStyles } from 'styles/util';
import { getContact, getBusinessOrPrivateName } from 'helpers/ActorHelper';
import contactTypes from 'enums/contactTypes';
import bookingStatuses from 'enums/bookingStatuses';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from 'form/Form';
import { Checkboxes } from 'mui-rff';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Loader from 'common/Loader';

const useStyles = makeStyles(({ theme }) => ({
    list: {
        maxHeight: '600px',
        overflow: 'auto'
    },
    information: {
        marginBottom: theme.spacing(2)
    }
}));

const CopyBookingEmailsToClipboardDialog = ({ filterParams, open, onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [initialValues, setInitialValues] = useState({});
    const [bookings, setBookings] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;

    useEffect(() => {
        if(open) {
            setIsLoading(true);
            const request = {
                ownerActorId: selectedActor.id,
                adminBookingStatus: filterParams.adminBookingStatus,
                storageSiteId: filterParams.storageSiteId,
                q: filterParams.q
            };
            dispatch(fetchOwnerBookings(request))
                .then(handleResponse(
                    response => {
                        const purchasedBookings = response.payload.items.filter(o => o.bookingStatus === bookingStatuses.purchased.key);
                        setBookings(purchasedBookings);
                        const values = {
                            bookings: []
                        };
                        purchasedBookings.forEach(booking => {
                            values.bookings[booking.id] = true;
                        });
                        setInitialValues(values);
                        setIsLoading(false);
                    }));
        }
    }, [open]);

    const handleCopy = success => {
        if(success) {
            onClose();
            dispatch(setSuccessResponse(strings.copyToClipboardSucceeded));
        } else {
            dispatch(setErrorResponse(strings.copyToClipboardFailed));
        }
    };

    const handleFormSubmit = () => {
        // nothing to be done here
    };

    const getSemicolonSeparatedEmails = values => distinct(bookings
            .filter(booking => values.bookings[booking.id])
            .map(booking => getContact(booking.tenantActor, contactTypes.mainEmail))
        )
        .join(';');

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <Form
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                mutators={{
                    changeValue: changeValueMutator
                }}
            >
                {({ handleSubmit, form, values }) => {
                    const handleBookingClick = booking => {
                        form.mutators.changeValue({ name: `bookings[${booking.id}]`, value: !values.bookings[booking.id] });
                    };

                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogTitle disableTypography>
                                <Typography variant="h5">
                                    {strings.copyBookingEmailsToClipboardDialogTitle}
                                </Typography>
                            </DialogTitle>
                            <DialogContent>
                                {
                                    isLoading && <Loader/>
                                }
                                {
                                    !isLoading &&
                                    (
                                        <>
                                            <Alert severity="info" className={classes.information}>
                                                {strings.copyBookingEmailsToClipboardDialogBody}
                                            </Alert>

                                            {
                                                bookings.length === 0 &&
                                                (
                                                    <Typography variant="body1" gutterBottom>
                                                        {strings.noBookingsInThisCategory}
                                                    </Typography>
                                                )
                                            }
                                            {
                                                bookings.length > 0 &&
                                                (
                                                    <List className={classes.list}>
                                                        {
                                                            bookings.map(booking => {
                                                                const storageGroup = booking.storageGroup;
                                                                const title = getStorageSiteAndStorageTitle(booking.storage, storageGroup);

                                                                const primary = (
                                                                    <Box>
                                                                        {getBusinessOrPrivateName(booking.tenantActor)} &ndash; {title}, {formatTimePeriod(booking)}
                                                                    </Box>
                                                                );
                                                                return (
                                                                    <ListItem key={booking.id} dense button onClick={() => handleBookingClick(booking)}>
                                                                        <ListItemIcon>
                                                                            <Checkboxes
                                                                                name={`bookings[${booking.id}]`}
                                                                                data={{}}
                                                                            />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={primary} />
                                                                    </ListItem>
                                                                );
                                                            })
                                                        }
                                                    </List>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </DialogContent>
                            <DialogActions>
                                {
                                    !isLoading &&
                                    (
                                        <CopyToClipboard text={getSemicolonSeparatedEmails(values)}
                                            onCopy={handleCopy}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={bookings.length === 0}
                                            >
                                                {strings.copyToClipboard}
                                            </Button>
                                        </CopyToClipboard>
                                    )
                                }

                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={onClose}
                                >
                                    {strings.close}
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Form>
        </Dialog>
    );
};

CopyBookingEmailsToClipboardDialog.propTypes = {
    filterParams: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default CopyBookingEmailsToClipboardDialog;
